import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import DashboardPassionFroid from '../../../../../../pages/private/Foodservice/PassionFroid/DashboardPassionFroid'
import { CustomersPagePassionFroid } from '../../../../../../pages/private/Foodservice/PassionFroid/CustomersPage'
import { WarehousesPagePassionFroid } from '../../../../../../pages/private/Foodservice/PassionFroid/WarehousesPage'
import { ProductsPagePassionFroid } from '../../../../../../pages/private/Foodservice/PassionFroid/ProductsPage'
import { ViewPagePassionFroid } from '../../../../../../pages/private/Foodservice/PassionFroid/ViewPage'

export const PassionFroidRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardPassionFroid />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPagePassionFroid />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'depots/',
        element: <WarehousesPagePassionFroid />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'produits/',
        element: <ProductsPagePassionFroid />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPagePassionFroid />,
        title: PagesName.VIEWS,
    },
]
