import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import client from '../../api/config'
import { InvitationResponse, InvitationsData, InvitationsSteps, InvitationState } from './invitationsTypes'
import { UsersRole } from '../user/userTypes'

export const initialState: InvitationState = {
    open: false,
    config: {
        steps: [
            InvitationsSteps.CONTACTSTEP,
            InvitationsSteps.ENVSTEP,
            InvitationsSteps.ENDSTEP
        ],
    },
    data: {
        email: "",
        role: UsersRole.USER,
        env: [],
        modules: []
    }
}

const invitationsSlice = createSlice({
    name: 'invitations',
    initialState,
    reducers: {
        updateInvitation: (
            state,
            action: PayloadAction<{ data: Partial<InvitationsData> }>
        ) => {
            state.data = { ...state.data, ...action.payload.data }
        },
    },
})

export const { updateInvitation } = invitationsSlice.actions

export const sendInvitation =
    (data: InvitationsData): AppThunk<Promise<InvitationResponse>> =>
        async (dispatch, getState) => {
            const invitation = {
                email: data.email,
                role: data.role,
                env: data.env,
                modules: data.modules
            }

            try {
                const sendInvitation = await client
                    .post(`/invitations`, invitation)
                    .then((response) => {
                        return response
                    })

                if (sendInvitation.data.result) {
                    return { result: { success: sendInvitation.data.result } }
                } else {
                    return { result: { error: sendInvitation.data.message } }
                }
            } catch (e) {
                console.log('ERROR :', e)
                return { result: { error: 'erreur' } }
            }
        }


export default invitationsSlice.reducer
