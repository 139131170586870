import { PrimaryText, SecondaryText, Title } from '../../../ui/Text'
import React, { useSyncExternalStore } from 'react'
import { Box, Grid, Paper } from '@mui/material'
import { PrimaryButton } from '../../../ui/Button'
import { LIGHT_GREY } from '../../../ui/Colors'
import KaryonLogo from '../../../../assets/Logo-KaryonFood-Normal.png'
import Illustration from '../../../../assets/Illustrationpagemobile.svg'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { getConfig } from '../../../../api/config'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../helpers/ExternalStore'

const handleSupport = () => {
    window.open(
        'https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed',
        '_blank'
    )
}

const IMAGE_LIMIT = 450

export const MobilePage = () => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    return (
        <Grid container justifyContent="center" sx={{ background: LIGHT_GREY }}>
            <Paper sx={{ width: '88vw', m: '1vh', p: '5vh', height: '88vh' }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    height="100%"
                    wrap="nowrap"
                >
                    <Grid container direction="column" alignItems="center">
                        <Box
                            component="img"
                            width="220px"
                            src={KaryonLogo}
                            alt="Logo Karyon"
                        />
                    </Grid>
                    <Grid container textAlign="center" direction="column" alignItems="center">
                        <Title> Vous êtes sur ordinateur ? Dézoomez votre écran pour afficher la page</Title>
                        <PrimaryText>
                            Vous êtes sur téléphone ? Pour une expérience agréable, accédez à la plateforme KaryonFood sur votre ordinateur ou tablette.
                        </PrimaryText>
                        {height > IMAGE_LIMIT && (
                            <Grid container flexWrap="nowrap" alignItems="center" justifyContent='center'>
                                <Box
                                    component="img"
                                    sx={{
                                        width: { xs: '250px', md: '35vw' },
                                        marginTop: '4em',
                                    }}
                                    src={Illustration}
                                    alt="Logo Karyon"
                                />
                                <Grid>
                                    <PrimaryText>
                                        1. En haut à droite, cliquez sur Plus … <br></br>
                                        2. À côté de l'option "Zoom", <b>pour réduire tous les éléments de la page</b>: cliquez sur Zoom arrière -
                                    </PrimaryText>
                                </Grid>

                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        onClick={handleSupport}
                    >
                        <HelpOutlineIcon />
                        <PrimaryText ml={1}>Support</PrimaryText>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
