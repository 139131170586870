import React, { type MouseEvent } from 'react'
import { Button, DialogActions } from '@mui/material'
import { DARK_GREY, MEDIUM_GREY, WHITE } from '../../ui/Colors'
import { PrimaryButton } from '../../ui/Button'

export const DialogFooter = ({
    leftButtonFunc,
    leftButtonText,
    rightButtonFunc,
    rightButtonText,
    rightButtonColor,
}: {
    leftButtonFunc: (e: MouseEvent) => void
    leftButtonText: string
    rightButtonFunc: (e: MouseEvent) => void
    rightButtonText: string
    rightButtonColor?: string
}) => {
    return (
        <DialogActions
            sx={{
                justifyContent: 'space-around',
                padding: '1vh 1vw 1vh 1vw',
                borderTop: `solid ${MEDIUM_GREY} 1px`,
            }}
        >
            <Button
                sx={{
                    color: DARK_GREY,
                    fontSize: '1.6vmin',
                }}
                onClick={(e) => {
                    leftButtonFunc(e)
                }}
            >
                {leftButtonText}
            </Button>
            <Button
                sx={{
                    color: rightButtonColor ? rightButtonColor : 'default',
                    fontSize: '1.6vmin',
                }}
                onClick={(e) => {
                    rightButtonFunc(e)
                }}
            >
                {rightButtonText}
            </Button>
        </DialogActions>
    )
}

export const DialogFooterOneAction = ({
    buttonFunc,
    text,
}: {
    buttonFunc: (e?: MouseEvent) => void
    text: string
}) => {
    return (
        <DialogActions
            sx={{
                justifyContent: 'space-around',
                padding: '.8em',
                borderTop: `solid ${MEDIUM_GREY} 1px`,
            }}
        >
            <PrimaryButton
                sx={{
                    width: '100%',
                    backgroundColor: `${WHITE} !important`,
                    borderWidth: '0.2vh',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    opacity: 1,
                    fontSize: { xs: '16px', md: '1.5vmin' },
                }}
                onClick={(e) => {
                    buttonFunc(e)
                }}
            >
                {text}
            </PrimaryButton>
        </DialogActions>
    )
}
