import { Grid } from '@mui/material'
import React from 'react'
import ChartBlock from '../../../../../../components/features/ChartBlock'
import { LIGHT_MOCK } from '../../../../../../components/ui/Colors'
import { useAppSelector } from '../../../../../../redux/hooks'
import { type RetailProduct } from '../../../../../../redux/user/userTypes'
import LineChartCard from './IndicatorComponent'
import { Separator } from '../../../../../../components/ui/Separator'

const UniqueCard = ({ data }: { data: RetailProduct }) => {
    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            flex={1.5}
            sx={{ borderRight: `1px solid ${LIGHT_MOCK}` }}
        >
            <LineChartCard isTop border={false} data={data} />
            <Separator />
        </Grid>
    )
}

const MultipleCards = ({
    top,
    flop,
}: {
    top: RetailProduct
    flop: RetailProduct
}) => {
    return (
        <Grid container direction="column" wrap="nowrap" flex={1.5}>
            <LineChartCard isTop data={top} />
            <Separator />
            <LineChartCard isTop={false} data={flop} />
        </Grid>
    )
}

const Indicator = () => {
    const { products } = useAppSelector(
        (state) => state.global.retailDashboardData
    )
    const { env } = useAppSelector((state) => state.global)
    const link = `/retail/${env.name}/produits`

    return (
        <ChartBlock title="TOP/FLOP Références (UVC)" link={link}>
            {products.length > 1 ? (
                <MultipleCards
                    top={products[0]}
                    flop={products[products.length - 1]}
                />
            ) : (
                <UniqueCard data={products[0]} />
            )}
        </ChartBlock>
    )
}

export default Indicator
