import { CardContent, Grid } from '@mui/material'
import { SubTitle } from '../../../../../../components/ui/Text'
import React from 'react'
import NumberWithSpace from '../../../../../../components/ui/NumberWithSpace'
import Tendency from '../../../../../../components/ui/Tendency'
import { BLACK, DARK_BLUE } from '../../../../../../components/ui/Colors'
import { Units } from '../../../../../../redux/global/globalTypes'

export const DataCartDrillDownPromoInfo = ({
    title,
    value,
    difference,
    unit,
    notRoundable = false,
}: {
    title: string
    value: number
    difference: number
    unit: Units
    notRoundable?: boolean
}) => {
    return (
        <CardContent
            sx={{
                border: 1,
                borderColor: DARK_BLUE,
                minWidth: '10vw',
                mr: '2vw',
            }}
        >
            <SubTitle>{title}</SubTitle>
            <SubTitle>
                <Grid flexDirection="row" container>
                    <NumberWithSpace number={value} />
                    <SubTitle color={BLACK} ml="0.5vw">
                        {unit}
                    </SubTitle>
                </Grid>
            </SubTitle>
            <Grid container alignItems="flex-end">
                <Tendency
                    kpi
                    difference={difference}
                    unit={unit}
                    notRoundable={notRoundable}
                />
            </Grid>
        </CardContent>
    )
}
