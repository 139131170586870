import React from 'react'
import { Dialog, DialogContent, Typography } from '@mui/material'
import { DialogHeader } from './BasicDialog'
import { DialogFooter } from './BasicFooterDialog'
import { RED } from '../../ui/Colors'

interface OwnProps {
    handleClose: (e) => void
    isOpen: boolean
    yes: () => void
    no: () => void
}

const YesNoDialog = ({ handleClose, isOpen, no, yes }: OwnProps) => {
    return (
        <Dialog
            maxWidth="sm"
            onClose={(e: any) => {
                e.stopPropagation()
                handleClose(e)
            }}
            open={isOpen}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    width: '780px',
                    padding: '1em',
                    height: '40vh',
                },
            }}
        >
            <DialogHeader
                title="Supprimer cette vue ?"
                setOpen={(e) => {
                    handleClose(e)
                }}
            />

            <DialogContent>
                <Typography>
                    Souhaitez-vous vraiment supprimer cette vue ?
                </Typography>
            </DialogContent>
            <DialogFooter
                rightButtonColor={RED}
                leftButtonFunc={(e) => {
                    e.stopPropagation()
                    no()
                }}
                leftButtonText={'Annuler'}
                rightButtonFunc={(e) => {
                    e.stopPropagation()
                    yes()
                }}
                rightButtonText={'Supprimer'}
            />
        </Dialog>
    )
}

export default YesNoDialog
