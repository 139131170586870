import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material"
import React, { useState } from "react"
import { SecondaryText } from "../../ui/Text"
import { distributorImages } from "../../../helpers/distributorsList"
import { MEDIUM_GREY } from "../../ui/Colors"
import { EnvName, EnvStatus } from "../../../redux/user/userTypes"
import { DialogData } from "../../../pages/private/Global/Import/Import"
import ContactDialog from "../../../pages/private/Global/Import/dialog/contact/ContactDialog"
import { PrimaryButton } from "../../ui/Button"

const resetState = {
    isOpen: false,
    envName: EnvName.MYDISTRIB,
    status: EnvStatus.INACTIVATED,
}

export const GhostEnvironnements = ({ env }: { env: string[], }) => {
    const [dialogContactData, setDialogContactData] =
        useState<DialogData>(resetState)

    const openModaleContact = () => {
        setDialogContactData({
            isOpen: true,
            envName: EnvName.MYDISTRIB,
            status: EnvStatus.INACTIVATED,
        })
    }

    return (
        <Grid>
            <ContactDialog
                isOpen={dialogContactData.isOpen}
                handleClose={() => {
                    setDialogContactData(resetState)
                }}
            />
            <Grid
                display={'flex'}
                justifyContent="space-between"
                alignItems={'center'}
            >
                <SecondaryText
                    style={{
                        color: MEDIUM_GREY,
                        padding: '0.5vh',
                        margin: '0.5vh',
                    }}
                >
                    Ajouter des distributeurs
                </SecondaryText>
                <PrimaryButton
                    id="add-distributor"
                    type="button"
                    style={{
                        padding: '1vh',
                        minWidth: '1vw',
                        margin: '1vh',
                        maxHeight: '1vh',
                    }}
                    onClick={(e) => {
                        openModaleContact()
                    }}
                >
                    +
                </PrimaryButton>
            </Grid>
            <List dense sx={{ padding: 0 }}>
                {env.map(
                    (listEnv) =>
                        <Tooltip placement="right" title="Vous pouvez ajouter un nouvel espace distributeur en quelques clics">
                            <ListItem
                                id={`${listEnv}-distributor`}
                                sx={{ cursor: "pointer !important" }}
                                key={listEnv}
                                onClick={() => openModaleContact()}
                            >
                                <ListItemButton disabled sx={{ padding: '0.8vh 1vw' }}>
                                    <ListItemIcon>
                                        <img
                                            style={{
                                                width: '3vw',
                                                paddingRight: '0.5vw',
                                            }}
                                            alt="Logo du distributeur"
                                            src={distributorImages[listEnv]}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={listEnv}
                                        sx={{
                                            '& .MuiTypography-root': {
                                                fontSize: '1.6vmin !important',
                                            },
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </Tooltip >

                )}
            </List >
        </Grid >
    )
}
