import { DialogContent, Grid } from '@mui/material'
import { type FormikBag, type FormikProps, withFormik, Form } from 'formik'
import React, { useCallback, useState } from 'react'
import { object, string } from 'yup'

import { DARK_GREY, PRIMARY } from '../../../ui/Colors'
import { DialogFooter } from '../../Dialog/BasicFooterDialog'
import { EnhancedFieldLarge, TextInput } from '../../../ui/Inputs'
import { PrimaryText, SecondaryText } from '../../../ui/Text'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { setDescription, shareView } from '../../../../redux/views/viewsSlice'
import { ConfigProps, ShareViewData } from '../ShareDialog'

interface FormValues {
    description: string,
    usersToShare: string[]
}

interface FormProps {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => void;
    handlePrevious: (e: React.MouseEvent) => void;
}


const PureAddMessageForm = (props: FormikProps<FormValues> & FormProps) => {
    const shareListLength = props.values.usersToShare.length

    return (
        <Form style={{ width: '100%', height: '100%' }}>
            <Grid height="90%">
                <DialogContent>
                    <PrimaryText color={PRIMARY}>
                        {shareListLength === 1 ? `Votre collègue sélectionné recevra une notification par email l'invitant à visualiser votre vue !` : `Vos ${shareListLength} collègues sélectionnés recevont une notification par email les invitant à visualiser votre vue !`}
                    </PrimaryText>
                    <Grid container direction="column" mt="3vh">
                        <SecondaryText color={DARK_GREY}>
                            Vous pouvez leur adresser une note ici, à vos plumes !
                        </SecondaryText>
                        <Grid item>
                            <EnhancedFieldLarge name={"description"}
                                label="Message"
                                autoFocus
                                margin="normal"
                                component={TextInput}
                                onChange={(e) => {
                                    e.preventDefault()
                                    const { value } = e.target
                                    props.setFieldValue("description", value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Grid>
            <DialogFooter
                rightButtonColor={PRIMARY}
                leftButtonFunc={(e) => {
                    e.stopPropagation()
                    props.handlePrevious(e)
                }}
                leftButtonText='Précédent'
                rightButtonFunc={(e) => {
                    e.stopPropagation()
                    props.submitForm()
                }}
                rightButtonText='Partager la vue'
            />
        </Form>
    )
}

const ControlledUsersToShareForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        description: props.initialValues.description,
        usersToShare: props.initialValues.usersToShare
    }),
    validationSchema: () => {
        return object().shape({
            description: string().required("Le champs Message est obligatoire"),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureAddMessageForm)

const AddMessageStep = ({
    handleNext,
    updateData,
    getData,
    handlePrevious,
    viewId,
}: ConfigProps) => {
    const initialValues = getData() as unknown as FormValues
    const dispatch = useAppDispatch()
    const { usersToShare } = useAppSelector((state) => state.views)
    const { env } = useAppSelector((state) => state.global)

    const _shareView = useCallback(
        async (values: FormValues) => {
            await dispatch(setDescription(values.description))
            await dispatch(shareView(viewId, usersToShare, values.description, env.name, env.type))
        },
        [dispatch, usersToShare]
    )

    const handleSubmit = async (values: FormValues) => {
        updateData(values as unknown as ShareViewData)
        _shareView(values)
        handleNext()
    }

    return (
        <ControlledUsersToShareForm
            initialValues={initialValues}
            handlePrevious={handlePrevious}
            onSubmit={handleSubmit}
        />
    )
}

export default AddMessageStep
