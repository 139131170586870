import { supabase } from '../../../supabaseClient'

export interface ResponseExportRaw {
    file: string
    status: string
    message: string
}

export const getExportRawSupabase = async (
    filesnames: string[]
): Promise<ResponseExportRaw[]> => {
    let list = []
    await Promise.all(
        filesnames.map(async (file) => {
            const { data, error } = await supabase.storage
                .from(process.env.REACT_APP_SUPABASE_BUCKET_PROCESSED_DATA)
                .download(file)
            if (data) {
                let csvURL = window.URL.createObjectURL(data)
                let tempLink = document.createElement('a')
                tempLink.href = csvURL
                tempLink.setAttribute('download', file)
                tempLink.click()
                tempLink.remove()
                list.push({
                    filename: file,
                    status: 'success',
                    message: 'File has been download',
                })
            } else {
                list.push({
                    filename: file,
                    status: 'error',
                    message: error.message,
                })
            }
        })
    ).catch((err) => console.log(err))

    return list as unknown as ResponseExportRaw[]
}
