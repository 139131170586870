import React, { useEffect, useState } from 'react'
import { Grid, Tooltip } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import FilterSelect from '../FilterSelect'
import { type ActiveRetailFilters } from '../../../../redux/filters/filtersTypes'
import { EnvType } from '../../../../redux/user/userTypes'
import { FiltersItem, MenuType, getFiltersConfig } from '../utils'
import { useAppSelector } from '../../../../redux/hooks'

// ICONS
import InventoryIcon from '@mui/icons-material/Inventory'
import trashIcon from '../../../../assets/icon/trashIcon.svg'
import { LIGHT_BLUE } from '../../../ui/Colors'

export interface FormValuesRetailFiltersForm {
    products: string[]
    stores: string[]
    distributionChannels: string[]
}

interface FormProps {
    initialValues: ActiveRetailFilters
    onSubmit: (values: FormValuesRetailFiltersForm) => void
    resetMethod: (values: FormValuesRetailFiltersForm) => void
    filtersConfig: FiltersItem[]
}

const PureFiltersForm = ({
    setFieldValue,
    submitForm,
    filtersConfig,
    ...props
}: FormikProps<FormValuesRetailFiltersForm> & FormProps) => {
    const isFiltered = useAppSelector((state) => state.global.isFiltered)

    const submitFilters = () => {
        const submit = async () => {
            const errors = await props.validateForm()

            if (Object.keys(errors).length === 0) {
                submitForm()
            }
        }

        if (props.dirty || Object.keys(props.touched).length > 0) {
            submit()
        }
    }

    const handleClearButton = () => {
        if (isFiltered) {
            props.resetForm()
            props.resetMethod(props.initialValues)
        }
    }

    return (
        <Form style={{ width: '100%' }}>
            <Grid container alignItems="center">
                {filtersConfig.map((filter) => (
                    <FilterSelect
                        name={filter.name}
                        icon={filter.icon}
                        placeholder={filter.placeholder}
                        type={filter.type}
                        values={props.values[filter.name]}
                        setFieldValue={setFieldValue}
                        key={filter.name}
                        env={EnvType.RETAIl}
                        submitFilters={submitFilters}
                    />
                ))}
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        borderRadius: '50px',
                        height: '2vw',
                        width: '2vw',
                        '&:hover': {
                            background: LIGHT_BLUE,
                        },
                    }}
                >
                    <Tooltip title="Réinitialiser les filtres">
                        <img
                            style={{
                                width: '2.5vw',
                                height: '2.5vh',
                                cursor: 'pointer',
                            }}
                            src={trashIcon}
                            onClick={() => {
                                handleClearButton()
                            }}
                        />
                    </Tooltip>
                </Grid>
            </Grid>
        </Form>
    )
}

const ControlledFiltersForm = withFormik<
    FormProps,
    FormValuesRetailFiltersForm
>({
    mapPropsToValues: (props) => ({
        products: props.initialValues.products,
        stores: props.initialValues.stores,
        distributionChannels: props.initialValues.distributionChannels,
    }),
    enableReinitialize: true,
    handleSubmit: (
        values: FormValuesRetailFiltersForm,
        bag: FormikBag<FormProps, FormValuesRetailFiltersForm>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureFiltersForm)

const RetailFiltersForm = ({
    handleSubmit,
    filters,
    resetMethod,
}: {
    handleSubmit: (values: FormValuesRetailFiltersForm) => void
    filters: ActiveRetailFilters
    resetMethod: (values: FormValuesRetailFiltersForm) => void
}) => {
    const { retailFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)
    const [filtersConfig, setFiltersConfig] = useState<FiltersItem[]>([
        {
            id: 0,
            name: 'products',
            icon: <InventoryIcon />,
            placeholder: 'Références',
            type: MenuType.PRODUCTS,
        },
    ])

    useEffect(() => {
        const keysFilters = retailFilters ? Object.keys(retailFilters) : []
        const config = getFiltersConfig(keysFilters, env.name)
        setFiltersConfig(config)
    }, [retailFilters])

    return (
        <ControlledFiltersForm
            onSubmit={handleSubmit}
            initialValues={filters}
            resetMethod={resetMethod}
            filtersConfig={filtersConfig}
        />
    )
}

export default RetailFiltersForm
