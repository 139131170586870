export enum PagesName {
    DASHBOARD = 'dashboard',
    CLIENTS = 'clients',
    WAREHOUSES = 'depots',
    PRODUCTS = 'produits',
    VIEWS = 'vues',
    STORES = 'magasins',
    PROMO = 'promo',
    CROSS = 'cross',
}
