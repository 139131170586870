import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { useState } from "react"
import { useCasesArray } from "../../../public/authentication/signUp/steps/SignUpJob"
import React from "react"
import { EditValues, FormProps } from "./ProfilPage"
import { Field, FormikProps } from "formik"
import { checkboxCSS } from "../../../../components/features/filters/selects/FilterComponent"
import { PrimaryButton } from "../../../../components/ui/Button"
import { TextInput } from "../../../../components/ui/Inputs"

export const InputUseCasesProfilPage = ({ ...props }: FormikProps<EditValues> & FormProps) => {
    const [inputText, setInputText] = useState<string>('')

    const listOfUseCases = [...useCasesArray, ...props.user.useCases]

    const listOfUseCasesToDisplay = listOfUseCases.filter((element, index) => listOfUseCases.indexOf(element) === index);

    const handleChangeText = (event: React.FormEvent<HTMLInputElement>) => {
        setInputText(event.currentTarget.value)
    }

    const handleChange = (useCase: string) => {
        const isInclude = props.values.useCases.includes(useCase)

        if (isInclude) {
            props.setFieldValue(
                'useCases',
                props.values.useCases.filter((el) => el !== useCase)
            )
        } else {
            props.setFieldValue('useCases', [...props.values.useCases, useCase])
        }
    }

    const handleAddButton = ({ otherUseCases }: { otherUseCases: string }) => {
        const isInclude = props.values.useCases.includes(otherUseCases)
        if (!isInclude) {
            useCasesArray.push(otherUseCases)
            handleChange(otherUseCases)
        }
        setInputText('')
    }
    return (
        <Grid container alignItems="flex-start" direction="column">
            {listOfUseCasesToDisplay.map((input, index) => {
                const isInclude =
                    props.values.useCases.includes(input)

                return (
                    <Grid
                        pb="1vh"
                        sx={{
                            '& .MuiTypography-body1': {
                                fontSize: {
                                    xs: '16px',
                                    md: '1.7vmin',
                                },
                            },
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                alignItems: 'center',
                                fontWeight: 400,
                                fontFamily: [
                                    'Cabin',
                                    'sans-serif',
                                ].join(','),
                                '& span': {
                                    fontSize: {
                                        xs: '16px',
                                        md: '1.7vmin',
                                    },
                                },
                            }}
                            control={
                                <Checkbox
                                    onChange={() =>
                                        handleChange(input)
                                    }
                                    defaultChecked={isInclude}
                                    sx={checkboxCSS}
                                />
                            }
                            label={input}
                        />
                    </Grid>
                )
            })}
            <Grid
                container
                alignItems="center"
                marginTop="1vh"
                spacing={3}
            >
                <Grid item md={8}>
                    <Field
                        label="Autre(s) mission(s)"
                        type="text"
                        value={inputText}
                        name="otherUseCases"
                        onChange={handleChangeText}
                        component={TextInput}
                    />
                </Grid>
                <Grid item md={4}>
                    <PrimaryButton
                        disabled={inputText.length < 5}
                        type="button"
                        onClick={() => {
                            handleAddButton({
                                otherUseCases: inputText,
                            })
                        }}
                    >
                        Ajouter
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>)
}