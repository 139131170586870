import React from 'react'
import { DataGrid, type DataGridProps, frFR } from '@mui/x-data-grid'
import { BLACK } from '../../../../components/ui/Colors'

const ImportsDataGrid = (props: DataGridProps) => {
    const fontStyle = {
        fontSize: { xs: '16px', md: '1.6vmin' },
        color: BLACK,
        fontFamily: ['Cabin', 'sans-serif'].join(','),
    }
    return (
        <DataGrid
            sx={{
                '& .MuiDataGrid-columnHeaders': {
                    fontStyle,
                },
                '& .MuiDataGrid-row': {
                    fontStyle,
                },
                '& .MuiTablePagination-root .MuiTablePagination-selectLabel': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
                '& .MuiTablePagination-displayedRows': {
                    fontSize: { xs: '16px', md: '1.7vmin' },
                },
            }}
            {...props}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
    )
}

export default ImportsDataGrid
