import React from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import { Chip } from '@mui/material'

interface ChipProps {
    color?: string
    label: string
    activable?: boolean
    pointer: boolean
}

export const SpecificChip = ({
    color,
    label,
    activable = false,
    pointer,
}: ChipProps) => {
    return (
        <Chip
            label={label}
            sx={{
                borderColor: color,
                color: color,
                cursor: pointer ? 'pointer' : 'default',
                width: '100%',
                marginBottom: '1vh',
                fontSize: { sx: '16px', md: '1.7vmin' },
            }}
            icon={
                activable && (
                    <CircleIcon
                        color="disabled"
                        sx={{ color: color, fontSize: '10px', paddingLeft: 1 }}
                    />
                )
            }
            color="default"
            variant="outlined"
        />
    )
}
