import React, { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { FsCrossViewFilters } from '../../../../../redux/filters/filtersTypes'
import PrivatePage from '../../../../../components/features/GlobalPages/Private/PrivatePage'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import Filters, { FiltersType } from '../../../../../components/features/filters/Filters'
import { Grid } from '@mui/material'
import FsKpis from '../../Pages/Dashboard/FsKpis'
import FsCrossLineChart from './FsCrossLineChart'
import { FsCrossTable } from './FsCrossTable'
import ChartBlock from '../../../../../components/features/ChartBlock'
import FsCrossSegmentation from './FsCrossSegmentation'
import { Units } from '../../../../../redux/global/globalTypes'
import { KPIsFsCrossConfig } from '../FsCrossDashboard'
import FsCrossViewPageLayout from '../layout/FsCrossViewPageLayout'


export const FsCrossViewPage = () => {
    const { viewId } = useParams()
    const { views } = useAppSelector((state) => state.views)
    const view = views.length > 0 ? views.find((view) => view._id === viewId) : null
    const filters = view ? view.filters as FsCrossViewFilters : null

    if (views.length === 0) {
        return <PrivatePage><Loading /></PrivatePage>
    }

    if (!view) {
        return <Navigate to="/" />
    } else {
        return (
            <PrivatePage title={view.name} period onViewPage>
                <Filters filtersType={FiltersType.VIEWSFSCROSS} filters={filters} />
                <FsCrossViewPageLayout view={view}>
                    <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        height="90vh"
                        p="1vw"
                    >
                        <FsKpis KPIsConfig={KPIsFsCrossConfig} />
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            justifyContent="flex-start"
                            pt="1vw"
                            sx={{ height: '80vh' }}
                        >
                            <Grid item sx={{ height: '40%' }}>
                                <FsCrossLineChart />
                            </Grid>
                            <Grid
                                container
                                wrap="nowrap"
                                pt="1vw"
                                sx={{ height: '60%' }}
                            >
                                <Grid
                                    item
                                    flex={1.5}
                                    sx={{ height: '100%', width: '14vw' }}

                                >
                                    <FsCrossSegmentation title={`Segmentation utilisateurs par volume (${Units.kg})`} />
                                </Grid>
                                <Grid
                                    container
                                    flexWrap="nowrap"
                                    direction="column"
                                    flex={2}
                                    pl="1vw"
                                    sx={{ height: '100%' }}
                                >
                                    <ChartBlock title="Répartition des ventes par distributeur">
                                        <Grid container wrap="nowrap" height="100%">
                                            <FsCrossTable />
                                        </Grid>
                                    </ChartBlock>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FsCrossViewPageLayout>
            </PrivatePage>
        )
    }
}

