import React, { useState, useCallback, useEffect } from 'react'
import { Alert, AlertTitle, Box, FormControl, Grid, Slide } from '@mui/material'
import {
    Field,
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import { boolean, object } from 'yup'
import { supabase } from '../../../../../supabaseClient.js'
import { PrimaryButton } from '../../../../../components/ui/Button'
import ErrorMessage from '../../../../../components/ui/ErrorMessage'
import { addUser } from '../../../../../redux/user/userSlice'
import { useAppDispatch } from '../../../../../redux/hooks'
import { useNavigate } from 'react-router-dom'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { GeneralCoonditions, InformationGrid } from './ResumeComponent'
import { ErrorMessageWithLink } from '../../../../../../src/components/ui/ErrorMessageWithLink'
import { StepContentComponent } from '../stepper/Stepper.js'

export interface SignUpData {
    email: string
    password: string
    firstname: string
    lastname: string
    phoneNumber: string
    job: string
    useCases: string[]
}

interface FormValues extends SignUpData {
    cgv: boolean
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    errorSupabase: string
    initialValues: FormValues
    isDisable: boolean
}

interface Inputs {
    label: string
    initialValue: string | string[] | number
    type?: string
    other?: string
    data?: string[]
    dataLabel?: string
}

const PureSignUpResumeForm = (
    props: FormikProps<FormValues> & FormProps
): JSX.Element => {
    const personalInformations: Inputs[] = [
        {
            label: 'Email',
            initialValue: props.initialValues.email,
            type: 'text',
        },
        {
            label: 'Mot de passe',
            initialValue: props.initialValues.password,
            type: 'password',
        },
        {
            label: 'Prénom',
            initialValue: props.initialValues.firstname,
            type: 'text',
        },
        {
            label: 'Nom',
            initialValue: props.initialValues.lastname,
            type: 'text',
        },
        {
            label: 'Poste',
            initialValue: props.initialValues.job,
            type: 'text',
        },
        {
            label: 'Numéro de téléphone',
            initialValue: props.initialValues.phoneNumber,
            type: 'text',
        },
        {
            label: 'Mission(s)',
            initialValue: props.initialValues.useCases,
            type: 'list',
        },
    ]
    return (
        <Form style={{ width: '100%' }}>
            <Grid>
                <Grid>
                    <ChartBlock title="Résumé">
                        <Grid sx={{ p: { xs: '10px', md: '1vw' } }}>
                            {personalInformations.map((label, index) => (
                                <InformationGrid
                                    type={personalInformations[index].type}
                                    label={personalInformations[index].label}
                                    initialValue={
                                        personalInformations[index]
                                            .initialValue as string
                                    }
                                />
                            ))}
                        </Grid>
                    </ChartBlock>
                </Grid>
            </Grid>
            <Grid>
                <FormControl
                    sx={{
                        mt: '3vh',
                        '& svg:first-of-type': {
                            width: { xs: '20px', md: '2vw' },
                        },
                    }}
                >
                    <Field
                        type="checkbox"
                        name="cgv"
                        Label={{
                            label: <GeneralCoonditions />,
                        }}
                        component={CheckboxWithLabel}
                        style={{
                            '& svg': {
                                width: { xs: '22px', md: '1.5vw' },
                                height: { xs: '22px', md: '2.5vh' },
                            },
                        }}
                    />
                </FormControl>
                <Grid marginLeft={4}>
                    {props.errors.cgv ? (
                        <ErrorMessage msg={props.errors.cgv} />
                    ) : null}
                </Grid>
            </Grid>
            <Box mt="2vh" width="100%">
                <PrimaryButton
                    fullWidth
                    type="submit"
                    disabled={props.isDisable}
                >
                    S'inscrire
                </PrimaryButton>
            </Box>
            {props.errorSupabase ? (
                <Grid container mt="1vh" alignItems="center" wrap="nowrap">
                    <ErrorMessageWithLink
                        msg={props.errorSupabase}
                        linkMsg="connectez-vous."
                        link="/"
                    />
                </Grid>
            ) : null}
        </Form>
    )
}

const ControlledSignUpAccountForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props: FormProps) => ({
        cgv: false,
        ...props.initialValues,
    }),
    validationSchema: () => {
        return object().shape({
            cgv: boolean().oneOf(
                [true],
                'Vous devez accepter les termes et conditions'
            ),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureSignUpResumeForm)

const SignUpAlert = ({ isSuccess }: { isSuccess: boolean }) => {
    return (
        <Slide direction="up" in={isSuccess} mountOnEnter unmountOnExit>
            <Alert
                severity="success"
                sx={{
                    width: '25%',
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    fontSize: '1em',
                    padding: '1em',
                }}
            >
                <AlertTitle sx={{ fontSize: '1.2vmin' }}>Succès !</AlertTitle>
                Vous venez de recevoir{' '}
                <strong style={{ marginLeft: '5px' }}>
                    un email de vérification
                </strong>
                , cliquez sur le lien pour activer votre compte.
                <br /> <br /> Vous allez maintenant être redirigé vers la page
                de connexion !
            </Alert>
        </Slide>
    )
}

const SignUpResume = ({ getData }: StepContentComponent) => {
    const navigate = useNavigate()
    const initialValues = getData() as unknown as FormValues
    const [error, setError] = useState<string | null>(null)
    const [isSuccess, setSuccess] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const _addUser = useCallback(
        async (user: SignUpData, supabaseId: string) => {
            await dispatch(addUser(user, supabaseId))
        },
        [dispatch]
    )
    const [isDisable, setIsDisable] = useState<boolean>(false)
    useEffect(() => {
        if (isSuccess) {
            const interval = setInterval(() => {
                navigate('/')
            }, 6000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [isSuccess, navigate])

    const handleSubmit = async (values: FormValues) => {
        setIsDisable(true)

        const { error, data } = await supabase.auth.signUp({
            email: values.email,
            password: values.password,
        })

        if (error) {
            setError(error)
            setIsDisable(false)
        }
        if (data.user.identities.length > 0) {
            await _addUser(values, data.user.id)

            localStorage.setItem('first_connection', 'yes')
        }
        setSuccess(true)
    }

    return (
        <Grid container direction="column">
            <ControlledSignUpAccountForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                errorSupabase={error}
                isDisable={isDisable}
            />
            <SignUpAlert isSuccess={isSuccess} />
        </Grid>
    )
}

export default SignUpResume
