import React from 'react'
import { Grid, Tab, Tabs } from '@mui/material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CloseIcon from '@mui/icons-material/Close'
import { SubTitle, Title } from '../../ui/Text'
import { Notification } from '../../../redux/notifications/notificationsTypes'
import { BLACK } from '../../ui/Colors'

interface DrawerHeaderProps {
    setOpen: (state: boolean) => void
    handleChange: () => void
    notificationsArchived: Notification[]
    notificationsNotArchived: Notification[]
    tabValue: number
}

const DrawerHeader = ({
    tabValue,
    setOpen,
    handleChange,
    notificationsNotArchived,
    notificationsArchived,
}: DrawerHeaderProps) => {
    return (
        <Grid p="1vw" position="sticky" container justifyContent="center">
            <Grid>
                <CloseIcon
                    sx={{
                        position: 'absolute',
                        cursor: 'pointer',
                        right: '1vw',
                        top: '2vh',
                    }}
                    onClick={() => setOpen(false)}
                />
                <Grid container alignItems="center" flexWrap="nowrap">
                    <AutoAwesomeIcon
                        sx={{
                            color: '#FFC700',
                            width: 54,
                            height: 54,
                            ml: 1,
                        }}
                    />
                    <Grid padding={2} fontSize={20}>
                        <Title color={BLACK}>Recommandations</Title>
                        <SubTitle color={BLACK}>
                            Vos risques et opportunités dans un fil de
                            notifications
                        </SubTitle>
                    </Grid>
                </Grid>
            </Grid>
            {/*  <Tabs onChange={handleChange} value={tabValue}>
                <Tab
                    label={`Fil d'opportunités (${notificationsNotArchived.length})`}
                />
                <Tab
                    label={`Notifications archivées (${notificationsArchived.length})`}
                />
            </Tabs> */}
        </Grid>
    )
}

export default DrawerHeader
