import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import Navigation, { type NavigationData } from './Navigation'
import { supabase } from '../../../supabaseClient.js'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { resetUser } from '../../../redux/user/userSlice'
import { resetDashboard } from '../../../redux/global/globalSlice'
import {
    resetActiveFsFilters,
    resetActiveRetailFilters,
    resetFSFilters,
    resetRetailFilters,
} from '../../../redux/filters/filtersSlice'
import { UsersRole } from '../../../redux/user/userTypes'
import BusinessIcon from '@mui/icons-material/Business';

const HelpNavigation = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user.user)

    const handleLogout = async () => {
        await supabase.auth.signOut()

        dispatch(resetUser())
        dispatch(resetDashboard())
        dispatch(resetFSFilters())
        dispatch(resetRetailFilters())
        dispatch(resetActiveFsFilters())
        dispatch(resetActiveRetailFilters())
    }

    const handleSupport = () => {
        window.open(
            'https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed',
            '_blank'
        )
    }

    const links: NavigationData[] = [
        {
            id: 0,
            label: 'Mon profil',
            link: '/parametres/profil',
            icon: <PermIdentityIcon />,
        },
        ...user.role === UsersRole.ADMIN ? [{
            id: 1,
            label: 'Gestion des membres',
            link: '/parametres/administration',
            icon: <BusinessIcon />
        }] : [],
        {
            id: 1,
            label: 'Support',
            link: '',
            icon: <HelpOutlineIcon />,
            customAction: handleSupport,
        },
        {
            id: 2,
            label: 'Déconnexion',
            link: '',
            icon: <LogoutOutlinedIcon />,
            customAction: handleLogout,
        },
    ]

    return <Navigation data={links} />
}

export default HelpNavigation
