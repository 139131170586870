import { CircularProgress, Grid } from '@mui/material'
import React from 'react'
import { PRIMARY } from '../../ui/Colors'
import Box from '@mui/material/Box'
import Logo from '../../../assets/Loading-logo.png'
import { PrimaryText } from '../../ui/Text'


export function CircularProgressWithLogo({ text }: { text: boolean }) {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
            }}
        >
            <CircularProgress
                size={110}
                sx={{
                    opacity: 0.9,
                    color: PRIMARY,
                    width: '8vw !important',
                    height: '8vw !important',
                }}
            />
            <Grid
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img src={Logo} alt="Logo Karyon" style={{ width: '6vw' }} />
                {text && (
                    <Grid
                        sx={{
                            width: '30vw',
                            textAlign: 'center',
                            position: 'absolute',
                            marginTop: '40vh',
                        }}
                    >
                        <PrimaryText>
                            Vos données sont en cours de chargement. Cela peut
                            prendre jusqu'à 30 secondes ...
                        </PrimaryText>
                        <PrimaryText marginTop="4vh">
                            (L'occasion rêvée pour spéculer sur vos chiffres ! A
                            votre avis, quel point de vente a enregistré la
                            croissance maximale ?)
                        </PrimaryText>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export function CircularProgressWithLogoWithoutText() {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
            }}
        >
            <CircularProgress
                size={100}
                sx={{
                    opacity: 0.9,
                    color: PRIMARY,
                    width: '5vw !important',
                    height: '5vw !important',
                }}
            />
            <Grid
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img src={Logo} alt="Logo Karyon" style={{ width: '4vw' }} />
            </Grid>
        </Box>
    )
}

const Loading = ({
    text = true,
    height = '86vh',
}: {
    text?: boolean
    height?: string
}) => {
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ height }}
        >
            <CircularProgressWithLogo text={text} />
        </Grid>
    )
}

export default Loading
