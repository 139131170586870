import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    type ChartOptions,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import DoughnutLabels, { labels } from './DoughnutLabels'
import { Grid, Tooltip as MUITooltip, Typography } from '@mui/material'
import { useAppSelector } from '../../../../../redux/hooks'
import { PrimaryText } from '../../../../ui/Text'
import { BLACK } from '../../../../ui/Colors'
import { EnvName, EnvType } from '../../../../../redux/user/userTypes'
import { Units } from '../../../../../redux/global/globalTypes'

ChartJS.register(ArcElement, Tooltip, Legend)

const isEmptyDoughtnut = (data: number[]) => {
    const isAllEmpty = data.every((el) => el === 0)
    return isAllEmpty
}

const getEmptyDoughtnutTooltip = (
    clientsLastYear: string[],
    dataLastYear: number[]
) => {
    const formatedTooltip = clientsLastYear.map((client, index) => {
        return (
            <Grid>
                {client}: {dataLastYear[index]} client(s)
            </Grid>
        )
    })
    return formatedTooltip
}

const sortClients = (
    data: number[],
    labels: string[],
    clients?: number[],
    evolutionClients?: number[],
    evolution?: number[]
) => {
    const list = []
    data.forEach((elem, index) =>
        list.push({
            base: data[index],
            second: labels[index],
            third: clients ? clients[index] : 0,
            fourth: evolutionClients ? evolutionClients[index] : 0,
            five: evolution ? evolution[index] : 0,
        })
    )

    list.sort((a, b) => {
        return a.base < b.base ? 1 : a.base === b.base ? 0 : -1
    })

    const newData = []
    const newLabels = []
    const newClients = []
    const newEvolutionClients = []
    const newEvolutions = []

    list.forEach((elem, index) => {
        newData[index] = list[index].base
        newLabels[index] = list[index].second
        newClients[index] = list[index].third
        newEvolutionClients[index] = list[index].fourth
        newEvolutions[index] = list[index].five
    })

    return {
        data: newData,
        labels: newLabels,
        clients: newClients,
        evolutionClients: newEvolutionClients,
        evolutions: newEvolutions,
    }
}

const DoughnutChart = ({
    setFieldSelected,
    setOpen,
}: {
    setFieldSelected: (field: any) => void
    setOpen: (open: boolean) => void
}) => {
    const [isLabels, setIsLabels] = useState<boolean>(false)
    const chartRef = React.useRef()
    const { env } = useAppSelector((state) => state.global)
    const { clients } =
        env.type === EnvType.FOOD_SERVICE
            ? useAppSelector((state) => state.global.fsDashboardData)
            : useAppSelector((state) => state.global.fsCrossDashboardData)
    const { unit } = useAppSelector((state) => state.global)

    useEffect(() => {
        setIsLabels(true)
    }, [chartRef])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (e, elements) => {

            if (env.type === EnvType.FOOD_SERVICE_CROSS) { return null }
            const index = clients.data.findIndex(
                (el) => el === elements[0].element.$context.parsed
            )

            setOpen(true)
            setFieldSelected({
                id: clients.labels[index],
                row: {
                    volume: clients.data[index],
                    volumeLastYear:
                        clients.dataLastYear[index],
                    clients: clients.clients[index],
                    products: clients.products[index],
                    productsLastYear:
                        clients.productsLastYear[index],
                    differenceWeight:
                        clients.differenceWeight[index],
                    differenceClients:
                        clients.differenceClients[
                        index
                        ],
                    differenceProducts:
                        clients.differenceClients[
                        index
                        ],
                    warehouses:
                        clients.warehouses[index],
                    warehousesLastYear:
                        clients.warehousesLastYear[
                        index
                        ],
                    VMM: clients.VMM[index],
                    VMMLastYear:
                        clients.VMMLastYear[index],
                    differenceVMM:
                        clients.differenceVMM[index],
                },
            })
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any) => {
                        const labelIndex = model.dataIndex

                        const total = model.dataset.data.reduce(
                            (acc, curr) => acc + curr,
                            0
                        )

                        return `${sortedClients.labels[labelIndex]
                            } : ${Math.round((model.parsed / total) * 100)}%`
                    },
                    footer: (model: any) => {
                        const labelIndex = model[0].dataIndex
                        const sells = Math.round(model[0].parsed)
                        const clientsNumber = sortedClients.clients[labelIndex]

                        const clientsEvolution =
                            sortedClients.evolutionClients[labelIndex]

                        const sellsEvolution = Math.round(
                            sortedClients.evolution[labelIndex]
                        )
                        const printedVolume = `Volume : ${sells
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${unit}`

                        const printedClient = `\nNombre d'utilisateurs finaux: ${clientsNumber
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}` // Add space in number : 1000 => 1 000

                        const printedSellsEvolution = sellsEvolution
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const printedClientsEvolution = Math.round(
                            clientsEvolution
                        )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const addArrow = (number: string) => {
                            if (number === '0') {
                                return ''
                            } else if (number > '0') {
                                return '↑'
                            } else {
                                return '↓'
                            }
                        }

                        const getTooltip = (distrib: string) => {
                            switch (distrib) {
                                case EnvName.FRANCE_FRAIS:
                                    return (
                                        printedVolume +
                                        `\nÉvolution volume : ${addArrow(
                                            printedSellsEvolution
                                        )} ${printedSellsEvolution} % ${printedClient}\nÉvolution utilisateurs finaux : ${addArrow(
                                            printedClientsEvolution
                                        )} ${printedClientsEvolution} %`
                                    )
                                case EnvName.TRANSGOURMET:
                                case EnvName.PASSIONFROID:
                                case EnvName.SODEXO:
                                case EnvName.CERCLEVERT:
                                case EnvName.EPISAVEURS:
                                case EnvName.FOOD_SERVICE_CROSS:
                                    return (
                                        printedVolume +
                                        `\nÉvolution volume : ${addArrow(
                                            printedSellsEvolution
                                        )} ${printedSellsEvolution} %`
                                    )
                                case EnvName.METRO:
                                    return `CA: ${sells.toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${Units.euro}`
                                default:
                                    return (
                                        printedVolume +
                                        `\nÉvolution volume : ${addArrow(
                                            printedSellsEvolution
                                        )} ${printedSellsEvolution} % ${printedClient}\nÉvolution utilisateurs finaux : ${addArrow(
                                            printedClientsEvolution
                                        )} ${printedClientsEvolution} %`
                                    )
                            }
                        }
                        const printedTooltip = getTooltip(env.name)

                        return printedTooltip
                    },
                },
            },
        },
    }

    const sortedType = sortClients(
        clients.data,
        clients.labels,
        clients.clients,
        clients.evolutionClients,
        clients.evolution
    )

    const sortedClients = {
        data: sortedType.data,
        labels: sortedType.labels,
        clients: sortedType.clients,
        evolutionClients: sortedType.evolutionClients,
        evolution: sortedType.evolutions,
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            sx={{ height: '100%' }}
        >
            {isEmptyDoughtnut(sortedType.data) ? (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={4}
                >
                    <PrimaryText color={BLACK}>
                        Aucun résultat sur la période sélectionnée,
                    </PrimaryText>
                    <PrimaryText bold color={BLACK}>
                        modifiez vos filtres.
                    </PrimaryText>
                    {/* TODO : we suppress data in back, we need to send clientsLastYear 
                    <MUITooltip
                        title={getEmptyDoughtnutTooltip(
                            sortedType.labels,
                            sortedType.clientsLastYear
                        )}
                    > */}
                    <Typography mt="1vh" sx={{ textDecoration: 'underline' }}>
                        Voir A-1
                    </Typography>
                    {/* </MUITooltip> */}
                </Grid>
            ) : (
                <Grid container flexDirection="row" height="70%">
                    <Grid
                        container
                        flex={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Doughnut
                            ref={chartRef}
                            updateMode="none"
                            style={{ maxWidth: '100%' }}
                            options={options as any}
                            data={{
                                datasets: [
                                    {
                                        data: sortedType.data,
                                        backgroundColor:
                                            labels[0].backgroundColor,
                                        borderWidth: 0,
                                        borderColor: 'black',
                                    },
                                ],
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        flex={1.8}
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'auto',
                            maxHeight: '90%',
                            minWidth: '12vw',
                        }}
                    >
                        {isLabels ? (
                            <DoughnutLabels
                                chart={chartRef.current}
                                setOpen={setOpen}
                                setFieldSelected={setFieldSelected}
                            />
                        ) : (
                            'loading'
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default DoughnutChart
