import { useSyncExternalStore } from 'react'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../../../helpers/ExternalStore'
import React from 'react'
import { formateLegend } from '../../../../../../components/features/foodService/dashboard/utils/formateProductsData'
import { Grid } from '@mui/material'
import { Line } from 'react-chartjs-2'
import { backgroundColor } from '../../ProductsPage/LineChartProduct'
import { Chart } from '../../../../../../redux/fsProducts/fsProductsTypes'

export const legendMarginPlugin = [
    {
        id: 'increase-legend-spacing',
        beforeInit(chart) {
            const originalFit = (chart.legend as any).fit
            ;(chart.legend as any).fit = function fit() {
                originalFit.bind(chart.legend)()
                this.height += 20
            }
        },
    },
]
export const EvolLineChart = ({
    evolution,
    period,
}: {
    evolution: Chart[]
    period: string[]
}) => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    const chartRef = React.useRef()

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        pointBorderWidth: height * 0.005,
        borderWidth: height * 0.004,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: {
                ticks: {
                    font: { size: height * 0.012 },
                },
            },
            y: {
                ticks: {
                    font: { size: height * 0.012 },
                },
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
                padding: 23,
                labels: {
                    paddingBottom: 20,
                },
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any, tooltipItem: any) => {
                        const sells = Math.round(model.raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return ` ${model.dataset.name} : ${sells} %`
                    },
                },
            },
        },
    }

    const formatedLegend = formateLegend(period)

    const datasets = evolution.map((element, index) => {
        const color = backgroundColor[index % 33]

        return {
            name: element.name,
            data: [null, ...element.data, null],
            borderColor: color,
            backgroundColor: color,
            label: element.name,
        }
    })

    const data = {
        labels: [null, ...formatedLegend, null],
        datasets: datasets,
    }

    return (
        <Grid
            container
            sx={{
                height: '36vh',
                padding: '1vw',
            }}
            wrap="nowrap"
            alignItems="flex-start"
        >
            <Line
                ref={chartRef}
                options={options as any}
                data={data}
                plugins={legendMarginPlugin}
            />
        </Grid>
    )
}
