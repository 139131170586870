import React, { useEffect, useCallback, useState } from 'react'
import { Grid } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { ActiveFsCrossFilters, FsCrossViewFilters } from '../../../../../redux/filters/filtersTypes'
import { NoDataScreen } from '../../../../../components/features/screens/NoDataScreen'
import { EnvStatus } from '../../../../../redux/user/userTypes'
import { fetchFilteredDashboard } from '../../../../../redux/global/globalSlice'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import WaitingScreen from '../../../../../components/features/screens/WaitingScreen'
import { setActiveFsCrossViewFilters, setFsCrossViewFilters } from '../../../../../redux/filters/filtersSlice'
import { View } from '../../../../../redux/views/viewsTypes'

const FsCrossViewPageLayout = ({ children, view }: { children: JSX.Element, view: View }) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dispatch = useAppDispatch()
    const dashboard = useAppSelector(
        (state) => state.global.fsCrossDashboardData
    )
    const { activeFsCrossViewFilters } = useAppSelector((state) => state.filters)
    const { isLoading, unit, env } = useAppSelector((state) => state.global)
    const { brand } = useAppSelector((state) => state.user.user)
    const _fetchFsDashboard = useCallback(
        async (filters: ActiveFsCrossFilters) => {
            await dispatch(fetchFilteredDashboard(filters, false))
        },
        [dispatch]
    )
    const filters = view.filters as FsCrossViewFilters

    useEffect(() => {
        dispatch(
            setFsCrossViewFilters({
                startDate: filters.startDate,
                endDate: filters.endDate,
                products: filters.products,
                clients: filters.clients,
                distributors: filters.distributors,
                min: filters.min,
                max: filters.max
            })
        )
        dispatch(
            setActiveFsCrossViewFilters({
                startDate: filters.startDate,
                endDate: filters.endDate,
                products: filters.products,
                clients: filters.clients,
                distributors: filters.distributors,
                min: filters.min,
                max: filters.max
            })
        )
    }, [filters])

    useEffect(() => {
        if (brand && activeFsCrossViewFilters.startDate) {
            _fetchFsDashboard(activeFsCrossViewFilters)
            setIsFirstLoad(false)
        } else if (activeFsCrossViewFilters.startDate === '') {
            setIsFirstLoad(false)
        }
    }, [brand, activeFsCrossViewFilters, _fetchFsDashboard, unit])

    if (
        isLoading ||
        isFirstLoad ||
        (!dashboard && env.status !== EnvStatus.ON_LOADING_INIT)
    ) {
        return <Loading />
    }

    if (
        !dashboard ||
        !activeFsCrossViewFilters.startDate ||
        env.status === EnvStatus.ON_LOADING_INIT
    ) {
        return <WaitingScreen />
    }

    if (dashboard.productsTopFlop.length === 0) {
        return <NoDataScreen />
    }
    return <Grid>{children}</Grid>
}
export default FsCrossViewPageLayout
