import { DialogContent, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import React, { useState, useCallback, useEffect } from 'react'
import { date, object, ref } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import FilterSelect from '../../filters/FilterSelect'
import FilterCalendar from '../../filters/FilterCalendar'
import { PrimaryText } from '../../../ui/Text'
import { DialogFooter } from '../../Dialog/BasicFooterDialog'
import { type StepConfig } from '../../../../redux/views/viewsTypes'
import {
    closeViewCreator,
    updateData,
} from '../../../../redux/views/viewsSlice'
import { trashFsFilters } from '../../../../redux/filters/filtersSlice'
import {
    getDefaultPeriod,
    getPlaceholderDates,
    monthAsArray,
} from '../../../../helpers/months'
import { EnvType } from '../../../../redux/user/userTypes'
import { formateDateToString } from '../../utils/formateDateToString'
import { FiltersItem, MenuType, getFiltersConfig } from '../../filters/utils'
import InventoryIcon from '@mui/icons-material/Inventory'
import { differenceInMonths } from '../../GlobalPages/Private/Period'

interface OwnProps {
    config: StepConfig
    step: number
}

interface FormValues {
    startDate: Date
    endDate: Date
    min: Date
    max: Date
    products: string[]
    regions: string[]
    clients: string[]
    categories: string[]
}

interface FormProps {
    activeValues: FormValues
    initialDates: {
        startDate: Date
        endDate: Date
    }
    config: StepConfig
    step: number
    onSubmit: (values: FormValues) => void
    filtersConfig: FiltersItem[]
}

const PureViewFilterStepForm = ({
    setFieldValue,
    submitForm,
    filtersConfig,
    ...props
}: FormikProps<FormValues> & FormProps) => {
    const [modified, setModified] = useState(false)
    const periodByDefault = getDefaultPeriod()
    const [printedPeriod, setPrintedPeriod] = useState<string | null>(
        periodByDefault
    )

    useEffect(() => {
        if (props.values.startDate && props.values.endDate) {
            const period = getPlaceholderDates(
                props.values.startDate,
                props.values.endDate
            )

            setPrintedPeriod(period)
        }
    }, [props.values])

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent>
                <Grid mt="5vh" mb="5vh">
                    <Grid
                        container
                        style={{
                            padding: 4,
                        }}
                    >
                        <PrimaryText width="30%">Mois et année : </PrimaryText>
                        <Grid sx={{ flex: 1, width: '100%' }}>
                            <FilterCalendar
                                isModified={modified}
                                placeholder={printedPeriod}
                                setFieldValue={setFieldValue}
                                setModified={setModified}
                                start={props.values.startDate}
                                end={props.values.endDate}
                                errorEndDate={props.errors.endDate as string}
                                errorStartDate={
                                    props.errors.startDate as string
                                }
                                isDialogFilter

                            />
                        </Grid>
                    </Grid>
                    {filtersConfig.map((filter) => (
                        <Grid
                            container
                            style={{
                                padding: 4,
                            }}
                        >
                            <PrimaryText width="30%">
                                {filter.placeholder} :{' '}
                            </PrimaryText>
                            <FilterSelect
                                name={filter.name}
                                icon={filter.icon}
                                placeholder={filter.placeholder}
                                type={filter.type}
                                values={props.values[filter.name]}
                                setFieldValue={setFieldValue}
                                key={filter.id}
                                env={EnvType.FOOD_SERVICE}
                                isDialogFilter
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={
                    props.step === 0
                        ? props.config.handleReset
                        : props.config.handlePrevious
                }
                leftButtonText={props.step === 0 ? 'ANNULER' : 'PRÉCÉDENT'}
                rightButtonFunc={async () => {
                    await submitForm()
                }}
                rightButtonText={
                    props.step === props.config.stepsNumber - 1
                        ? 'CRÉER MA VUE'
                        : 'SUIVANT'
                }
            />
        </Form>
    )
}

const ControlledFsViewsFiltersForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        startDate: props.activeValues.startDate,
        endDate: props.activeValues.endDate,
        min: props.activeValues.min,
        max: props.activeValues.max,
        products: props.activeValues.products,
        regions: props.activeValues.regions,
        clients: props.activeValues.clients,
        categories: props.activeValues.categories,
    }),
    enableReinitialize: true,
    validationSchema: (props) => {
        const startMonth = props.activeValues.min.getMonth()
        const startYear = props.activeValues.min.getFullYear()
        const endMonth = props.activeValues.max.getMonth()
        const endYear = props.activeValues.max.getFullYear()

        return object().shape({
            startDate: date()
                .typeError('Entrez une date valide')
                .min(
                    props.activeValues.min,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de début'),
            endDate: date()
                .typeError('Entrez une date valide')
                .min(
                    ref('startDate'),
                    'Sélectionnez une date plus ancienne que celle de début'
                )
                .max(
                    props.activeValues.max,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de fin')
                .test(
                    'is-within-12-months',
                    'Vous ne pouvez pas sélectionner plus de 12 mois consécutifs.',
                    function (endDate) {
                        const { startDate } = this.parent; //Différent des pages car props.date.startDate est undefined
                        if (startDate && endDate) {
                            const monthsDifference = differenceInMonths(startDate, endDate);
                            return monthsDifference <= 11;
                        }
                        return true; // Si les dates ne sont pas définies, on passe.
                    }
                ),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureViewFilterStepForm)

const FsViewsFiltersForm = ({ step, config }: OwnProps) => {
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)
    const { fsFilters } = useAppSelector((state) => state.filters)

    const [filtersConfig, setFiltersConfig] = useState<FiltersItem[]>([
        {
            id: 0,
            name: 'products',
            icon: <InventoryIcon />,
            placeholder: 'Références',
            type: MenuType.PRODUCTS,
        },
    ])

    useEffect(() => {
        const keysFilters = fsFilters ? Object.keys(fsFilters) : []
        const config = getFiltersConfig(keysFilters, env.name)
        setFiltersConfig(config)
    }, [fsFilters])

    const _trashFsFilters = useCallback(
        () => dispatch(trashFsFilters()),
        [dispatch]
    )
    const _closeViewCreator = useCallback(
        () => dispatch(closeViewCreator()),
        [dispatch]
    )

    const _updateData = useCallback(
        ({
            clients,
            products,
            startDate,
            endDate,
            regions,
            categories,
            min,
            max
        }: {
            clients: string[]
            products: string[]
            startDate: string
            endDate: string
            regions: string[]
            categories: string[]
            min: string
            max: string
        }) =>
            dispatch(
                updateData({
                    data: {
                        filters: {
                            clients,
                            products,
                            startDate,
                            endDate,
                            regions,
                            categories,
                            min,
                            max
                        },
                    },
                })
            ),
        [dispatch]
    )

    const activeValues = {
        startDate: new Date(activeFsFilters.startDate),
        endDate: new Date(activeFsFilters.endDate),
        min: new Date(activeFsFilters.min),
        max: new Date(activeFsFilters.max),
        products: activeFsFilters.products,
        regions: activeFsFilters.regions ? activeFsFilters.regions : [],
        clients: activeFsFilters.clients,
        categories: activeFsFilters.categories,
    }

    const initialDates = {
        startDate: new Date(fsFilters.startDate),
        endDate: new Date(fsFilters.endDate),
    }

    activeValues.startDate.setHours(0, 0, 0)
    activeValues.endDate.setHours(0, 0, 0)
    activeValues.min.setHours(0, 0, 0)
    activeValues.max.setHours(0, 0, 0)
    initialDates.startDate.setHours(0, 0, 0)
    initialDates.endDate.setHours(0, 0, 0)


    const handleSubmit = (values: FormValues) => {
        const formatedDate = formateDateToString(
            values.startDate,
            values.endDate
        )


        _updateData({
            products: values.products,
            clients: values.clients,
            startDate: formatedDate.start,
            endDate: formatedDate.end,
            min: activeFsFilters.min,
            max: activeFsFilters.max,
            regions: values.regions,
            categories: values.categories,
        })
        config.handleSubmit()
        _trashFsFilters()
        _closeViewCreator()
    }


    return (
        <ControlledFsViewsFiltersForm
            config={config}
            step={step}
            onSubmit={handleSubmit}
            activeValues={activeValues}
            filtersConfig={filtersConfig}
            initialDates={initialDates}
        />
    )
}

export default FsViewsFiltersForm
