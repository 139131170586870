import React, { useCallback, useState } from 'react'
import { supabase } from '../../../supabaseClient.js'
import {
    Field,
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { object, string } from 'yup'
import Box from '@mui/material/Box'
import { TextInput } from '../../../components/ui/Inputs'
import ErrorMessage from '../../../components/ui/ErrorMessage'
import { Alert, AlertTitle, Grid, Slide } from '@mui/material'
import { PrimaryText } from '../../../components/ui/Text'
import { LinkButton, PrimaryButton } from '../../../components/ui/Button'
import PublicForm, {
    TextAlignement,
} from '../../../components/features/PublicForm'
import PublicPage from '../../../components/features/GlobalPages/PublicPage'
import { useAppDispatch } from '../../../redux/hooks'
import { askRecoveryPasswordActivity } from '../../../redux/user/userSlice'


interface FormValues {
    email: string
}

interface FormProps {
    error: boolean
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

const PurePasswordRecoveryForm = (
    props: FormikProps<FormValues> & FormProps
): JSX.Element => {
    return (
        <Form>
            <Grid container textAlign="center">
                <Field
                    sx={{
                        '& label': {
                            fontSize: '1.6vmin',
                        },
                    }}
                    margin="normal"
                    fullWidth
                    label="Email"
                    name="email"
                    type="text"
                    component={TextInput}
                />

                {props.error ? (
                    <ErrorMessage msg="Erreur, merci de réessayer." />
                ) : null}

                <Box mt={8} sx={{ width: '100%' }}>
                    <PrimaryButton fullWidth type="submit">
                        Envoyer le lien
                    </PrimaryButton>
                </Box>
                <Box mt={2} sx={{ width: '100%' }}>
                    <LinkButton to="/" fullWidth>
                        Revenir à la page d'acceuil
                    </LinkButton>
                </Box>
            </Grid>
        </Form>
    )
}

const ControlledPasswordRecoveryForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        email: '',
    }),
    validationSchema: () => {
        return object().shape({
            email: string()
                .email('L’email doit être une adresse valide.')
                .required('Le champ Email est obligatoire.'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PurePasswordRecoveryForm)

export default function PasswordRecovery() {
    const [signError, setSignError] = useState<boolean>(false)
    const [isSuccess, setSucess] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const _askRecoveryPasswordActivity = useCallback(
        (email: string) =>
            dispatch(askRecoveryPasswordActivity(email)),
        [dispatch]
    )
    const handleSubmit = async (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        _askRecoveryPasswordActivity(values.email)
        const { error } = await supabase.auth.resetPasswordForEmail(
            values.email,
            { redirectTo: '/setnewpassword' }
        )
        if (error) {
            setSignError(true)
        }
        setSucess(true)
    }

    return (
        <PublicPage>
            <Box>
                <PublicForm
                    align={TextAlignement.LEFT}
                    title="Mot de passe oublié ? Ça arrive même aux meilleurs..."
                    subtitle={
                        <PrimaryText>
                            Entrez votre adresse email pour recevoir un lien de
                            réintialisation de votre mot de passe.
                        </PrimaryText>
                    }
                >
                    <ControlledPasswordRecoveryForm
                        onSubmit={handleSubmit}
                        error={signError}
                    />
                </PublicForm>
                <Slide direction="up" in={isSuccess} mountOnEnter unmountOnExit>
                    <Alert
                        severity="success"
                        sx={{
                            width: '25%',
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            fontSize: '1em',
                            padding: '1em',
                        }}
                    >
                        <AlertTitle sx={{ fontSize: '1.1em' }}>
                            Succès
                        </AlertTitle>
                        Si votre email est bien associé à un compte, vous
                        recevrez un message
                        <strong style={{ marginLeft: '5px' }}>
                            Consultez votre boite mail
                        </strong>
                    </Alert>
                </Slide>
            </Box>
        </PublicPage>
    )
}
