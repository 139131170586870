import React from "react"
import { Grid } from "@mui/material"
import { PrimaryText, SubTitle } from "../ui/Text"
import { BLACK } from "../ui/Colors"
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { KpiCardType } from "./PrimaryKpiCard"
import SquareIcon from '@mui/icons-material/Square';

export const GaugeCard = ({
    name,
    number,
    difference,
    evolution
}: KpiCardType) => {

    return (

        <Grid width="100%" container direction="row" justifyContent="space-between" alignItems="center">
            <Grid container direction="column" flex={1}>
                <PrimaryText bold color={BLACK} gutterBottom>
                    {name}
                </PrimaryText>
                <SubTitle color={BLACK}>{`Total : ${number.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`} </SubTitle>
                <Grid container direction="row" alignItems="center" >
                    <SquareIcon fontSize="small" color="primary" />
                    <SubTitle ml="0.5vw" color={BLACK}>  {`Investi : ${difference.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`}</SubTitle>
                </Grid>
            </Grid>
            <Grid >
                <Gauge width={100} height={80} value={evolution} innerRadius="80%"
                    outerRadius="100%" startAngle={-120}
                    endAngle={120}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontFamily: ['Cabin', 'sans-serif'],
                        },
                    }}
                    text={
                        ({ value }) => { return value === null ? `- %` : `${value} %` }
                    } />
            </Grid>

        </Grid>

    )
}