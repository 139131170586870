import React, { useCallback } from 'react'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { type User } from '../../../redux/user/userTypes'
import { BLACK } from '../../ui/Colors'
import { Field, type FormikBag, type FormikProps, withFormik } from 'formik'
import { PrimaryText } from '../../ui/Text'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import DistributerSelector from '../DistributerSelector'
import { TextInput } from '../../ui/Inputs'
import {
    MINIMUM_CHARACTER_FOR_OTHERS,
    retailDistributor,
} from '../../../helpers/distributorsList'
import { PrimaryButton } from '../../ui/Button'
import { hasOthers } from '../../../pages/public/authentication/signUp/steps/SignUpRetail'
import { array, object, string } from 'yup'
import ErrorMessage from '../../ui/ErrorMessage'
import { fetchUpdateRetailDistributor } from '../../../redux/companies/companiesSlice'
import { CompanyData } from '../../../redux/companies/companiesTypes'

export interface EditValuesRetail {
    distributerRetail: string[]
    dataRetail: string[]
    distributerRetailStartingList?: string[]
    otherRetail?: string
    hasOthersRetail?: boolean
}

interface FormProps {
    user: Partial<User>
    onSubmit: (values: EditValuesRetail) => void
    onClose: () => void
}

const inputsdata: EnhancedQuestion = {
    id: 0,
    type: 'distributer',
    list: retailDistributor,
    name: 'distributerRetail',
}
export interface DistributorComponentProps {
    type: string
    name: string
    list?: string[]
}

export interface EnhancedQuestion extends DistributorComponentProps {
    id: number
    list?: string[]
}

const DistributorComponent = ({
    type,
    name,
    list,
    ...props
}: DistributorComponentProps & FormikProps<EditValuesRetail> & FormProps) => {
    const toggleDistributer = (distributor: string) => {
        const isInclude = props.values[name].includes(distributor)
        if (isInclude) {
            props.setFieldValue(
                name,
                props.values[name].filter((el) => el !== distributor)
            )
        } else {
            props.setFieldValue(name, [...props.values[name], distributor])
        }
    }

    const handleAddButton = ({ otherRetail }: { otherRetail: string }) => {
        if (!props.errors.otherRetail) {
            props.setFieldValue('otherRetail', '')
            toggleDistributer(otherRetail)
        }
    }

    return (
        <Grid>
            <Grid alignItems="center" justifyContent="center" width="40vw">
                <Grid item flex={2}>
                    <PrimaryText color={BLACK}>
                        Quels distributeurs souhaitez-vous ajouter ?
                    </PrimaryText>

                    <DistributerSelector
                        list={list}
                        values={props.values[name]}
                        selectorHandler={(distributor) => {
                            toggleDistributer(distributor)
                        }}
                    />
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    marginTop={3}
                    flexWrap="nowrap"
                >
                    <Grid item>
                        <Field
                            sx={{
                                '& label': {
                                    fontSize: '1.6vmin',
                                },
                            }}
                            label="Autre(s)"
                            type="text"
                            name="otherRetail"
                            component={TextInput}
                        />
                    </Grid>
                    <Grid item ml={3}>
                        <PrimaryButton
                            disabled={
                                props.values.otherRetail.length <
                                MINIMUM_CHARACTER_FOR_OTHERS
                            }
                            type="button"
                            onClick={() => {
                                handleAddButton({
                                    otherRetail: props.values.otherRetail,
                                })
                            }}
                        >
                            Ajouter
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const PureDistributorUpdateForm = (
    props: FormikProps<EditValuesRetail> & FormProps
): JSX.Element => {
    // The next 3 lines allows us to display the distributor list with the others the user just added
    const otherDistributors = props.values.distributerRetail.filter(
        (distributor) =>
            !retailDistributor.includes(distributor) &&
            !props.values.distributerRetailStartingList.includes(distributor)
    )
    const distributor = retailDistributor.filter(
        (distributor) =>
            !props.values.distributerRetailStartingList.includes(distributor)
    )

    const distributorListToDisplay = [...distributor, ...otherDistributors]

    return (
        <Grid container direction="column" alignItems="center">
            <Grid justifyContent="center" mt="5vh">
                <Grid container direction="column" justifyContent="center">
                    <DistributorComponent
                        name={inputsdata.name}
                        type={inputsdata.type}
                        list={distributorListToDisplay}
                        {...props}
                    />
                </Grid>
            </Grid>
            {props.submitCount > 0 && props.errors.distributerRetail && (
                <Grid item mt="3vh">
                    <ErrorMessage
                        msg={props.errors.distributerRetail as string}
                    />
                </Grid>
            )}
            <Grid width={'100%'} bottom={0} mt="5vh">
                <DialogFooter
                    leftButtonFunc={(e) => {
                        e.stopPropagation()
                        props.onClose()
                    }}
                    leftButtonText={'Annuler'}
                    rightButtonFunc={(e) => {
                        e.stopPropagation()
                        props.submitForm()
                    }}
                    rightButtonText={'Valider'}
                />
            </Grid>
        </Grid>
    )
}

const ControlledUpdateUserForm = withFormik<FormProps, EditValuesRetail>({
    mapPropsToValues: ({ user }) => ({
        distributerRetail: [],
        distributerRetailStartingList: user.retail
            ? user.retail.distributer
            : [],
        dataRetail: user.retail ? user.retail.data : [],
        otherRetail: '',
    }),
    validationSchema: () => {
        return object().shape({
            distributerRetail: array()
                .of(string().required())
                .required()
                .min(1, 'Sélectionner au moins un distributeur'),
            otherFS: string()
                .min(
                    MINIMUM_CHARACTER_FOR_OTHERS,
                    `Le nom du distributeur doit contenir au moins ${MINIMUM_CHARACTER_FOR_OTHERS} caractère`
                )
                .optional(),
        })
    },
    handleSubmit: (
        values: EditValuesRetail,
        bag: FormikBag<FormProps, EditValuesRetail>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureDistributorUpdateForm)

export const RetailDialogContent = ({
    setOpen,
}: {
    setOpen: (boolean) => void
}) => {
    const user = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _updateUser = useCallback(
        async (company: Partial<CompanyData> & EditValuesRetail) =>
            await dispatch(fetchUpdateRetailDistributor(company)),
        [dispatch]
    )

    const handleSubmit = async (values: EditValuesRetail) => {
        setOpen(false)
        const valuesToSend = {
            distributerRetail: [
                ...user.retail.distributer,
                ...values.distributerRetail,
            ],
            dataRetail: values.dataRetail,
            hasOthersRetail: hasOthers(
                values.distributerRetail,
                retailDistributor
            ),
        }
        await _updateUser(valuesToSend)
    }

    return (
        <Grid container direction="column" p="1vw">
            <ControlledUpdateUserForm
                onSubmit={handleSubmit}
                onClose={() => {
                    setOpen(false)
                }}
                user={user}
            />
        </Grid>
    )
}
