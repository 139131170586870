import React from 'react'
import { Grid } from '@mui/material'
import { DrillDownMenu } from '../../../../../../components/features/drillDowns/othersDrillDownComponents'
import { PromoInformationsTab } from './PromoInformationsTab'
import { PromoClientsTab } from './PromoClientsTab'
import { DrillDownContentProps } from '../../../../../../components/features/drillDowns/ClientFFRDrillDownContent'

export const PromoDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
}: DrillDownContentProps) => {
    return (
        <Grid height="66vh" width={'100%'}>
            <Grid
                sx={{
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                    '& .MuiTab-root.Mui-selected': {
                        color: '#666666',
                    },
                    '& .MuiTab-root': {
                        fontSize: { xs: '16px', md: '1.6vmin' },
                        padding: '1vw 2vh',
                        maxWidth: 'unset',
                    },
                }}
            >
                <DrillDownMenu
                    menus={['KPI CARD', 'UTILISATEURS FINAUX']}
                    handleChange={handleChange}
                    value={value}
                />
            </Grid>
            <PromoInformationsTab value={value} row={row} />
            <PromoClientsTab
                value={value}
                isFirstVisit={isFirstVisit}
                row={row}
            />
        </Grid>
    )
}
