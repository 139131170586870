import React, { useState } from 'react'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
    messageToDisplay,
} from './othersDrillDownComponents'
import { useCallback, useEffect } from 'react'
import {
    getFSRows,

    getProductsColumns,
} from './setupDrillDown'
import { DrillDownType } from './ProductsDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import {
    fetchDrillDownProducts,
} from '../../../redux/fsProducts/fsProductsSlice'
import Loading from '../screens/LoadingScreen'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'


export const ClientCercleVertDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
    type,
}: DrillDownContentProps) => {
    const [isLoadingProducts, setLoadingProducts] = useState(true)
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { drillDownProducts } = useAppSelector(
        (state) => state.fsProducts
    )

    const productslength = drillDownProducts ? drillDownProducts.length : ''

    const _fetchDrillDownProducts = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownProducts(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        min: activeFsFilters.min,
        max: activeFsFilters.max,
        clients: [row.id],
        products: activeFsFilters.products,
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }

    useEffect(() => {
        const fetchDataProducts = async () => {
            await _fetchDrillDownProducts(filters)
        }

        fetchDataProducts().then(() => setLoadingProducts(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={[
                'Informations',
                `Références (${productslength})`,
            ]}
            row={row}
            value={value}
            type={type}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownProducts && !isLoadingProducts && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownProducts)}
                        columns={getProductsColumns(type)}
                        message={messageToDisplay({
                            type: DrillDownType.PRODUCTS,
                            value: row.row.products,
                            oldValue: row.row.productsLastYear,
                        })}
                    />
                )}
                {isLoadingProducts && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
        </CommonTabs>
    )
}
