import React from 'react'
import { LIGHT_MOCK } from './Colors'

export const Separator = ({
    color = LIGHT_MOCK,
    mb = '0vh',
    mt = '0vh',
    width = 'calc(100% - 1px)'
}: {
    color?: string
    mb?: string
    mt?: string
    width?: string
}) => {
    return (
        <hr
            style={{
                width: width,
                border: `1px solid ${color}`,
                margin: 0,
                marginBottom: mb,
                marginTop: mt,
            }}
        />
    )
}
