import { Grid, Popover } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import HelpNavigation from './HelpNavigation'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { PrimaryText } from '../../ui/Text'
import { BLACK } from '../../ui/Colors'
import Avatar from '../Avatar'
import { UsersStatus } from '../../../redux/user/userTypes'
import { fetchUser } from '../../../redux/user/userSlice'

interface UserMenuProps {
    open: boolean
    anchorEl: Element | null
    onClose: (e: React.MouseEvent) => void
}

const UserMenu = ({ open, anchorEl, onClose }: UserMenuProps) => {
    return (
        <Popover
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
        >
            <HelpNavigation />
        </Popover>
    )
}

const UserNavigation = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const { firstname, lastname, brand, status } = useAppSelector(
        (state) => state.user.user
    )
    const dispatch = useAppDispatch()
    const _fetchUser = useCallback(async () => await dispatch(fetchUser()), [dispatch])

    useEffect(() => {
        _fetchUser()
    }, [])

    const handleClick = (e: React.MouseEvent) => {
        if (open) {
            setAnchorEl(null)
            setOpen(false)
        } else {
            setAnchorEl(e.currentTarget)
            setOpen(true)
        }
    }

    return (
        <Grid
            container
            alignItems="center"
            onClick={(e) => {
                handleClick(e)
            }}
            sx={{ cursor: 'pointer' }}
        >
            <Grid item flex={2}>
                <Avatar name={firstname} />
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                flex={6}
            >
                <Grid item>
                    <PrimaryText
                        bold
                        color={BLACK}
                    >{`${firstname} ${lastname.toUpperCase()}`}</PrimaryText>
                </Grid>
                {status != UsersStatus.STANDALONE && (
                    <Grid item>
                        <PrimaryText color={BLACK}>{brand}</PrimaryText>
                    </Grid>
                )}
            </Grid>
            <Grid item flex={1}>
                <ArrowRightIcon />
            </Grid>
            <UserMenu
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => {
                    handleClick(e)
                }}
            />
        </Grid>
    )
}

export default UserNavigation
