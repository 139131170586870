import React from 'react'
import { Grid } from '@mui/material'
import Activated from '../../../../../assets/activated.svg'
import { PrimaryText } from '../../../../../components/ui/Text'
import { EnvStatusChip } from '../../../../../components/features/import/EnvStatusChip'
import { ConnectorType, EnvStatus } from '../../../../../redux/user/userTypes'
import { useNavigate } from 'react-router-dom'
import { DialogFooterOneAction } from '../../../../../components/features/Dialog/BasicFooterDialog'

const AutoActivated = ({
    lastImportDate,
    distrib,
    connectorType,
    type,
}: {
    lastImportDate: string
    distrib: string
    connectorType: ConnectorType
    type: string
}) => {
    const updateDate = new Date(lastImportDate).toLocaleDateString('fr-FR')
    const navigate = useNavigate()

    //TO DO : make last date dynamic

    return (
        <Grid>
            <Grid container height="100%" alignItems="center" mt="5vh" mb="5vh">
                <Grid item flex={1} textAlign="center">
                    <img width="60%" src={Activated} />
                </Grid>
                <Grid item flex={1}>
                    <PrimaryText mb="2vh">
                        Votre connecteur est actif, vos données sont
                        actualisées.
                    </PrimaryText>
                    <Grid container alignItems="center" mb="2vh">
                        <PrimaryText bold>Statut des données : </PrimaryText>
                        <Grid marginLeft="1vw">
                            <EnvStatusChip
                                pointer={false}
                                status={EnvStatus.ACTIVATED}
                                name={distrib}
                                last={[]}
                                connectorType={connectorType}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <PrimaryText bold>Dernière mise à jour : </PrimaryText>
                        <PrimaryText ml="1vw">22/05/2024</PrimaryText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DialogFooterOneAction
                    text="Tableau de bord"
                    buttonFunc={() => {
                        navigate(`/${type}/${distrib}/dashboard`)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default AutoActivated
