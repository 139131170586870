import { Grid, List, ListItem, ListItemIcon } from '@mui/material'
import React from 'react'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { EditValues, FormProps } from './ProfilPage'
import { type FormikProps } from 'formik'
import { EnhancedField, TextInput } from '../../../../components/ui/Inputs'
import { PrimaryText } from '../../../../components/ui/Text'
import { BLACK } from '../../../../components/ui/Colors'

export interface InputsProfilProps {
    label: string
    type: string
    name: string
    list?: string | string[]
    disabled: boolean
}
export const InputsProfilPage = ({
    label,
    type,
    name,
    list,
    disabled = false,
    ...props
}: InputsProfilProps & FormikProps<EditValues> & FormProps) => {

    let listToDisplay = []



    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            width="40vw"
        >
            <Grid item flex={2}>
                <PrimaryText color={BLACK}>{label}</PrimaryText>
            </Grid>
            <Grid container flex={5}>
                {type === 'list' &&
                    listToDisplay.map((element) => (
                        <Grid container>
                            <List sx={{ p: 0 }}>
                                <ListItem sx={{ p: 0, m: 0 }}>
                                    <ListItemIcon>
                                        <ArrowRightIcon fontSize="small" />
                                    </ListItemIcon>
                                    <PrimaryText color={BLACK}>
                                        {element}
                                    </PrimaryText>
                                </ListItem>
                            </List>
                        </Grid>
                    ))}
                {type !== 'distributer' &&
                    type !== 'distributerData' &&
                    type !== 'list' && (
                        <EnhancedField
                            type={type}
                            name={name}
                            margin="normal"
                            disabled={disabled}
                            component={TextInput}
                        />
                    )}

            </Grid>

        </Grid>
    )
}
