import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import Loading from '../../../../components/features/screens/LoadingScreen'
import WaitingScreen from '../../../../components/features/screens/WaitingScreen'
import { NoDataScreen } from '../../../../components/features/screens/NoDataScreen'
import { ActiveFsFilters } from '../../../../redux/filters/filtersTypes'
import { fetchWarehouses } from '../../../../redux/fsProducts/fsProductsSlice'
import { UsersStatus } from '../../../../redux/user/userTypes'
import { StandaloneScreen } from '../../../../components/features/screens/StandAloneScreen'

export const WarehousesLayout = ({
    children,
    filters,
}: {
    children: JSX.Element
    filters: ActiveFsFilters
}) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { brand, status } = useAppSelector((state) => state.user.user)
    const { isLoading, env } = useAppSelector((state) => state.global)
    const { warehouses } = useAppSelector((state) => state.fsProducts)
    const _fetchWarehouses = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchWarehouses(filters))
        },
        [dispatch]
    )

    useEffect(() => {
        if (brand && activeFsFilters.startDate) {
            _fetchWarehouses(activeFsFilters)
            setIsFirstLoad(false)
        } else if (activeFsFilters.startDate === '') {
            setIsFirstLoad(false)
        }
    }, [brand, _fetchWarehouses, activeFsFilters])

    if (isLoading || isFirstLoad) {
        return <Loading />
    }

    if (status === UsersStatus.STANDALONE) {
        return <StandaloneScreen />
    }

    if (
        !warehouses ||
        !activeFsFilters.startDate ||
        env.status === 'onload-init'
    ) {
        return <WaitingScreen />
    }

    if (warehouses.list.length === 0) {
        return <NoDataScreen />
    }
    return <Grid>{children}</Grid>
}
