import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import { useAppSelector } from '../../../../redux/hooks'
import { listOfFSDistributors } from '../types'
import { PassionFroidColumnsCustomersPage } from '../utils'
import { CustomersLayout } from '../layout/CustomersLayout'
import Customers from '../Pages/FsCustomersPage'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'

export const CustomersPagePassionFroid = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage title="Utilisateurs finaux" period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.CLIENTS}
            />
            <CustomersLayout>
                <Customers
                    envName={listOfFSDistributors.PASSIONFROID}
                    columns={PassionFroidColumnsCustomersPage()}
                    titles={{
                        doughnutTitle: `Volume vendu par segment utilisateur`,
                        dataGridTitle: `Liste des sous-sous-types d'utilisateurs`,
                    }}
                    drillDownType={DrillDownType.CLIENTSPF}
                />
            </CustomersLayout>
        </PrivatePage>
    )
}
