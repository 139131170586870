import React, { useState } from 'react'
import { supabase } from '../../../supabaseClient'
import ErrorMessage from '../../ui/ErrorMessage'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { useNavigate } from 'react-router-dom'
import {
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    Box,
} from '@mui/material'
import { BLACK } from '../../ui/Colors'
import { resetUser } from '../../../redux/user/userSlice'
import { useDispatch } from 'react-redux'
import { resetDashboard } from '../../../redux/global/globalSlice'
import {
    resetActiveFsFilters,
    resetActiveRetailFilters,
    resetFSFilters,
    resetRetailFilters,
} from '../../../redux/filters/filtersSlice'

const LogoutMobile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [hasError, setError] = useState<null | string>(null)

    const handleClick = async () => {
        const { error } = await supabase.auth.signOut()

        if (error) {
            setError(error)
        }
        dispatch(resetUser())
        dispatch(resetDashboard())
        dispatch(resetFSFilters())
        dispatch(resetRetailFilters())
        dispatch(resetActiveFsFilters())
        dispatch(resetActiveRetailFilters())
        navigate('/')
    }

    return (
        <nav style={{ position: 'absolute', bottom: '32px' }}>
            <Box
                sx={{ '&:hover': { cursor: 'pointer' } }}
                onClick={handleClick}
            >
                <ListItem disablePadding sx={{ '& svg': { color: BLACK } }}>
                    <ListItemButton>
                        <ListItemIcon
                            sx={{
                                minWidth: { xs: '40px', md: '3vw' },
                                '& svg': {
                                    width: { xs: '22px', md: '2.5vw' },
                                    height: { xs: '22px', md: '2.5vh' },
                                },
                            }}
                        >
                            <LogoutOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            sx={{
                                '& span': {
                                    textDecoration: 'none',
                                    fontSize: { xs: '16px', md: '1.8vmin' },
                                },
                            }}
                            primary="Déconnexion"
                        />
                    </ListItemButton>
                </ListItem>
                {hasError && (
                    <ErrorMessage msg="Erreur durant la déconnexion. Veuillez réessayer" />
                )}
            </Box>
        </nav>
    )
}

export default LogoutMobile
