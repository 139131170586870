import { Grid } from '@mui/material'
import React, { useSyncExternalStore } from 'react'
import ColorSquare, { type ColorSquareProps } from '../../../../ui/ColorSquare'
import { BLACK } from '../../../../ui/Colors'
import { SecondaryText } from '../../../../ui/Text'

interface OwnProps extends ColorSquareProps {
    label: string
    layer: number
    hide: boolean
    toggleDataSets?: (e: React.MouseEvent, layer: number) => void
}

const HistogramLabel = ({
    label,
    layer,
    color,
    size,
    hide,
    toggleDataSets,
}: OwnProps) => {
    return (
        <Grid container alignItems="center" style={{ cursor: 'pointer' }}>
            <ColorSquare color={color} size={size} />
            <SecondaryText
                color={BLACK}
                ml="0.2vw"
                textDecoration={hide ? 'line-through' : 'none'}
                onClick={(e) => {
                    toggleDataSets(e, layer)
                }}
            >
                {label}
            </SecondaryText>
        </Grid>
    )
}

export default HistogramLabel
