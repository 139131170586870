import React from 'react'
import { Grid } from '@mui/material'
import { SecondaryText } from '../../../../../components/ui/Text'
import ColorSquare, {
    ColorSquareProps,
} from '../../../../../components/ui/ColorSquare'
import { BLACK } from '../../../../../components/ui/Colors'

export interface OwnProps extends ColorSquareProps {
    label: string
    toggleDataSets?: (e: React.MouseEvent, layer: number) => void
    clickHandler?: () => void
    hide: boolean
    layer?: number
}

const LabelCustomer = ({
    label,
    color,
    size,
    clickHandler,
    hide,
}: OwnProps) => {
    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={clickHandler}
        >
            <Grid>
                <ColorSquare color={color} size={size} />
            </Grid>
            <SecondaryText
                textDecoration={hide ? 'line-through' : 'none'}
                color={BLACK}
                ml="0.5vw"
                whiteSpace="nowrap"
            >
                {label}
            </SecondaryText>
        </Grid>
    )
}

export default LabelCustomer
