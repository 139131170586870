import { Dialog } from '@mui/material'
import React, { useState } from 'react'
import {
    Appointment,
    Default,
    EmailContact,
    MobileNumberSubmitting,
} from './ContactDialogComponents'
import MobileContacts from '../../../../../../assets/contact-3.svg'
import MobileValidation from '../../../../../../assets/contact-validation.svg'
import { useAppSelector } from '../../../../../../redux/hooks'
import { ContactSteps } from '../../../../../../redux/contact/contactTypes'
import { StepConfig } from '../../../../../../redux/views/viewsTypes'

const stepsSync: Record<
    string,
    (step: number, config: StepConfig) => JSX.Element
> = {
    [ContactSteps.Default]: (step: number, config: StepConfig) => (
        <Default step={step} config={config} />
    ),
    [ContactSteps.MobileContact]: (step: number, config: StepConfig) => (
        <MobileNumberSubmitting
            img={MobileContacts}
            step={step}
            config={config}
        />
    ),
    [ContactSteps.Appointment]: (step: number, config: StepConfig) => (
        <EmailContact step={step} config={config} />
    ),
    [ContactSteps.EmailContact]: (step: number, config: StepConfig) => (
        <Appointment step={step} config={config} />
    ),
    [ContactSteps.MobileValidation]: (step: number, config: StepConfig) => (
        <MobileNumberSubmitting
            img={MobileValidation}
            step={step}
            config={config}
        />
    ),
}

const ContactDialog = ({
    isOpen,
    handleClose,
}: {
    isOpen?: boolean
    handleClose: () => void
}) => {
    const { config } = useAppSelector((state) => state.contacts)
    const [activeStep, setActiveStep] = useState<number>(0)

    const handleReset = () => {
        setActiveStep(0)
        handleClose()
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = () => {
        if (activeStep === config.steps.length - 1) {
            handleSubmit()
        } else {
            setActiveStep(activeStep + 1)
        }
    }

    const handleSubmit = () => {
        setActiveStep(0)
    }

    const StepComponent = stepsSync[config.steps[activeStep]]

    return (
        <Dialog
            maxWidth={false}
            onClose={(e: any) => {
                e.stopPropagation()
                handleClose()
            }}
            open={isOpen}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    width: '70vw',
                    padding: '1em',
                    height: '70vh',
                },
            }}
        >
            {StepComponent(activeStep, {
                handleNext,
                handlePrevious,
                handleReset,
                handleSubmit,
                stepsNumber: config.steps.length,
            })}
        </Dialog>
    )
}

export default ContactDialog
