import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import { useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import FsProductsPage from '../Pages/FsProductsPage'
import { TGMColumnsProductsPage } from '../utils'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import { ProductsLayout } from '../layout/ProductsLayout'

export const ProductsPageTGM = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage title="Références" attribute={0} period>
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} />
            <ProductsLayout>
                <FsProductsPage
                    columns={TGMColumnsProductsPage}
                    displayToggle={false}
                    lineChartTitle={`Volume facturé par référence (kg)`}
                    drillDownType={DrillDownType.PRODUCTSTGM}
                />
            </ProductsLayout>
        </PrivatePage>
    )
}
