import React, { useCallback, useEffect, useState } from 'react'
import Loading from '../../../../components/features/screens/LoadingScreen'
import WaitingScreen from '../../../../components/features/screens/WaitingScreen'
import { NoDataScreen } from '../../../../components/features/screens/NoDataScreen'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { ActiveFsFilters } from '../../../../redux/filters/filtersTypes'
import { fetchFilteredDashboard } from '../../../../redux/global/globalSlice'
import { EnvStatus, UsersStatus } from '../../../../redux/user/userTypes'
import { StandaloneScreen } from '../../../../components/features/screens/StandAloneScreen'

export const DashboardLayout = ({ children }: { children: JSX.Element }) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dispatch = useAppDispatch()
    const dashboard = useAppSelector((state) => state.global.fsDashboardData)
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { isLoading, unit, env } = useAppSelector((state) => state.global)
    const { status, brand } = useAppSelector((state) => state.user.user)
    const _fetchFsDashboard = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchFilteredDashboard(filters, false))
        },
        [dispatch]
    )

    if (status === UsersStatus.STANDALONE) {
        return <StandaloneScreen />
    }
    useEffect(() => {
        if (brand && activeFsFilters.startDate) {
            _fetchFsDashboard(activeFsFilters)
            setIsFirstLoad(false)
        } else if (activeFsFilters.startDate === '') {
            setIsFirstLoad(false)
        }
    }, [brand, activeFsFilters, _fetchFsDashboard, unit])

    if (
        isLoading ||
        isFirstLoad ||
        (!dashboard && env.status !== EnvStatus.ON_LOADING_INIT)
    ) {
        return <Loading />
    }

    if (
        !dashboard ||
        !activeFsFilters.startDate ||
        env.status === EnvStatus.ON_LOADING_INIT
    ) {
        return <WaitingScreen />
    }

    if (dashboard.products.length === 0) {
        return <NoDataScreen />
    }
    return <Grid>{children}</Grid>
}
