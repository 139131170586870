import React from 'react'

interface OwnProps {
    number: number | string
    unit?: string
}

const NumberWithSpace = ({ number }: OwnProps) => {
    var parts = number.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    const processedNumber = parts.join('.')

    return <span>{`${processedNumber} `}</span>
}

export default NumberWithSpace
