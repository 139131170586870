import React, { useState } from 'react'
import { Dialog } from '@mui/material'
import { DrillDownContent } from './DrillDownContent'
import { DialogHeader } from '../Dialog/BasicDialog'
import { DialogFooterOneAction } from '../Dialog/BasicFooterDialog'
import { DrillDownType } from './ProductsDrillDownContent'

interface OwnProps {
    title: string
    open: boolean
    value: number
    setValue: (state: number) => void
    row: any
    type: DrillDownType
    handleClose: () => void
}

const DrillDown = ({
    title,
    open,
    value,
    row,
    setValue,
    type,
    handleClose,
}: OwnProps) => {
    const [firstVisit, setFirstVisit] = useState(true)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        setFirstVisit(false)
    }

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        maxWidth: '85vw',
                        minHeight: '75vh',
                        minWidth: '85vw',
                    },
                },
            }}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    padding: '1em',
                    overflowX: 'hidden',
                },
            }}
            onClose={() => {
                handleClose()
                setFirstVisit(true)
            }}
        >
            <DialogHeader
                title={title}
                setOpen={() => {
                    handleClose()
                    setFirstVisit(true)
                }}
            />
            <DrillDownContent
                isFirstVisit={firstVisit}
                type={type}
                handleChange={handleChange}
                row={row}
                value={value}
            />
            <DialogFooterOneAction
                buttonFunc={() => {
                    handleClose()
                    setFirstVisit(true)
                }}
                text="Fermer"
            />
        </Dialog>
    )
}

export default DrillDown
