import React from 'react'
import { useAppSelector } from '../../../../redux/hooks'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { FsPromoPage } from '../Pages/PromotionPage/page/FsPromoPage'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import { KPIsEpiSaveursConfig } from './EpiSaveursDashboard'

export const PromoPageEpiSaveurs = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage period title="Promotions">
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} />
            <FsPromoPage
                promoGraphTitle={
                    'Corrélation promo et vol moyen par utilisateur final'
                }
                sellInGraphTitle={
                    'Corrélation prix net et vol moyen par utilisateur final'
                }
                KPIsConfig={KPIsEpiSaveursConfig}
                promoGraphCurveDataName={
                    'Volume sell-out par utilisateur final (kg)'
                }
                sellInGraphCurveDataName={'Volume sell-out par transaction'}
                drillDownType={DrillDownType.PROMO}
            />
        </PrivatePage>
    )
}
