import { Box, Checkbox, DialogContent, FormControlLabel, Grid, Typography } from '@mui/material'
import { type FormikBag, type FormikProps, withFormik, Form } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { array, object, string } from 'yup'
import { BLACK, DARK_GREY, PRIMARY } from '../../../ui/Colors'
import { DialogFooter, DialogFooterOneAction } from '../../Dialog/BasicFooterDialog'
import { checkboxCSS } from '../../filters/selects/FilterComponent'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchCompany } from '../../../../redux/companies/companiesSlice'
import { setUsersToShare } from '../../../../redux/views/viewsSlice'
import { PrimaryText, SecondaryText } from '../../../ui/Text'
import { PrimaryButton } from '../../../ui/Button'
import { ErrorMessageForm } from '../../../ui/ErrorMessageForm'
import { Member } from '../../../../redux/companies/companiesTypes'
import { useNavigate } from 'react-router-dom'
import { ConfigProps, ShareViewData } from '../ShareDialog'

interface FormValues {
    usersToShare: string[]
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    handleClose: (e: React.MouseEvent) => void
    initialValues: FormValues
}

const NoUsersFallback = () => {
    const navigate = useNavigate()

    return (
        <Grid container height="80%" direction="column" justifyContent="center" alignItems="center">
            <Grid item mb="2vh">
                <PrimaryText color={BLACK} textAlign="center">
                    Vous n'avez pas de collègues ayant un compte sur Karyonfood.
                </PrimaryText>
            </Grid>
            <Grid item mb="4vh">
                <PrimaryText textAlign="center">
                    Vous pouvez inviter vos collaborateurs pour qu'ils rejoignent la plateforme ! <br /> En cliquant sur le bouton ci-dessous, vous allez être redirigé vers une page vous permettant de les inviter.
                </PrimaryText>
            </Grid>
            <Grid item>
                <PrimaryButton onClick={() => navigate("/parametres/profil")}>Inviter mes collaborateurs</PrimaryButton>
            </Grid>
        </Grid >)
}

const UsersList = ({ list, usersToShare, handler, error }: { list: Member[], usersToShare: string[], handler: (email: string) => void, error: string | null }) => {
    return (<Grid container direction="column" height="80%">
        {list.map((el) =>
            <FormControlLabel
                sx={{
                    alignItems: 'center',
                    fontWeight: 400,
                    fontFamily: [
                        'Poppins',
                        'sans-serif',
                    ].join(','),
                    '& span': {
                        fontSize: {
                            xs: '16px',
                            md: '1.7vmin',
                        },
                    },
                }}
                control={
                    <Checkbox
                        onChange={() => {
                            handler(el.email)
                        }
                        }
                        defaultChecked={usersToShare.includes(el.email)}
                        sx={checkboxCSS}
                    />
                }
                label={<PrimaryText color={BLACK} >{`${el.firstname} ${el.lastname}`}</PrimaryText>}
            />)}
        {error && <Box mt="2vh">
            <ErrorMessageForm message={error} />
        </Box>}
    </Grid>)
}

const PureUsersToShareForm = (props: FormikProps<FormValues> & FormProps) => {
    const { company } = useAppSelector((state) => state.companies)
    const { user } = useAppSelector((state) => state.user)

    const handleChange = (email: string) => {
        if (props.values.usersToShare.includes(email)) {
            props.setFieldValue(
                'usersToShare',
                props.values.usersToShare.filter((userEmail) => userEmail !== email)
            )
        }
        else {
            props.setFieldValue('usersToShare', [...props.values.usersToShare, email])
        }
    }

    const users = company && company.members && company.members.filter((el) => el.email != user.email).sort((a, b) => a.firstname.toLowerCase().localeCompare(b.firstname.toLowerCase()))

    return (
        <Form style={{ width: '100%', height: '100%' }}>
            <Grid height="80%">
                <DialogContent style={{ height: "calc(100% - 4vh)" }}>
                    <Grid container direction="column" height="20%">
                        <Typography>
                            A qui souhaitez-vous partager la vue ?
                        </Typography>
                        <SecondaryText color={DARK_GREY} fontStyle="italic">
                            Les destinataires auront accès à votre vue dans leur espace et seront notifiés par email.
                        </SecondaryText>
                    </Grid>
                    {users.length === 0 ? <NoUsersFallback /> : <UsersList list={users} usersToShare={props.values.usersToShare} handler={handleChange} error={props.errors.usersToShare as string} />}
                </DialogContent>
            </Grid>
            {users.length !== 0 ?
                <DialogFooter
                    rightButtonColor={PRIMARY}
                    leftButtonFunc={(e) => {
                        e.stopPropagation()
                        props.handleClose(e)
                    }}
                    leftButtonText={'Annuler'}
                    rightButtonFunc={(e) => {
                        e.stopPropagation()
                        props.submitForm()
                    }}
                    rightButtonText={'Suivant'}
                /> : <DialogFooterOneAction
                    buttonFunc={(e) => {
                        e.stopPropagation()
                        props.handleClose(e)
                    }}
                    text="Fermer"
                />}

        </Form >
    )
}

const ControlledUsersToShareForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props: FormProps) => ({
        usersToShare: props.initialValues.usersToShare
    }),
    validationSchema: () => {
        return object().shape({
            usersToShare: array()
                .of(string().required())
                .required()
                .min(1, 'Choisissez au moins un collègue'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureUsersToShareForm)

const UsersToShareStep = ({
    handleClose,
    handleNext,
    getData,
    updateData
}: ConfigProps
) => {
    const initialValues = getData() as unknown as FormValues
    const dispatch = useAppDispatch()
    const _fetchCompany = useCallback(
        async () => await dispatch(fetchCompany()),
        [dispatch]
    )

    useEffect(() => {
        _fetchCompany()
    }, [])

    const handleSubmit = async (values: FormValues) => {
        updateData(values as unknown as ShareViewData)
        dispatch(setUsersToShare(values.usersToShare))
        handleNext()
    }

    return (
        <ControlledUsersToShareForm
            initialValues={initialValues}
            handleClose={handleClose}
            onSubmit={handleSubmit}
        />
    )
}

export default UsersToShareStep
