import React, { type Dispatch, type SetStateAction, useState } from 'react'
import Label from './LineChartLabel'
import { Grid } from '@mui/material'
import { backgroundColor } from './LineChartProduct'
import { SquareColor } from '../../../../../components/ui/ColorSquare'
import { DataSet } from './TreeComponent'

interface OwnProps {
    chart: any
    products: string[]
    start: number
    setSelected: Dispatch<SetStateAction<number>>
    data: DataSet[]
}
const LineChartLabels = ({ chart, products, start, setSelected, data }: OwnProps) => {
    const [datasets, setDatasets] = useState<number[]>([])

    const showDataset = (menu: number, id: number) => {
        setDatasets(datasets.filter((elem, pos) => pos !== id))
        setSelected((selected) => selected - 1)
        chart.show(menu)
    }

    const hideDataset = (menu: number) => {
        setDatasets([...datasets, menu])
        setSelected((selected) => selected + 1)
        chart.hide(menu)
    }

    const HandleToogleDatasets = (e: React.MouseEvent, menu: number) => {
        e.preventDefault()
        const el = datasets.indexOf(menu)
        if (el >= 0) {
            showDataset(menu, el)
        } else {
            hideDataset(menu)
        }
    }

    return (
        <Grid container>
            {products.map((product, labelIndex) => {

                const color = data.find((el) => el.name === product).borderColor

                return (
                    <Grid key={product} container direction="column">
                        <Grid container ml={1.5} mt={1}>
                            <Label
                                toggleDataSets={HandleToogleDatasets}
                                layer={labelIndex + start}
                                label={product}
                                color={color}
                                size={SquareColor.SM}
                                hide={
                                    datasets.findIndex(
                                        (el) => el === labelIndex + start
                                    ) >= 0
                                }
                            />
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default LineChartLabels
