import React, { useCallback } from 'react'
import { DialogContent, Grid, Link } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import { object, string } from 'yup'
import { ExportTypes } from '../../../redux/export/exportsTypes'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import LockIcon from '@mui/icons-material/Lock'
import excelIcon from '../../../assets/icon/excelIcon.svg'
import { PrimaryText } from '../../ui/Text'
import { BLACK } from '../../ui/Colors'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { StepperData } from '../../../redux/stepper/stepperTypes'
import { ExportComponentProps, StepExportConfig } from './ExportSetup'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setGlobalEnv } from '../../../redux/global/globalSlice'
import { Env, EnvType, EnvStatus, EnvName } from '../../../redux/user/userTypes'
import { TypeOfItem } from '../utils/OptionsChoicesBox'
import { OptionsChoiceComponent } from '../utils/OptionsChoices'
import ErrorMessage from '../../ui/ErrorMessage'

interface FormValues {
    type: string
}

interface FormProps {
    initialValues: { type: string }
    config: StepExportConfig
    step: number
    envType: string
    handleClickLink: () => void
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

const typesOfExport: TypeOfItem[] = [
    {
        id: 1,
        label: 'Données brutes (csv)',
        icon: (
            <img src={excelIcon} style={{ width: '2.5vw', height: '2.5vh' }} />
        ),
        type: ExportTypes.EXCEL,
        locked: false,
        tooltip:
            'Format brut : Exportez vos données brutes et non-filtrées, au format .csv du distributeur.',
    },

    {
        id: 2,
        label: `Données personnalisées (Excel)`,
        icon: (
            <LockIcon
                color="disabled"
                style={{ width: '2.5vw', height: '2.5vh' }}
            />
        ),
        type: ExportTypes.PRESENTATION,
        locked: true,
        tooltip:
            '[Fonctionnalité à venir] Format KaryonFood : Exportez vos données filtrées et unifiées selon votre segmentation.',
    },
    {
        id: 3,
        label: `Présentation (Power Point)`,
        icon: (
            <LockIcon
                color="disabled"
                style={{ width: '2.5vw', height: '2.5vh' }}
            />
        ),
        type: ExportTypes.PRESENTATION,
        locked: true,
        tooltip:
            '[Fonctionnalité à venir] Pour des présentations professionnelles qui en impressionneront plus d’un …',
    },
]

const PureSettingsForm = ({
    config,
    step,
    envType,
    handleClickLink,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    return (
        <Form>
            <DialogContent sx={{ height: '32vh' }}>
                <Grid container direction="column">
                    <PrimaryText
                        color={BLACK}
                        style={{ marginBottom: '3vh', marginTop: '1vh' }}
                    >
                        Les options d'export de vos données sont :
                    </PrimaryText>
                    <OptionsChoiceComponent
                        listOfChoice={typesOfExport}
                        setValue={props.setFieldValue}
                        name="type"
                    />

                    <Grid
                        container
                        wrap="nowrap"
                        sx={{ marginBottom: '2vh', marginTop: '2vh' }}
                    >
                        <InfoOutlinedIcon
                            style={{
                                marginRight: '0.2vw',
                                width: '2.5vw',
                                height: '2.5vh',
                            }}
                            color="disabled"
                        />
                        <PrimaryText>
                            Pour exporter vos données filtrées et unifiées selon
                            votre segmentation, choisissez votre abonnement
                            KaryonFood dès à présent !
                            <Link
                                href="https://meetings.hubspot.com/marc-kwan"
                                sx={{ cursor: 'pointer', ml: '0.5vw' }}
                            >
                                Contacter KaryonFood.
                            </Link>
                        </PrimaryText>
                    </Grid>
                    {/* TODO : Handle Multi env export
                    {envType !== 'setting' && (
                        <Grid container wrap="nowrap">
                            <InfoOutlinedIcon
                                style={{ marginRight: '0.5vw' }}
                                color="disabled"
                            />
                            <PrimaryText>
                                Vous souhaitez exporter les données de plusieurs
                                distributeurs ? Exportez depuis la page :{' '}
                                <Link
                                    onClick={() => {
                                        handleClickLink()
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    Gérer mes distributeurs
                                </Link>
                            </PrimaryText>
                        </Grid>
                    )} */}
                    {props.submitCount > 0 && props.errors.type && (
                        <Grid
                            item
                            mt="1vh"
                            display={'flex'}
                            justifyContent="center"
                        >
                            <ErrorMessage msg={props.errors.type as string} />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={(e) => {
                    envType === EnvType.SETTING
                        ? config.handlePrevious()
                        : config.handleClose()
                }}
                leftButtonText={
                    envType === EnvType.SETTING ? 'PRECEDENT' : 'ANNULER'
                }
                rightButtonFunc={(e) => {
                    e.stopPropagation()
                    props.submitForm()
                }}
                rightButtonText={'SUIVANT'}
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        type: props.initialValues.type,
    }),
    validationSchema: () => {
        return object().shape({
            type: string()
                .oneOf(Object.values(ExportTypes))
                .required('Choisissez le format de votre export'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const TypeOfExportStep = ({ step, config, env }: ExportComponentProps) => {
    const { data } = useAppSelector((state) => state.exports)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )
    const handleSubmit = (values: FormValues) => {
        config.updateData(values as unknown as StepperData)
        config.handleNext()
    }

    const handleClickLink = () => {
        navigate(`/`, { state: { exportModaleOpen: true } })
        const navigation = {
            name: EnvName.MYDISTRIB,
            type: EnvType.SETTING,
            status: EnvStatus.ACTIVATED,
        }
        _setEnv(navigation)
    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            handleClickLink={handleClickLink}
            envType={env.type}
            initialValues={{
                type: data.type,
            }}
        />
    )
}

export default TypeOfExportStep
