import React from 'react'
import { ImageList, ImageListItem } from '@mui/material'
import OtherDistributorIcon from './OtherDistributorIcon'
import { distributorImages } from '../../helpers/distributorsList'

interface OwnProps {
    list: string[]
    values: string[]
    selectorHandler: (string) => void
}

const DistributerSelector = ({ list, values, selectorHandler }: OwnProps) => {
    return (
        <ImageList cols={5} rowHeight={100} sx={{ width: '30vw' }}>
            {list.map((item) => {
                const isSelected =
                    values.findIndex((pair) => pair === item) >= 0
                const img = distributorImages[item]

                return (
                    <ImageListItem
                        key={img}
                        sx={{
                            marginRight: '1vw',
                            marginTop: '2vh',
                            flex: 1,
                            height: '7vh',
                            width: '7vh',
                        }}
                    >
                        {img ? (
                            <img
                                src={`${img}`}
                                srcSet={`${img}`}
                                alt={item}
                                onClick={() => {
                                    selectorHandler(item)
                                }}
                                loading="lazy"
                                style={{
                                    padding: 4,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                    border: isSelected && '1px solid #3A4D87',
                                    borderRadius: isSelected && '5px',
                                }}
                            />
                        ) : (
                            <OtherDistributorIcon
                                name={item}
                                onClick={selectorHandler}
                                isSelected={isSelected}
                            />
                        )}
                    </ImageListItem>
                )
            })}
        </ImageList>
    )
}

export default DistributerSelector
