import React, { useState } from 'react'
import { DrillDownType } from '../../../../../../components/features/drillDowns/ProductsDrillDownContent'
import { DataTableComponent } from '../../../../DataTableComponent'
import {
    GridCellParams,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
} from '@mui/x-data-grid'
import {
    PrimaryText,
    SecondaryText,
} from '../../../../../../components/ui/Text'
import { BLACK, LAVENDER } from '../../../../../../components/ui/Colors'
import NumberWithSpace from '../../../../../../components/ui/NumberWithSpace'
import { Grid } from '@mui/material'
import DrillDown from '../../../../../../components/features/drillDowns/DrillDown'
import { roundIfNotZero } from '../../../../../../helpers/roundIfNotZero'

export interface Product {
    id: string
    ean: string
    name: string
    netSellIn: number
    percentageNetSellIn: number
    percentagePromo: number
    percentageVolSellIn: number
    percentageVolSellOut: number
    promo: number
    volSellIn: number
    volSellOut: number
}

export const columnsProductsListPromoPage = (): GridColDef[] => {
    return [
        {
            field: 'name',
            headerName: 'Références',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'ean',
            headerName: 'EAN',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'volSellIn',
            headerName: 'Sell-in (kg)',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'percentageVolSellIn',
            headerName: '% Sell-in',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'netSellIn',
            headerName: `Sell-in (€ net)`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'percentageNetSellIn',
            headerName: `% Sell-in`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'volSellOut',
            headerName: `Sell-out (kg)`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'percentageVolSellOut',
            headerName: `% Sell-out (kg)`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'promo',
            headerName: `Promotions (€)`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'percentagePromo',
            headerName: `% Promotions (€)`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const ProductsList = ({
    productsList,
    drillDownType,
}: {
    productsList: Product[]
    drillDownType: DrillDownType
}) => {
    const [isOpen, setOpen] = useState(false)
    const [fieldSelected, setFieldSelected] = useState<GridCellParams<any>>()
    const [value, setValue] = useState(0)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Grid
            item
            mt="1vw"
            sx={{
                height: '40vh',
                '& .PromoPageTotauxGeneraux': {
                    backgroundColor: LAVENDER,
                    cursor: 'auto',
                },
                '& .PromoPagetotauxTopProducts': {
                    backgroundColor: '#CFFF97',
                    cursor: 'auto',
                },
            }}
        >
            <DataTableComponent
                type={drillDownType}
                setOpen={setOpen}
                setFieldSelected={setFieldSelected}
                setValue={setValue}
                rows={productsList}
                columns={columnsProductsListPromoPage()}
                getRowId={(row) => row.id}
                hideFooter={true}
            />
            <DrillDown
                type={drillDownType}
                open={isOpen}
                title={fieldSelected && `${fieldSelected.row.name}`}
                value={value}
                setValue={setValue}
                row={fieldSelected}
                handleClose={handleClose}
            />
        </Grid>
    )
}
