export const formateDateToString = (startDate: Date, endDate: Date) => {
    const start = `${startDate.getFullYear()}-${
        startDate.getMonth() >= 9
            ? startDate.getMonth() + 1
            : `0${startDate.getMonth() + 1}`
    }`
    const end = `${endDate.getFullYear()}-${
        endDate.getMonth() >= 9
            ? endDate.getMonth() + 1
            : `0${endDate.getMonth() + 1}`
    }`
    return { start, end }
}
