import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContactConfig, ContactState, ContactSteps } from './contactTypes'
import { AppThunk } from '../store'
import client from '../../api/config'

const initialState: ContactState = {
    open: false,
    config: {
        steps: [
            ContactSteps.Default,
            ContactSteps.MobileContact,
            ContactSteps.Appointment,
            ContactSteps.EmailContact,
            ContactSteps.MobileValidation,
        ],
    },
}

export enum CLICK {
    PROMO = 'User ask for contact on Promo page',
    UPSELL_MENU_BE_CALLED = 'User ask to be called on distributer menu modale',
    UPSELL_MENU_BOOK_MEETING = 'User booked a meeting on distributer menu modale',
    UPSELL_MENU_SEND_AN_EMAIL = 'User click to send an email on distributer menu modale',
}

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        closeContact: (state) => {
            state.open = false
        },
        setupContact: (
            state,
            action: PayloadAction<{ config: ContactConfig }>
        ) => {
            state.open = true
            state.config = action.payload.config
        },
    },
})

export const { closeContact, setupContact } = contactsSlice.actions

export const createAskForContactActivity =
    (click: string): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            try {
                await client
                    .post('/activities/ask-contact-log', {
                        click: click,
                    })
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                    })
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }
export default contactsSlice.reducer
