import React from 'react'
import ViewsPage from '../../../../pages/private/views/ViewsPage'
import { routerType } from './PublicNavigation'
import { DashboardRetailPage } from '../../../../pages/private/Retail/DashboardRetailPage'
import { ProductsPage } from '../../../../pages/private/Retail/ProductsPage'
import { StoresPage } from '../../../../pages/private/Retail/StoresPage'
import { PagesName } from '../types/pages'
import RetailCrossDashboard from '../../../../pages/private/Foodservice/Cross/RetailCrossDashboard'

export const retailPages: routerType[] = [
    {
        path: 'cross/dashboard',
        element: <RetailCrossDashboard />,
        title: PagesName.CROSS,
    },
    {
        path: ':distributor/dashboard',
        element: <DashboardRetailPage />,
        title: PagesName.DASHBOARD,
    },
    {
        path: ':distributor/produits',
        element: <ProductsPage />,
        title: PagesName.PRODUCTS,
    },
    {
        path: ':distributor/magasins',
        element: <StoresPage />,
        title: PagesName.STORES,
    },
    {
        path: ':distributor/vues/:viewId',
        element: <ViewsPage />,
        title: PagesName.VIEWS,
    },
]
