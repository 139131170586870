import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Dashboard from '../../../../assets/cross/Cross-distributeur - Dashboard V.1.2.png'
import { Grid } from '@mui/material'

const RetailCrossDashboard = () => {
    return (
        <PrivatePage title="" period>
            <Grid container justifyContent="center">
                <img width="100%" src={Dashboard} />
            </Grid>
        </PrivatePage>
    )
}

export default RetailCrossDashboard
