import React from 'react'
import { useAppSelector } from './redux/hooks'
import { ENVIRONMENTS } from './api/config'
import { HelmetAlphaWithCookies, HelmetProdWithCookies } from './HelmetProdWithCookies'
import { HelmetWithoutCookies } from './HelmetProdNoCookies'

interface OwnProps {
    children: JSX.Element,
    title: string
}

const CookiesUsedOrNot = ({
    children,
    title
}: OwnProps) => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted')

    return <>{children}</>
    // Handle cookies validation
    /* if (cookiesAccepted === 'true') {
        return <>{children}</>
    } else {
        return <HelmetWithoutCookies title={title} />
    } */
}

export const HelmetDispatcher = () => {
    const { firstname, lastname, brand, job, email } = useAppSelector(
        (state) => state.user.user
    )
    const cookiesAccepted = localStorage.getItem('cookiesAccepted')

    switch (process.env.REACT_APP_ENV) {
        case ENVIRONMENTS.PRODUCTION:
            return (
                <CookiesUsedOrNot title="Dashboard">
                    <HelmetProdWithCookies
                        title="Dashboard"
                        firstname={firstname}
                        lastname={lastname}
                        brand={brand}
                        job={job}
                        email={email}
                    />
                </CookiesUsedOrNot>
            )
        case ENVIRONMENTS.ALPHA:
            return (
                <CookiesUsedOrNot title="Alpha">
                    <HelmetAlphaWithCookies
                        title="Alpha"
                        firstname={firstname}
                        lastname={lastname}
                        brand={brand}
                        job={job}
                        email={email}
                    />
                </CookiesUsedOrNot>
            )
        case ENVIRONMENTS.DEVELOPMENT:
            return (
                <HelmetWithoutCookies title="Environnement de dev" />
            )
        default:
            return (
                <HelmetWithoutCookies title="Environnement de dev" />
            )
    }
}
