import { FsCrossViewFilters, FsViewFilters, RetailViewFilters } from '../filters/filtersTypes'
import { EnvType } from '../user/userTypes'

export enum ViewsSteps {
    FsSettings = 'food-service-settings',
    FsCrossSettings = 'food-service-cross-settings',
    RetailSettings = 'retail-settings',
    FsFilters = 'food-service-filters',
    FsCrossFilters = 'food-service-cross-filters',
    RetailFilters = 'retail-filters',
}

export enum ViewTypes {
    Dashboard = 'dashboard',
    Map = 'map',
    ListProduct = 'list-product',
    ListShop = 'list-shop',
}

export enum ViewCreationMode {
    GLOBAL = 'global',
    NOTIFICATION = 'notification',
}

export interface ViewData {
    name: string
    type: ViewTypes
    environment: EnvType
    distributor: string
    filters: FsViewFilters | RetailViewFilters | FsCrossViewFilters
    creationMode: ViewCreationMode
    notificationId?: string
}

export interface ViewDataUpdate {
    name: string
    filters: FsViewFilters | RetailViewFilters
}

export interface ViewConfig {
    steps: ViewsSteps[]
}

export interface View {
    _id: string
    name: string
    environment: EnvType
    distributor: string
    type: ViewTypes
    createConfig: ViewsCreation
    filters: FsViewFilters | RetailViewFilters | FsCrossViewFilters
    creationMode: ViewCreationMode
    notificationId?: string
    description?: string
}

interface ViewsCreation {
    creator: string;
    created_at: Date;
    shared?: boolean;
    usersToShare?: string[];
    creatorFirstname?: string;
    creatorLastname?: string;
    creatorEmail?: string;
}

export interface ResponseViewsCreation {
    insertedId: string
    distributor: string
    environment: EnvType
}

export interface viewsState {
    views: View[]
    openAdd: boolean
    openUpdate: boolean
    config: ViewConfig
    data: ViewData
    dataUpdate: ViewDataUpdate
    usersToShare: string[]
    description: string
}

export interface StepConfig {
    handleNext: () => void
    handlePrevious: () => void
    handleReset: () => void
    handleSubmit?: () => void
    handleClose?: () => void
    stepsNumber: number
}
