import { Dialog } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { StepConfig } from '../../../../redux/views/viewsTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { DialogHeader } from '../../../../components/features/Dialog/BasicDialog'
import InvitationUserStep from './InvitationUserStep'
import { InvitationsSteps } from '../../../../redux/invitations/invitationsTypes'
import InvitationEnvStep from './InvitationEnvStep'
import InvitationEndStep from './InvitationEndStep'
import { initialState, updateInvitation } from '../../../../redux/invitations/invitationsSlice'

const stepsSync: Record<
    string,
    (step: number, config: StepConfig) => JSX.Element
> = {
    [InvitationsSteps.CONTACTSTEP]: (step: number, config: StepConfig) => (
        <InvitationUserStep step={step} config={config} />
    ),
    [InvitationsSteps.ENVSTEP]: (step: number, config: StepConfig) => (
        <InvitationEnvStep step={step} config={config} />
    ),
    [InvitationsSteps.ENDSTEP]: (step: number, config: StepConfig) => (
        <InvitationEndStep step={step} config={config} />
    ),
}

const InvitationDialog = ({ isDialogOpen, setIsDialogOpen }: { isDialogOpen: boolean, setIsDialogOpen: any }) => {
    const [activeStep, setActiveStep] = useState<number>(0)
    const dispatch = useAppDispatch()
    const { config } = useAppSelector((state) => state.invitations)

    const _updateInvitation = useCallback(
        () => dispatch(updateInvitation(initialState)),
        [dispatch]
    )

    const handleClose = () => {
        setIsDialogOpen(false)
        handleReset()
    }

    const handleReset = () => {
        _updateInvitation()
        setActiveStep(0)
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const StepComponent = stepsSync[config.steps[activeStep]]

    return (
        <Dialog
            open={isDialogOpen}
            fullWidth={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader
                title="Invitez vos collaborateurs"
                setOpen={handleClose}
            />
            {StepComponent(
                activeStep,
                {
                    handleNext,
                    handlePrevious,
                    handleReset,
                    handleClose,
                    stepsNumber: config.steps.length,
                },

            )}
        </Dialog>
    )
}

export default InvitationDialog
