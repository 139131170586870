import { monthsAsObject } from '../../../../../helpers/months'

export const formateLegend = (legend: string[]): string[] => {
    const formatedLegend = legend.map((date) => {
        if (!date) return null

        const index = date.slice(5, 7)
        return monthsAsObject[index]
    })
    return formatedLegend
}
