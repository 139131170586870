import React, { useCallback } from 'react'
import {
    Field,
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { DialogContent, Grid } from '@mui/material'
import { object, string } from 'yup'
import { type StepConfig, ViewTypes } from '../../../../redux/views/viewsTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { TextInput } from '../../../ui/Inputs'
import { updateDataUpdate } from '../../../../redux/views/viewsSlice'

// Icons
import { DialogFooter } from '../../Dialog/BasicFooterDialog'
import { PrimaryText } from '../../../ui/Text'
import { typesOfView } from './FsSettingsStep'
import { OptionsChoiceComponent } from '../../utils/OptionsChoices'
import { BLACK } from '../../../ui/Colors'

interface OwnProps {
    viewId: string
    step: number
    config: StepConfig
}

interface FormValues {
    name: string
    type: ViewTypes
}

interface FormProps {
    step: number
    config: StepConfig
    initialValues: FormValues
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

const PureSettingsForm = ({
    config,
    step,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    return (
        <Form>
            <DialogContent sx={{ height: '40vh' }}>
                <Grid container direction="column">
                    <PrimaryText
                        bold
                        color={BLACK}
                        style={{ marginBottom: '3vh', marginTop: '1vh' }}
                    >
                        Paramétrez votre vue :
                    </PrimaryText>
                    <Grid container direction="column">
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            m="3vh 0"
                        >
                            <PrimaryText> Nom</PrimaryText>
                            <Field
                                sx={{
                                    width: '20vw',
                                    '& div': {
                                        fontSize: '1.6vmin',
                                    },
                                }}
                                autoFocus
                                name="name"
                                type="text"
                                component={TextInput}
                                size="small"
                            />
                        </Grid>
                        <Grid item>
                            <PrimaryText paragraph> Type de vue</PrimaryText>
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ marginBottom: '3vh', marginTop: '1vh' }}
                            >
                                <OptionsChoiceComponent
                                    listOfChoice={typesOfView}
                                    setValue={props.setFieldValue}
                                    name="type"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <PrimaryButton>
            Recevoir une notification dès que les fonctionnalités sont
            disponibles
          </PrimaryButton> */}
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={
                    step === 0 ? config.handleReset : config.handlePrevious
                }
                leftButtonText={step === 0 ? 'ANNULER' : 'PRÉCÉDENT'}
                rightButtonFunc={async () => {
                    await props.submitForm()
                }}
                rightButtonText={
                    step === config.stepsNumber - 1
                        ? 'ACTUALISER MA VUE'
                        : 'SUIVANT'
                }
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        name: props.initialValues.name,
        type: props.initialValues.type,
    }),
    validationSchema: () => {
        return object().shape({
            name: string().required('Nom est un champ obligatoire'),
            type: string()
                .oneOf(Object.values(ViewTypes))
                .required('Le choix du type de vue est obligatoire'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const SettingsStepUpdate = ({ step, config, viewId }: OwnProps) => {
    const dispatch = useAppDispatch()
    const views = useAppSelector((state) => state.views.views)
    const view = views.find((view) => view._id === viewId)
    const _updateDataUpdate = useCallback(
        ({ name }: { name: string }) =>
            dispatch(
                updateDataUpdate({
                    data: { name },
                })
            ),
        [dispatch]
    )

    const handleSubmit = (values: FormValues) => {
        _updateDataUpdate({
            name: values.name,
        })
        config.handleNext()
    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            initialValues={{
                name: view.name,
                type: view.type,
            }}
        />
    )
}

export default SettingsStepUpdate
