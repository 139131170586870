import { Grid } from '@mui/material'
import React from 'react'
import ColorSquare, {
    type ColorSquareProps,
} from '../../../../../../components/ui/ColorSquare'
import { BLACK } from '../../../../../../components/ui/Colors'
import { SecondaryText } from '../../../../../../components/ui/Text'

interface OwnProps extends ColorSquareProps {
    label: string
    clickHandler: () => void
    hide: boolean
}

const PieLabel = ({ label, color, size, clickHandler, hide }: OwnProps) => {
    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={clickHandler}
        >
            <ColorSquare color={color} size={size} />
            <SecondaryText
                color={BLACK}
                ml="1vw"
                textDecoration={hide ? 'line-through' : 'none'}
            >
                {label}
            </SecondaryText>
        </Grid>
    )
}

export default PieLabel
