import React, { useCallback, useEffect, useState } from 'react'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
    messageToDisplay,
} from './othersDrillDownComponents'
import { DrillDownType } from './ProductsDrillDownContent'
import {
    getRetailRows,
    getStoresColumns,
    getStoresNotDistributorsColumns,
} from './setupDrillDown'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveRetailFilters } from '../../../redux/filters/filtersTypes'
import { fetchDrillDownStores } from '../../../redux/retailProducts/retailProductsSlice'
import Loading from '../screens/LoadingScreen'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'

export const RetailProductsDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
}: DrillDownContentProps) => {
    const [isLoading, setLoading] = useState(true)
    const dispatch = useAppDispatch()
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const _fetchDrillDownStores = useCallback(
        async (filters: ActiveRetailFilters) => {
            await dispatch(fetchDrillDownStores(filters))
        },
        [dispatch]
    )

    const { drillDownStores } = useAppSelector((state) => state.retailProducts)

    const productsLength = drillDownStores
        ? drillDownStores.storesDistributorsList.length
        : ''

    const storesNotDistributorsLength = drillDownStores
        ? drillDownStores.storesNotDistributorsList.length
        : ''

    const filters: ActiveRetailFilters = {
        startDate: activeRetailFilters.startDate,
        endDate: activeRetailFilters.endDate,
        products: [row.id],
        stores: activeRetailFilters.stores,
        distributionChannels: activeRetailFilters.distributionChannels,
        min: activeRetailFilters.min,
        max: activeRetailFilters.max
    }

    let storesNotDistributorsRows = []

    storesNotDistributorsRows =
        drillDownStores &&
        drillDownStores.storesNotDistributorsList.map((el) => {
            return {
                id: el,
                storeName: el,
            }
        })

    useEffect(() => {
        const fetchData = async () => {
            await _fetchDrillDownStores(filters)
        }

        fetchData().then(() => setLoading(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={[
                'Informations',
                `Points de vente (${productsLength})`,
                `Points de vente non distributeurs (${storesNotDistributorsLength})`,
            ]}
            row={row}
            value={value}
            type={DrillDownType.RETAILPRODUCTS}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownStores && !isLoading && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getRetailRows(
                            drillDownStores.storesDistributorsList
                        )}
                        columns={getStoresColumns(DrillDownType.RETAILPRODUCTS)}
                        message={messageToDisplay({
                            type: DrillDownType.STORES,
                            value: row.row.stores,
                            oldValue: row.row.oldStores,
                        })}
                    />
                )}
                {isLoading && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {drillDownStores && !isLoading && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={storesNotDistributorsRows}
                        columns={getStoresNotDistributorsColumns()}
                        message={`Psssst, ci-dessous la précieuse liste des points de vente qui ne distribuent pas (encore) votre référence ${row.row.productName}`}
                    />
                )}
                {isLoading && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
        </CommonTabs>
    )
}
