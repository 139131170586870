import { DepthOfData } from '../../components/features/filters/FilterSelect'
import {
    ActiveFsCrossFilters,
    ActiveFsFilters,
    ActiveRetailFilters,
    FsCrossFilters,
    FsFilters,
    Response2levels,
    Response3levels,
    Responsebase,
    RetailFilters,
} from '../filters/filtersTypes'
import { EnvType } from '../user/userTypes'

export const getURLForFilteredDashboard = (
    envType: EnvType,
    distributor: string,
    unit: string
) => {
    if (envType === EnvType.FOOD_SERVICE) {
        return `/user/dashboard/fs?distrib=${distributor}&unit=${unit}`
    } else if (envType === EnvType.RETAIl) {
        return `/user/dashboard/retail?distrib=${distributor}`
    } else if (envType === EnvType.FOOD_SERVICE_CROSS) {
        return `/user/cross/dashboard/fs`
    }
}

export const calculateTotalLength = (element: number[]): number => {
    return element.reduce((a, b) => a + b, 0)
}

export const isFiltered2levels = (
    elements: Response2levels[],
    elementsActiveFilters: string[]
) => {
    const elementsTotal = elements.map((region) => region.children.length)
    const elementsLength = calculateTotalLength(elementsTotal)
    const elementsComparision =
        elementsActiveFilters.length !== elementsLength &&
        elementsActiveFilters.length !== 0

    return elementsComparision
}

const isFiltered3levels = (
    elements: Response3levels[],
    elementsActiveFilters: string[]
) => {
    const elementsTotal = elements
        .map((type) => type.children.map((subType) => subType.children.length))
        .flat()

    const elementsLength = calculateTotalLength(elementsTotal)
    const elementsComparision =
        elementsActiveFilters.length !== elementsLength &&
        elementsActiveFilters.length !== 0
    return elementsComparision
}

const isFiltered1level = (
    elements: Responsebase[],
    elementsActiveFilters: string[]
) => {
    return (
        elementsActiveFilters.length !== elements.length &&
        elementsActiveFilters.length !== 0
    )
}

export const isDashboardFiltered = (
    filters: FsFilters | RetailFilters | FsCrossFilters,
    activeFilters: ActiveFsFilters | ActiveRetailFilters | ActiveFsCrossFilters
) => {
    if (filters) {
        const date =
            activeFilters.startDate !== filters.startDate ||
            activeFilters.endDate !== filters.endDate

        const keys = Object.keys(filters)

        const isFiltered = keys.some((key) => {
            switch (filters[key].depth) {
                case DepthOfData.TWO:
                    return isFiltered2levels(
                        filters[key].data,
                        activeFilters[key]
                    )
                case DepthOfData.THREE:
                    return isFiltered3levels(
                        filters[key].data,
                        activeFilters[key]
                    )
                case DepthOfData.ONE:
                    return isFiltered1level(
                        filters[key].data,
                        activeFilters[key]
                    )
            }
        })

        return isFiltered || date
    } else {
        return false
    }
}

export const getActiveFilters = (envType: EnvType, onView: boolean) => {
    switch (envType) {
        case EnvType.FOOD_SERVICE:
            if (onView) {
                return 'activeFsViewFilters'
            } else {
                return 'activeFsFilters'
            }
        case EnvType.RETAIl:
            if (onView) {
                return 'activeRetailViewFilters'
            } else {
                return 'activeRetailFilters'
            }
        case EnvType.FOOD_SERVICE_CROSS:
            if (onView) {
                return 'activeFsCrossFilters'
            } else {
                return 'activeFsCrossFilters'
            }
    }
}
