import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Grid, Tooltip } from '@mui/material'
import { GREEN, RED } from './Colors'
import { PrimaryText, SecondaryText } from './Text'
import NumberWithSpace from './NumberWithSpace'
import { roundIfNotZero } from '../../helpers/roundIfNotZero'

interface OwnProps {
    difference: number
    unit: string
    kpi?: boolean
    tooltip?: TooltipMessage
    notRoundable?: boolean
    evolution?: number
}

export enum TooltipMessage {
    WAREHOUSES = `Variation du nombre de dépôts par rapport à l’année précédente sur la même période.`,
    CUSTOMERS = `Variation du nombre d'utilisateurs finaux par rapport à l’année précédente sur la même période.`,
    STORES = `Variation du nombre de magasins par rapport à l’année précédente sur la même période.`,
    PRODUCTS = `Variation du nombre de références par rapport à l’année précédente sur la même période.`,
    VOLUME = `Variation du volume par rapport à l’année précédente sur la même période.`,
    VMM = `Variation des ventes moyennes mensuelles (VMM) par rapport à l’année précédente sur la même période.`,
    SHARE_OF_TURNOVER = `Variation de la part de CA par rapport à l’année précédente sur la même période.`,
    CATEGORIES = "Variation du nombre de catégories produits par rapport à l'année précédente sur la même période.",
    CA = "Variation du CA par rapport à l'année précédente sur la même période.",
}

const Tendency = ({
    difference,
    unit,
    kpi,
    tooltip,
    notRoundable = false,
    evolution
}: OwnProps) => {
    const isEqual = roundIfNotZero(difference) === '0'

    // Determine arrow and text color :
    const color = difference > 0 ? GREEN : RED

    // Determine wich arrow to display if needed
    const arrowTendency =
        difference > 0 ? (
            <ArrowUpwardIcon sx={{ color, height: '2vh' }} />
        ) : (
            <ArrowDownwardIcon sx={{ color, height: '2vh' }} />
        )

    return (
        <Tooltip title={tooltip}>
            <Grid
                id="tendency"
                container
                wrap="nowrap"
                sx={
                    kpi
                        ? {
                            '& svg': { width: '1.2vw' },
                        }
                        : {
                            width: "auto",
                            '& svg': { width: '2vw' },
                        }

                }
                justifyContent="center"
                alignItems="center"
            >
                {!isEqual && arrowTendency}
                {isEqual ? (
                    <Grid container alignItems="baseline" >
                        <SecondaryText
                            style={{ height: '2vh', marginRight: '1vw' }}
                        >
                            =
                        </SecondaryText>
                        {evolution && <SecondaryText color={color} ml="0.5vw">{` (${roundIfNotZero(evolution, notRoundable)}%)`}</SecondaryText>}
                    </Grid>

                ) : !kpi ? (
                    <Grid container>
                        <SecondaryText color={color}>
                            <NumberWithSpace
                                number={roundIfNotZero(difference, notRoundable)}
                            />
                            {`${unit}`}
                        </SecondaryText>
                        {evolution && <SecondaryText color={color} ml="0.5vw">{` (${roundIfNotZero(evolution, true)}%)`}</SecondaryText>}
                    </Grid>

                ) : (
                    <Grid container alignItems="baseline">
                        <PrimaryText color={color}>
                            <NumberWithSpace
                                number={roundIfNotZero(
                                    difference,
                                    notRoundable
                                )}
                            />
                            {unit}
                        </PrimaryText>

                        {evolution && <PrimaryText color={color} ml="0.5vw">{` (${roundIfNotZero(evolution, true)} %)`}</PrimaryText>}
                    </Grid>
                )}
            </Grid>
        </Tooltip>
    )
}

export default Tendency
