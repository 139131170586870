import React from 'react'
import { RED } from './Colors'
import { PrimaryText } from './Text'

interface OwnProps {
    msg: string
}

const ErrorMessage = ({ msg }: OwnProps) => {
    return (
        <PrimaryText color={RED} fontWeight="400">
            {msg}
        </PrimaryText>
    )
}

export default ErrorMessage
