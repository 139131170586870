import { Card, Grid, Box } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BLACK, DARK_BLUE, LIGHT_BLUE, LIGHT_MOCK } from '../ui/Colors'
import { PrimaryText, SubTitle } from '../ui/Text'

interface OwnProps {
    title: string
    children: JSX.Element
    link?: string
    titleHasHover?: boolean
}

const HEADER_HEIGHT = '0.4'

const LinkButton = ({ route }: { route: string }) => {
    const navigate = useNavigate()

    return (
        <Box
            onClick={() => {
                navigate(route)
            }}
            sx={{
                cursor: 'pointer',
                background: LIGHT_BLUE,
                borderRadius: '5px',
                padding: '0.2vh 1vw',
                position: 'absolute',
                top: '1vh',
                right: '0.8vw',
            }}
        >
            <PrimaryText color={DARK_BLUE}>En savoir plus</PrimaryText>
        </Box>
    )
}

const ChartBlock = ({
    title,
    children,
    link,
    titleHasHover = true,
}: OwnProps) => {
    const [isHover, setHover] = useState<boolean>(false)

    return (
        <Card
            sx={{
                height: '100%',
            }}
            onMouseOver={() => {
                setHover(true)
            }}
            onMouseLeave={() => {
                setHover(false)
            }}
        >
            <Grid
                container
                position="relative"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                sx={{
                    borderBottom: `1px solid ${LIGHT_MOCK}`,
                    '& span': { fontSize: '1.2rem' },
                    padding: { xs: '10px', md: `${HEADER_HEIGHT}vw` },
                    height: '4.5vh',
                }}
            >
                <SubTitle
                    color={BLACK}
                    noWrap
                    width={
                        isHover && titleHasHover
                            ? 'calc(100% - 8.9vw)'
                            : 'initial'
                    }
                >
                    {title}
                </SubTitle>
                {isHover && link && <LinkButton route={link} />}
            </Grid>
            <Grid
                container
                direction="column"
                sx={{ height: `calc(100% - 4.5vh)` }}
            >
                {children}
            </Grid>
        </Card>
    )
}

export default ChartBlock
