import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import { useAppSelector } from '../../../../redux/hooks'
import { listOfFSDistributors } from '../types'
import { TransgourmetColumnsCustomersPage } from '../utils'
import { CustomersLayout } from '../layout/CustomersLayout'
import Customers from '../Pages/FsCustomersPage'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'

export const CustomersPageTGM = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage title="Utilisateurs finaux" period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.CLIENTS}
            />
            <CustomersLayout>
                <Customers
                    envName={listOfFSDistributors.TRANSGOURMET}
                    columns={TransgourmetColumnsCustomersPage()}
                    titles={{
                        doughnutTitle: `Volume facturé par type d'utilisateurs finaux`,
                        dataGridTitle: `Types d'utilisateurs finaux`,
                    }}
                    drillDownType={DrillDownType.CLIENTSTGM}
                />
            </CustomersLayout>
        </PrivatePage>
    )
}
