import React from 'react'
import { CommonTabs } from './othersDrillDownComponents'
import { DrillDownType } from './ProductsDrillDownContent'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'

export const WarehousesTGMDrillDownContent = ({
    row,
    value,
    handleChange,
}: DrillDownContentProps) => {
    return (
        <CommonTabs
            handleChange={handleChange}
            menus={['Informations']}
            row={row}
            value={value}
            type={DrillDownType.WAREHOUSESTGM}
        />
    )
}
