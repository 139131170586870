import * as React from 'react'
import { PrimaryText } from '../../../../ui/Text'
import { BLACK } from '../../../../ui/Colors'
import { Pages } from '../../../../../pages/private/DataTableComponent'
import { Grid, Radio } from '@mui/material'
import { useAppSelector } from '../../../../../redux/hooks'
import { EnvName } from '../../../../../redux/user/userTypes'

const titleDictionnary = {
    type: 'Type',
}

interface OwnProps {
    label: string
    toggleDataSets?: (layer: number, e: React.MouseEvent) => void
    layer?: number
    page: Pages
    checked?: boolean
}

export default function CheckboxLabels({
    label,
    toggleDataSets,
    layer,
    page,
    checked,
}: OwnProps) {
    const printedLabel = titleDictionnary[label] || label
    const clients =
        page === Pages.PRODUCTS
            ? useAppSelector((state) => state.fsProducts.clients)
            : { type: [] }

    const isOneOnlyOneLevel = Object.keys(clients).length === 1

    return (
        <Grid container alignItems="center">
            {!isOneOnlyOneLevel && (
                <Radio
                    defaultChecked
                    checked={checked}
                    onClick={(e) => {
                        e.stopPropagation()
                        toggleDataSets(layer, e)
                    }}
                />
            )}
            <PrimaryText
                onClick={(e) => {
                    e.preventDefault()
                }}
                color={BLACK}
            >
                {printedLabel}
            </PrimaryText>
        </Grid>
    )
}
