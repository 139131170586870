import { Chip, Grid, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Form, FormikBag, FormikProps, withFormik } from 'formik'
import { date, object, ref } from 'yup'
import EditIcon from '@mui/icons-material/Edit'
import {
    getDefaultPeriod,
    getPlaceholderDates,
    monthAsArray,
} from '../../../../helpers/months'
import { DARK_BLUE, LIGHT_BLUE, MEDIUM_GREY, SECONDARY } from '../../../ui/Colors'
import { CalendarPopover } from '../../filters/FilterCalendar'
import {
    ActiveFsCrossFilters,
    ActiveFsFilters,
    ActiveRetailFilters,
    FsCrossFilters,
    FsFilters,
    FsViewFilters,
    RetailFilters,
    RetailViewFilters,
} from '../../../../redux/filters/filtersTypes'
import trashIcon from '../../../../assets/icon/trashIcon.svg'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { trashFsCrossFilters, trashFsCrossPeriodFilters, trashFsPeriodFilter, trashRetailPeriodFilter } from '../../../../redux/filters/filtersSlice'
import { EnvType } from '../../../../redux/user/userTypes'

export interface DatesValues {

    activeStartDate: Date
    activeEndDate: Date
    min: Date
    max: Date
}

export interface DatesFormValues {
    startDate: Date
    endDate: Date
}

interface FormProps {
    dates: DatesValues
    onSubmit: (values: DatesFormValues) => void
}


export const differenceInMonths = (startDate: Date, endDate: Date) => {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth(); // 0-11
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth(); // 0-11

    return (endYear - startYear) * 12 + (endMonth - startMonth);
};

const PureFiltersForm = ({
    setFieldValue,
    submitForm,
    ...props
}: FormikProps<DatesFormValues> & FormProps) => {
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const periodByDefault = getDefaultPeriod()
    const [printedPeriod, setPrintedPeriod] = useState<string | null>(
        periodByDefault
    )
    const { env } = useAppSelector((state) => state.global)

    const handleClick = (el: HTMLElement) => {
        setAnchorEl(el)
        setOpen(true)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const _trashPeriodFilter = useCallback(
        () => {
            if (env.type === EnvType.FOOD_SERVICE) {
                dispatch(trashFsPeriodFilter())
            } else if (env.type === EnvType.FOOD_SERVICE_CROSS) {
                dispatch(trashFsCrossPeriodFilters())
            } else { dispatch(trashRetailPeriodFilter()) }
        },
        [dispatch]
    )


    const submitPeriod = () => {
        const submit = async () => {
            const errors = await props.validateForm()
            if (Object.keys(errors).length === 0) {
                submitForm()
            }
        }

        if (props.dirty || Object.keys(props.touched).length > 0) {
            submit()
        }
    }

    useEffect(() => {

        if (props.values.startDate && props.values.endDate) {
            const period = getPlaceholderDates(
                props.dates.activeStartDate,
                props.dates.activeEndDate
            )
            setPrintedPeriod(period)
        }
    }, [props.values, props.dates])

    return (
        <Form>
            <Grid container>
                <Tooltip title={!open ? 'Choisissez une période de données' : ''}>
                    <Grid>
                        <Chip
                            id="period"
                            label={printedPeriod}
                            sx={{
                                fontSize: '1.6vmin',
                                ml: '2vw',
                                height: '3.5vh',
                                cursor: 'pointer',
                                background: SECONDARY,
                                color: DARK_BLUE,
                                '&:hover': { background: SECONDARY },
                            }}
                            onClick={(e) => handleClick(e.currentTarget)}
                            // Use delete feature to put icon at the end of chip
                            onDelete={(e) => {
                                handleClick(e.currentTarget.parentElement)
                            }}
                            deleteIcon={
                                <EditIcon
                                    sx={{
                                        color: MEDIUM_GREY,
                                        cursor: 'pointer',
                                        height: '2vh',
                                        pr: '0.3vw',
                                    }}
                                />
                            }
                        />
                        <CalendarPopover
                            submitPeriod={submitPeriod}
                            setFieldValue={setFieldValue}
                            start={props.values.startDate}
                            end={props.values.endDate}
                            errorEndDate={props.errors.endDate as string}
                            errorStartDate={props.errors.startDate as string}
                            open={open}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                        />
                    </Grid>
                </Tooltip>
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        borderRadius: '50px',
                        height: '2vw',
                        width: '2vw',
                        '&:hover': {
                            background: LIGHT_BLUE,
                        },
                    }}
                >
                    <Tooltip title="Réinitialiser la période">
                        <img
                            style={{
                                width: '2.5vw',
                                height: '2.5vh',
                                cursor: 'pointer',
                            }}
                            src={trashIcon}
                            onClick={() => {
                                _trashPeriodFilter()
                            }}
                        />
                    </Tooltip>
                </Grid>
            </Grid>
        </Form>
    )
}

const ControlledFiltersForm = withFormik<FormProps, DatesFormValues>({
    mapPropsToValues: (props) => ({
        startDate: props.dates.activeStartDate,
        endDate: props.dates.activeEndDate,
    }),
    enableReinitialize: true,
    validationSchema: (props) => {
        const startMonth = props.dates.min.getMonth()
        const startYear = props.dates.min.getFullYear()
        const endMonth = props.dates.max.getMonth()
        const endYear = props.dates.max.getFullYear()

        return object().shape({
            startDate: date()
                .typeError('Entrez une date valide')
                .min(
                    props.dates.min,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de début'),
            endDate: date()
                .typeError('Entrez une date valide')
                .min(
                    ref('startDate'),
                    'Sélectionnez une date plus ancienne que celle de début'
                )
                .max(
                    props.dates.max,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de fin')
                .test(
                    'is-within-12-months',
                    'Vous ne pouvez pas sélectionner plus de 12 mois consécutifs.',
                    function (endDate) {
                        const { startDate } = this.parent;

                        if (startDate && endDate) {
                            const monthsDifference = differenceInMonths(startDate, endDate);
                            return monthsDifference <= 11;
                        }
                        return true; // Si les dates ne sont pas définies, on passe.
                    }
                ),
        })
    },
    handleSubmit: (
        values: DatesFormValues,
        bag: FormikBag<FormProps, DatesFormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureFiltersForm)

const Period = ({
    handleSubmit,
    activeValues,
}: {
    handleSubmit: (values: DatesFormValues) => void
    activeValues: ActiveFsFilters | ActiveRetailFilters | ActiveFsCrossFilters
}) => {
    const dates = {

        activeStartDate: new Date(activeValues.startDate),
        activeEndDate: new Date(activeValues.endDate),
        min: new Date(activeValues.min),
        max: new Date(activeValues.max)
    }

    dates.activeStartDate.setHours(0, 0, 0)
    dates.activeEndDate.setHours(0, 0, 0)
    dates.min.setHours(0, 0, 0)
    dates.max.setHours(0, 0, 0)

    return <ControlledFiltersForm onSubmit={handleSubmit} dates={dates} />
}

export default Period
