import React from 'react'
import WaitingPicture from '../../../assets/waiting-picture.png'
import { Grid, Paper } from '@mui/material'
import { BLACK, PRIMARY } from '../../ui/Colors'
import { PrimaryText } from '../../ui/Text'

const WaitingScreen = () => {
    return (
        <Paper
            sx={{
                width: 'calc(100% - 48px)',
                height: '86vh',
                margin: 'auto',
                marginTop: '1vw',
            }}
        >
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                height={'100%'}
                wrap="nowrap"
            >
                <img
                    alt="Chargement"
                    src={WaitingPicture}
                    style={{ width: '40vw' }}
                />
                <PrimaryText mt={3} color={BLACK}>
                    Vos données sont en cours de traitement, vous serez notifié
                    lorsqu'elles seront disponibles.
                </PrimaryText>
                <PrimaryText bold color={BLACK} mt={'3vh'}>
                    Découvrez dès à présent comment prendre en main la
                    plateforme :{' '}
                    <a
                        href="https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed"
                        style={{ marginLeft: '5px', color: PRIMARY }}
                        target={'_blank'}
                        rel="noreferrer noopener"
                    >
                        Boîte à outils
                    </a>
                </PrimaryText>
            </Grid>
        </Paper>
    )
}

export default WaitingScreen
