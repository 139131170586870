import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { DashboardTGM } from '../../../../../../pages/private/Foodservice/Transgourmet/DashboardTGM'
import { CustomersPageTGM } from '../../../../../../pages/private/Foodservice/Transgourmet/CustomersPage'
import { ProductsPageTGM } from '../../../../../../pages/private/Foodservice/Transgourmet/ProductsPage'
import { WarehousesPageTGM } from '../../../../../../pages/private/Foodservice/Transgourmet/WarehousesPage'
import { ViewPageTGM } from '../../../../../../pages/private/Foodservice/Transgourmet/ViewPage'
import { PromoPageTGM } from '../../../../../../pages/private/Foodservice/Transgourmet/PromoPage'

export const TransgourmetRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardTGM />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageTGM />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'depots/',
        element: <WarehousesPageTGM />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'produits/',
        element: <ProductsPageTGM />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPageTGM />,
        title: PagesName.VIEWS,
    },
    ...(modules.includes(KaryonModules.PROMO)
        ? [
              {
                  path: 'promo/',
                  element: <PromoPageTGM />,
                  title: PagesName.PROMO,
              },
          ]
        : []),
]
