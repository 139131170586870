import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Paper, Box } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import { string, object } from 'yup'
import {
    inputsMissions,
    inputsProfil,
    menuForUser,
} from './settingsPageProfil'
import { InputsProfilPage } from './InputsProfilPage'
import { MenuProfil } from './MenuProfil'
import { handleScroll } from './handleScroll'
import { PrimaryText, SubTitle } from '../../../../components/ui/Text'
import Avatar from '../../../../components/features/Avatar'
import { GREEN, LIGHT_GREY } from '../../../../components/ui/Colors'
import { Separator } from '../../../../components/ui/Separator'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import ErrorMessage from '../../../../components/ui/ErrorMessage'

import {
    Env,
    User,
    UsersStatus,
} from '../../../../redux/user/userTypes'
import { PrimaryButton } from '../../../../components/ui/Button'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchUpdateUser } from '../../../../redux/user/userSlice'
import { InputUseCasesProfilPage } from './InputUseCasesProfilPage'


export interface EditProfilValues {
    firstname: string
    lastname: string
    email: string
    phoneNumber: string
    job: string
    useCases: string[]
}

export interface EditCompanyValues {
    distributerRetail: string[]
    distributerFS: string[]
    dataRetail: string[]
    dataFS: string[]
    hasOthersRetail?: boolean
    hasOthersFS?: boolean
    companyName: string
    members: {
        firstname: string
        lastname: string
        email: string
        status: UsersStatus
    }[]
    env: Env[]
}

export type EditValues = EditProfilValues & {
    buttonDisabled: boolean
}
interface ValidationStatus {
    status: 'submit' | 'error' | null
    msg: string
}

export interface FormProps {
    user: User
    onSubmit: (values: EditValues) => void
    submitMsg: ValidationStatus
}

const PureUserUpdateForm = (
    props: FormikProps<EditValues> & FormProps
): JSX.Element => {
    useEffect(() => {
        handleScroll(props.user.role)
        window.addEventListener('scroll', () => handleScroll(props.user.role))
        return () => {
            window.removeEventListener('scroll', () =>
                handleScroll(props.user.role)
            )
        }
    }, [])

    useEffect(() => {
        props.setFieldValue('buttonDisabled', true)
    }, [props.submitCount])

    if (props.dirty && props.submitCount < 1) {
        props.values.buttonDisabled = false
    }

    const inputsToDisplay = [{ ...inputsProfil, id: 0 }, inputsMissions]

    return (
        <Form>
            <Grid container direction="row">
                <Grid container alignItems="center" direction="column" mt="5vh">
                    <Grid>
                        {inputsToDisplay.map((section) => (
                            <Grid
                                key={section.id}
                                container
                                direction="column"
                                mb="5vh"
                            >
                                <Separator />
                                <SubTitle
                                    bold
                                    id={`${section.id}`}
                                    mt="4vh"
                                    mb="4vh"
                                >
                                    {section.title}
                                </SubTitle>
                                {section.inputs.map((input) => (
                                    <Grid
                                        sx={{
                                            '& .MuiFormHelperText-root': {
                                                fontSize: '1.6vh',
                                            },
                                        }}
                                    >
                                        <InputsProfilPage
                                            key={input.id}
                                            name={input.name}
                                            type={input.type}
                                            label={input.label}
                                            list={
                                                input.list ? input.list : null
                                            }
                                            disabled={input.disabled}
                                            {...props}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid>
                        <InputUseCasesProfilPage {...props}></InputUseCasesProfilPage>
                    </Grid>
                    <Grid>
                        {props.submitMsg &&
                            (props.submitMsg.status === 'submit' ? (
                                <PrimaryText color={GREEN}>
                                    {props.submitMsg.msg}
                                </PrimaryText>
                            ) : (
                                <ErrorMessage msg={props.submitMsg.msg} />
                            ))}
                    </Grid>
                    <Grid>
                        <Box mt="2vh">
                            <PrimaryButton
                                disabled={props.values.buttonDisabled}
                                type="submit"
                                style={{ marginBottom: '2vh' }}
                            >
                                Enregistrer
                            </PrimaryButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    )
}

const ControlledUpdateUserForm = withFormik<FormProps, EditValues>({
    mapPropsToValues: ({ user }) => ({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phoneNumber: user.phoneNumber ? user.phoneNumber : '',
        job: user.job,
        useCases: user.useCases,
        buttonDisabled: true,
    }),

    validationSchema: () => {
        return object().shape({
            firstname: string().required('Le champ Prénom est obligatoire'),
            lastname: string().required('Le champ Nom est obligatoire'),
            job: string().required('Le champ Fonction est obligatoire'),
        })
    },
    enableReinitialize: true,
    handleSubmit: (
        values: EditValues,
        bag: FormikBag<FormProps, EditValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureUserUpdateForm)

export const ProfilPage = () => {
    const user = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _updateUser = useCallback(
        async (user: Partial<User> & EditProfilValues) =>
            await dispatch(fetchUpdateUser(user)),
        [dispatch]
    )

    const initialStatus = {
        status: null,
        msg: '',
    }
    const [submitMsg, setSubmitMsg] = useState<ValidationStatus>(initialStatus)

    useEffect(() => {
        if (submitMsg.status === 'submit') {
            const interval = setInterval(() => {
                setSubmitMsg(initialStatus)
            }, 3500)
            return () => {
                clearInterval(interval)
            }
        }
    }, [submitMsg.status])

    const handleSubmit = async (values: EditValues) => {
        delete values.email
        delete values.buttonDisabled

        let userModified = null

        userModified = await _updateUser({
            firstname: values.firstname,
            lastname: values.lastname,
            job: values.job,
            email: values.email,
            phoneNumber: values.phoneNumber,
            useCases: values.useCases
        })

        if (userModified) {
            setSubmitMsg({
                status: 'submit',
                msg: 'Vos modifications ont bien été enregistrées !',
            })
        } else {
            setSubmitMsg({
                status: 'error',
                msg: 'Aucune modification à enregistrer',
            })
        }
    }

    return (
        <PrivatePage title="Profil">
            <Grid
                container
                direction="column"
                sx={{ background: LIGHT_GREY }}
                p="1vw"
            >
                <MenuProfil data={menuForUser} />
                <Paper>
                    <Grid container direction="column" alignItems="center">
                        {user.firstname.length && (
                            <Box mt={-5}>
                                <Avatar name={user.firstname[0]} large />
                            </Box>
                        )}
                        <ControlledUpdateUserForm
                            submitMsg={submitMsg}
                            onSubmit={handleSubmit}
                            user={user}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </PrivatePage>
    )
}
