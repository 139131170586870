import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import DashboardCercleVert from '../../../../../../pages/private/Foodservice/Cercle Vert/DashboardCercleVert'
import { CustomersPageCercleVert } from '../../../../../../pages/private/Foodservice/Cercle Vert/CustomersPage'
import { ProductsPageCercleVert } from '../../../../../../pages/private/Foodservice/Cercle Vert/ProductsPage'
import { ViewPageCercleVert } from '../../../../../../pages/private/Foodservice/Cercle Vert/ViewPage'

export const CercleVertRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardCercleVert />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageCercleVert />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'produits/',
        element: <ProductsPageCercleVert />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPageCercleVert />,
        title: PagesName.VIEWS,
    },
]
