import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import HistogramLabels from './HistogramLabels'

export interface ProductsChart {
    name: string
    labels: string[]
    data: number[]
    dataLastYear?: number[]
}

interface OwnProps {
    data: {
        labels: string[]
        datasets: {
            data: number[]
            backgroundColor: string
            borderColor: string
        }[]
    }
    options: any
    height: string
}

export const Histogram = ({ data, options, height }: OwnProps) => {
    const chartRef = React.useRef()
    const [isLabels, setIsLabels] = useState<boolean>(false)
    useEffect(() => {
        setIsLabels(true)
    }, [chartRef])

    return (
        <Grid container justifyContent="center">
            <Grid item sx={{ pt: '0.8vh' }}>
                {isLabels ? (
                    <HistogramLabels chart={chartRef.current} />
                ) : (
                    'loading'
                )}
            </Grid>
            <Grid
                item
                sx={{
                    width: '95%',
                    height: height,
                }}
            >
                <Bar
                    updateMode="none"
                    ref={chartRef}
                    options={options as any}
                    data={data}
                />
            </Grid>
        </Grid>
    )
}
