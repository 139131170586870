import { Grid } from '@mui/material'
import React from 'react'
import ChartBlock from '../../../../../components/features/ChartBlock'
import LineChart from '../../../../../components/features/foodService/dashboard/LineChart'
import { useAppSelector } from '../../../../../redux/hooks'
import { MultipleCards, UniqueCard } from '../../Pages/Dashboard/FsLineChart'
import LineChartCross from '../../../../../components/features/foodService/cross/LineChartCross'

const FsCrossLineChart = () => {
    const { productsTopFlop } = useAppSelector(
        (state) => state.global.fsCrossDashboardData
    )
    const { unit } = useAppSelector((state) => state.global)
    const { name } = useAppSelector((state) => state.global.env)

    return (
        <ChartBlock
            title={`Ventes par famille par mois (${unit})`}
        >
            <Grid container wrap="nowrap" height="100%">
                {productsTopFlop.length > 1 ? (
                    <MultipleCards
                        top={productsTopFlop[0]}
                        flop={productsTopFlop[productsTopFlop.length - 1]}
                    />
                ) : (
                    <UniqueCard data={productsTopFlop[0]} />
                )}
                <Grid item flex={3} p="0px 1vw 1vw 1vw">
                    <LineChartCross />
                </Grid>
            </Grid>
        </ChartBlock>
    )
}

export default FsCrossLineChart
