import React from 'react'
import { WarehousesDrillDownContent } from './WarehousesDrillDownContent'
import {
    DrillDownType,
    ProductsDrillDownContent,
} from './ProductsDrillDownContent'
import { StoresDrillDownContent } from './StoresDrillDownContent'
import { RetailProductsDrillDownContent } from './RetailProductsDrillDownContent'
import { PromoDrillDownContent } from '../../../pages/private/Foodservice/Pages/PromotionPage/drilldownPromo/DrillDownPromo'
import { WarehousesTGMDrillDownContent } from './WarehousesTGMDrillDownContent'
import { ProductsTGMDrillDownContent } from './ProductsTGMDrillDownContent'
import { PromoTGMDrillDownContent } from '../../../pages/private/Foodservice/Pages/PromotionPage/drilldownPromo/DrillDownPromoTGM'
import { ProductsSodexoDrillDownContent } from './ProductsSodexoDrillDownContent'
import { ClientTGMDrillDownContent } from './ClientTGMDrillDownContent'
import { ClientSodexoDrillDownContent } from './ClientSodexoDrillDownContent'
import { ClientMetroDrillDownContent } from './ClientMetroDrillDownContent'
import { ProductsMetroDrillDownContent } from './ProductsMetroDrillDownContent'
import { ClientFFRDrillDownContent } from './ClientFFRDrillDownContent'
import { WarehousesMetroDrillDownContent } from './WarehousesMetroDrillDownContent'
import { ProductsCercleVertDrillDownContent } from './ProductsCercleVertDrillDownContent'
import { ClientCercleVertDrillDownContent } from './ClientCercleVertDrillDownContent'
import { ProductsEpiSaveursDrillDownContent } from './ProductsEpiSaveursDrillDownContent'

export interface DrillDownProps {
    children?: any
    type?: DrillDownType
    row: any
    value: number
    handleChange: (event: React.SyntheticEvent, newValue: number) => void
    isFirstVisit: boolean
}

export const DrillDownContent = ({
    type,
    row,
    value,
    isFirstVisit,
    handleChange,
}: DrillDownProps) => {
    switch (type) {
        case DrillDownType.CLIENTS:
        case DrillDownType.CLIENTSPF:
        case DrillDownType.CLIENTSEPISAVEURS:
            {
                return (
                    <ClientFFRDrillDownContent
                        isFirstVisit={isFirstVisit}
                        type={type}
                        row={row}
                        value={value}
                        handleChange={handleChange}
                    />
                )
            }
        case DrillDownType.CLIENTSCERCLEVERT: {
            return (
                <ClientCercleVertDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        }
        case DrillDownType.CLIENTSTGM: {
            return (
                <ClientTGMDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        }
        case DrillDownType.CLIENTSSODEXO: {
            return (
                <ClientSodexoDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        }
        case DrillDownType.CLIENTSMETRO: {
            return (
                <ClientMetroDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        }
        case DrillDownType.WAREHOUSES:
        case DrillDownType.WAREHOUSESPF:
        case DrillDownType.WAREHOUSESEPISAVEURS:
            return (
                <WarehousesDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        case DrillDownType.WAREHOUSESTGM:
            return (
                <WarehousesTGMDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={0} // Fix the value because in this drillDown there is only one tab and we always arrive on it no matter which column the user click on
                    handleChange={handleChange}
                />
            )
        case DrillDownType.WAREHOUSESMETRO:
            return (
                <WarehousesMetroDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        case DrillDownType.STORES:
            return (
                <StoresDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        case DrillDownType.RETAILPRODUCTS:
            return (
                <RetailProductsDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        case DrillDownType.PROMO:
            return (
                <PromoDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        case DrillDownType.PROMOTGM:
            return (
                <PromoTGMDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        case DrillDownType.PRODUCTSTGM:
            return (
                <ProductsTGMDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={0} // Fix the value because in this drillDown there is only one tab and we always arrive on it no matter which column the user click on
                    handleChange={handleChange}
                />
            )
        case DrillDownType.PRODUCTSSODEXO:
            return (
                <ProductsSodexoDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        case DrillDownType.PRODUCTSMETRO:
            return (
                <ProductsMetroDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        case DrillDownType.PRODUCTSCERCLEVERT:
            return (
                <ProductsCercleVertDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange} />)
        case DrillDownType.PRODUCTSEPISAVEURS:
            return (
                <ProductsEpiSaveursDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
        default:
            return (
                <ProductsDrillDownContent
                    isFirstVisit={isFirstVisit}
                    type={type}
                    row={row}
                    value={value}
                    handleChange={handleChange}
                />
            )
    }
}
