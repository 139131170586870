import React from 'react'
import { CommonTabs } from './othersDrillDownComponents'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'
import { DrillDownType } from './ProductsDrillDownContent'

export const ProductsTGMDrillDownContent = ({
    row,
    value,
    handleChange,
}: DrillDownContentProps) => {
    return (
        <CommonTabs
            handleChange={handleChange}
            menus={['Informations']}
            row={row}
            value={value}
            type={DrillDownType.PRODUCTSTGM}
        />
    )
}
