// IMAGES
import Auchan from '../assets/FSlogo/auchan.jpg'
import Intermarché from '../assets/FSlogo/intermarche.jpg'
import Monoprix from '../assets/FSlogo/monoprix-logo.jpg'
import Casino from '../assets/FSlogo/casino.png'
import Franprix from '../assets/FSlogo/franprix.png'
import MagasinsU from '../assets/FSlogo/MagasinsU.jpg'
import Carrefour from '../assets/FSlogo/Carrefour.png'
import Leclerc from '../assets/FSlogo/leclerc.jpg'
import Cora from '../assets/FSlogo/cora.jpg'
import Carrefourmarket from '../assets/FSlogo/CM.jpg'
import Metro from '../assets/FSlogo/metro.png'
import FranceFrais from '../assets/FSlogo/logos/france-frais-logo.svg'
import ProaPro from '../assets/FSlogo/logos/proapro.png'
import Distriboissons from '../assets/FSlogo/logos/logo-restauration-services.png'
import Sysco from '../assets/FSlogo/logos/Sysco-Logo.svg.png'
import Vivalya from '../assets/FSlogo/logos/thumbnail_logo-vivalya-.jpg'
import C10 from '../assets/FSlogo/logos/logo-c10.png'
import Transgourmet from '../assets/FSlogo/logos/logo2.png'
import Episaveurs from '../assets/FSlogo/logos/epiSaveurs.png'
import RelaisdOr from "../assets/FSlogo/logos/Logo Relais d'OrV2.png"
import donutIcon from '../assets/icon/donutIcon.svg'
import PassionFroid from '../assets/FSlogo/logos/passionFroid.png'
import Sodexo from '../assets/FSlogo/logos/sodexo.png'
import CercleVert from '../assets/FSlogo/logos/Logo Cercle Vert.jpg'

export const retailDistributor = [
    'Auchan',
    'Carrefour',
    'Carrefourmarket',
    'Casino',
    'Cora',
    'Franprix',
    'Intermarché',
    'Leclerc',
    'Monoprix',
    'Magasins U',
]

export const fsDistributor = [
    'C10',
    'Distriboissons',
    'France Frais',
    'Metro',
    'PassionFroid',
    'Pro à pro',
    "Relais d'Or",
    'Sysco',
    'Transgourmet',
    'Transgourmet plus',
    'Vivalya',
    'EpiSaveurs',
    'Sodexo',
    'Cercle vert'
]

export const distributorImages = {
    Auchan: Auchan,
    Carrefour: Carrefour,
    Intermarché: Intermarché,
    Monoprix: Monoprix,
    Casino: Casino,
    Franprix: Franprix,
    'Magasins U': MagasinsU,
    Leclerc: Leclerc,
    Cora: Cora,
    Carrefourmarket: Carrefourmarket,
    'France Frais': FranceFrais,
    Sysco: Sysco,
    Transgourmet: Transgourmet,
    'Transgourmet plus': Transgourmet,
    EpiSaveurs: Episaveurs,
    PassionFroid: PassionFroid,
    Metro: Metro,
    'Pro à pro': ProaPro,
    Distriboissons: Distriboissons,
    Vivalya: Vivalya,
    C10: C10,
    "Relais d'Or": RelaisdOr,
    Commandes: donutIcon,
    Sodexo: Sodexo,
    'Cercle vert': CercleVert
}

export const MINIMUM_CHARACTER_FOR_OTHERS = 1
