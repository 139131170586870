import React, { useState, useCallback } from 'react'
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    InfoWindow,
} from '@react-google-maps/api'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { Grid } from '@mui/material'
import { PrimaryText, SecondaryText } from '../ui/Text'
import { PrimaryButton } from '../ui/Button'
import { handleViewCreation, updateData } from '../../redux/views/viewsSlice'
import { ResponseViewsCreation } from '../../redux/views/viewsTypes'
import { useNavigate } from 'react-router-dom'
import { Units } from '../../redux/global/globalTypes'

const containerStyle = {
    width: 'auto',
    height: '400px',
}

const center = {
    lat: 48.8566,
    lng: 2.3522,
}

function MapContainer() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { geoStores } = useAppSelector(
        (state) => state.global.retailDashboardData
    )
    const { env } = useAppSelector((state) => state.global)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyA68WrAmkqKANzNg9qZirSPbNaMNSgy0pk',
        language: 'fr',
    })
    const [selectedCenter, setSelectedCenter] = useState(null)
    const { activeRetailFilters } = useAppSelector((state) => state.filters)

    const _updateData = useCallback(
        ({ stores, storeName }: { stores: string[]; storeName: string }) =>
            dispatch(
                updateData({
                    data: {
                        name: `Vue ${storeName}`,
                        environment: env.type,
                        distributor: env.name,
                        filters: {
                            products: [],
                            startDate: activeRetailFilters.startDate,
                            endDate: activeRetailFilters.endDate,
                            min: activeRetailFilters.min,
                            max: activeRetailFilters.max,
                            stores,
                            distributionChannels:
                                activeRetailFilters.distributionChannels,
                        },
                    },
                })
            ),
        [dispatch]
    )

    const _handleCreation = useCallback(
        async () => await dispatch(handleViewCreation()),
        [dispatch]
    )
    const handleCreation = (store: string) => {
        _updateData({ stores: [store], storeName: store })
        _handleCreation().then((view: ResponseViewsCreation) => {
            navigate(
                `/${view.environment}/${view.distributor}/vues/${view.insertedId}`
            )
        })
    }

    return isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={5}>
            <>
                {geoStores.map((location) => (
                    <Marker
                        position={location.position}
                        title={location.store}
                        onClick={() => {
                            setSelectedCenter(location)
                        }}
                    />
                ))}
                {selectedCenter && (
                    <InfoWindow
                        onCloseClick={() => {
                            setSelectedCenter(null)
                        }}
                        position={{
                            lat: selectedCenter.position.lat,
                            lng: selectedCenter.position.lng,
                        }}
                    >
                        <Grid padding={2}>
                            <PrimaryText>{selectedCenter.store}</PrimaryText>
                            <Grid paddingTop={2} paddingBottom={2}>
                                <SecondaryText>
                                    Total des ventes : {selectedCenter.sells}
                                    {` ${Units.uvc}`}
                                </SecondaryText>
                                <SecondaryText>
                                    Références distribuées :
                                    {` ${selectedCenter.products} `}
                                </SecondaryText>
                            </Grid>
                            <PrimaryButton
                                onClick={() =>
                                    handleCreation(selectedCenter.store)
                                }
                            >
                                Créer une vue
                            </PrimaryButton>
                        </Grid>
                    </InfoWindow>
                )}
            </>
        </GoogleMap>
    ) : (
        <></>
    )
}

export default React.memo(MapContainer)
