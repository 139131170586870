import React from 'react'
import { DialogContent, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import { array, object, string } from 'yup'
import { StepperData } from '../../../redux/stepper/stepperTypes'
import { PrimaryText } from '../../ui/Text'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import { BLACK } from '../../ui/Colors'
import { ExportComponentProps, StepExportConfig } from './ExportSetup'
import DistributerSelector from '../DistributerSelector'
import { useAppSelector } from '../../../redux/hooks'
import ErrorMessage from '../../ui/ErrorMessage'
import { EnvStatus } from '../../../redux/user/userTypes'

interface FormValues {
    distributors: string[]
}

interface FormProps {
    initialValues: string[]
    distributors: string[]
    config: StepExportConfig
    step: number
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

const PureMultiExportFilterStepForm = ({
    setFieldValue,
    submitForm,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    const toggleDistributer = (distributor: string) => {
        const isInclude = props.values.distributors.includes(distributor)
        if (isInclude) {
            setFieldValue(
                'distributors',
                props.values.distributors.filter((el) => el !== distributor)
            )
        } else {
            setFieldValue('distributors', [
                ...props.values.distributors,
                distributor,
            ])
        }
    }
    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '32vh' }}>
                <PrimaryText color={BLACK}>
                    De quel(s) distributeur(s) souhaitez-vous exporter les
                    données ?
                </PrimaryText>
                <Grid>
                    <Grid
                        container
                        style={{
                            padding: 4,
                        }}
                    >
                        <DistributerSelector
                            list={props.distributors}
                            values={props.values.distributors}
                            selectorHandler={toggleDistributer}
                        />
                    </Grid>

                    {props.submitCount > 0 && props.errors.distributors && (
                        <Grid
                            item
                            mt="3vh"
                            display="flex"
                            justifyContent="center"
                        >
                            <ErrorMessage
                                msg={props.errors.distributors as string}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={(e) => {
                    props.config.handlePrevious()
                }}
                leftButtonText={'PRECEDENT'}
                rightButtonFunc={(e) => {
                    e.stopPropagation()

                    submitForm()
                }}
                rightButtonText={'SUIVANT'}
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        distributors: [],
    }),
    validationSchema: () => {
        return object().shape({
            distributors: array()
                .of(string().required())
                .required()
                .min(1, 'Sélectionner au moins un distributeur'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureMultiExportFilterStepForm)

const MultiExportDistributorsStep = ({
    step,
    config,
}: ExportComponentProps) => {
    const allDistributors = useAppSelector((state) => state.user.user.env)
    const { environment } = config.getData() as unknown as any
    const activeEnv = allDistributors.filter(
        (distributor) =>
            (distributor.status === EnvStatus.ACTIVATED ||
                EnvStatus.ON_LOADING ||
                EnvStatus.OUTDATED) &&
            distributor.type === environment
    )

    const activeDistributors = activeEnv.map((distrib) => distrib.name)
    const handleSubmit = (values: FormValues) => {
        config.updateData(values as unknown as StepperData)
        config.handleNext()
    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            initialValues={activeDistributors}
            distributors={activeDistributors}
        />
    )
}

export default MultiExportDistributorsStep
