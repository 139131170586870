import React from 'react'
import { Grid } from '@mui/material'
import {
    CustomTabPanel,
    DrillDownMenu,
} from '../../../../../../components/features/drillDowns/othersDrillDownComponents'
import { PromoInformationsTabTGM } from './PromoInformationsTabTGM'
import { PrimaryText } from '../../../../../../components/ui/Text'
import NoInfo from '../../../../../../assets/no-informations.svg'
import { DrillDownContentProps } from '../../../../../../components/features/drillDowns/ClientFFRDrillDownContent'

export const PromoTGMDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
}: DrillDownContentProps) => {
    return (
        <Grid height="66vh" width={'100%'}>
            <Grid
                sx={{
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                    '& .MuiTab-root.Mui-selected': {
                        color: '#666666',
                    },
                    '& .MuiTab-root': {
                        fontSize: { xs: '16px', md: '1.6vmin' },
                        padding: '1vw 2vh',
                        maxWidth: 'unset',
                    },
                }}
            >
                <DrillDownMenu
                    menus={['KPI CARD', 'UTILISATEURS FINAUX']}
                    handleChange={handleChange}
                    value={value}
                />
            </Grid>
            <PromoInformationsTabTGM value={value} row={row} />
            <CustomTabPanel value={value} index={1}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <img
                        style={{ width: '20vw', marginTop: '10vh' }}
                        src={NoInfo}
                        alt="Pas de résultats"
                    />
                    <PrimaryText mt="4vh" mb="5vh">
                        Les données Transgourmet ne permettent pas d’afficher
                        cette maille d’information
                    </PrimaryText>
                </Grid>
            </CustomTabPanel>
        </Grid>
    )
}
