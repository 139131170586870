import React, { useCallback } from 'react'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { type User } from '../../../redux/user/userTypes'
import { BLACK } from '../../ui/Colors'
import { Field, type FormikBag, type FormikProps, withFormik } from 'formik'
import { PrimaryText } from '../../ui/Text'
import DistributerSelector from '../DistributerSelector'
import {
    type DistributorComponentProps,
    type EnhancedQuestion,
} from './RetailDialogContent'
import { TextInput } from '../../ui/Inputs'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import {
    fsDistributor,
    MINIMUM_CHARACTER_FOR_OTHERS,
} from '../../../helpers/distributorsList'
import { PrimaryButton } from '../../ui/Button'
import { hasOthers } from '../../../pages/public/authentication/signUp/steps/SignUpRetail'
import { array, object, string } from 'yup'
import ErrorMessage from '../../ui/ErrorMessage'
import { fetchUpdateFsDistributor } from '../../../redux/companies/companiesSlice'
import { CompanyData } from '../../../redux/companies/companiesTypes'

export interface EditValuesFs {
    distributerFS: string[]
    dataFS: string[]
    distributerFSStartingList?: string[]
    otherFS?: string
    hasOthersFS?: boolean
}

interface FormProps {
    user: Partial<User>
    onSubmit: (values: EditValuesFs) => void
    onClose: () => void
}

const inputsdata: EnhancedQuestion = {
    id: 0,
    type: 'distributer',
    list: fsDistributor,
    name: 'distributerFS',
}

const DistributorComponent = ({
    type,
    name,
    list,
    ...props
}: DistributorComponentProps & FormikProps<EditValuesFs> & FormProps) => {
    const toggleDistributer = (distributer: string) => {
        const isInclude = props.values[name].includes(distributer)
        if (isInclude) {
            props.setFieldValue(
                name,
                props.values[name].filter((el) => el !== distributer)
            )
        } else {
            props.setFieldValue(name, [...props.values[name], distributer])
        }
    }

    const handleAddButton = ({ otherFS }: { otherFS: string }) => {
        if (!props.errors.otherFS) {
            props.setFieldValue('otherFS', '')
            toggleDistributer(otherFS)
        }
    }

    return (
        <Grid>
            <Grid alignItems="center" justifyContent="center" width="40vw">
                <Grid item flex={2}>
                    <PrimaryText color={BLACK}>
                        Quels distributeurs souhaitez-vous ajouter ?
                    </PrimaryText>
                    <DistributerSelector
                        list={list}
                        values={props.values[name]}
                        selectorHandler={toggleDistributer}
                    />
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    marginTop={3}
                    flexWrap="nowrap"
                >
                    <Grid item>
                        <Field
                            sx={{
                                '& label': {
                                    fontSize: '1.6vmin',
                                },
                            }}
                            label="Autre(s)"
                            type="text"
                            name="otherFS"
                            component={TextInput}
                        />
                    </Grid>
                    <Grid item ml={3}>
                        <PrimaryButton
                            disabled={
                                props.values.otherFS.length <
                                MINIMUM_CHARACTER_FOR_OTHERS
                            }
                            type="button"
                            onClick={() => {
                                handleAddButton({
                                    otherFS: props.values.otherFS,
                                })
                            }}
                        >
                            Ajouter
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const PureDistributorUpdateForm = (
    props: FormikProps<EditValuesFs> & FormProps
): JSX.Element => {
    // The next 3 lines allows us to display the distributor list with the others the user just added
    const otherDistributors = props.values.distributerFS.filter(
        (distributor) =>
            !fsDistributor.includes(distributor) &&
            !props.values.distributerFSStartingList.includes(distributor)
    )
    const distributor = fsDistributor.filter(
        (distributor) =>
            !props.values.distributerFSStartingList.includes(distributor)
    )

    const distributorListToDisplay = [...distributor, ...otherDistributors]

    return (
        <Grid container direction="column" alignItems="center">
            <Grid justifyContent="center" mt="5vh">
                <Grid container direction="column" justifyContent="center">
                    <DistributorComponent
                        key={inputsdata.id}
                        name={inputsdata.name}
                        type={inputsdata.type}
                        list={distributorListToDisplay}
                        {...props}
                    />
                </Grid>
            </Grid>
            {props.submitCount > 0 && props.errors.distributerFS && (
                <Grid item mt="3vh">
                    <ErrorMessage msg={props.errors.distributerFS as string} />
                </Grid>
            )}
            <Grid width={'100%'} bottom={0} mt="5vh">
                <DialogFooter
                    leftButtonFunc={(e) => {
                        e.stopPropagation()
                        props.onClose()
                    }}
                    leftButtonText={'Annuler'}
                    rightButtonFunc={(e) => {
                        e.stopPropagation()
                        props.submitForm()
                    }}
                    rightButtonText={'Valider'}
                />
            </Grid>
        </Grid>
    )
}

const ControlledUpdateUserForm = withFormik<FormProps, EditValuesFs>({
    mapPropsToValues: ({ user }) => ({
        distributerFS: [],
        distributerFSStartingList: user.fs ? user.fs.distributer : [],
        dataFS: user.fs ? user.fs.data : [],
        otherFS: '',
    }),
    validationSchema: () => {
        return object().shape({
            distributerFS: array()
                .of(string().required())
                .required()
                .min(1, 'Sélectionner au moins un distributeur'),
            otherFS: string()
                .min(
                    MINIMUM_CHARACTER_FOR_OTHERS,
                    `Le nom du distributeur doit contenir au moins ${MINIMUM_CHARACTER_FOR_OTHERS} caractère`
                )
                .optional(),
        })
    },
    handleSubmit: (
        values: EditValuesFs,
        bag: FormikBag<FormProps, EditValuesFs>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureDistributorUpdateForm)

export const FsDialogContent = ({
    setOpen,
}: {
    setOpen: (boolean: boolean) => void
}) => {
    const user = useAppSelector((state) => state.user.user)

    const dispatch = useAppDispatch()
    const _updateUser = useCallback(
        async (company: Partial<CompanyData> & EditValuesFs) =>
            await dispatch(fetchUpdateFsDistributor(company)),
        [dispatch]
    )

    const handleSubmit = async (values: EditValuesFs) => {
        setOpen(false)
        const valuesToSend = {
            distributerFS: [...user.fs.distributer, ...values.distributerFS],
            dataFS: values.dataFS,
            hasOthersFS: hasOthers(values.distributerFS, fsDistributor),
        }
        await _updateUser(valuesToSend)
    }

    return (
        <Grid container direction="column" p="1vw">
            <ControlledUpdateUserForm
                onSubmit={handleSubmit}
                onClose={() => {
                    setOpen(false)
                }}
                user={user}
            />
        </Grid>
    )
}
