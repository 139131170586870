import React, { useCallback, useState } from 'react'
import noData from '../../../assets/no-data-promo.png'
import { Grid, Paper } from '@mui/material'
import { BLACK, GREEN } from '../../ui/Colors'
import { SecondaryText, SubTitle, Title } from '../../ui/Text'
import { PrimaryButton } from '../../ui/Button'
import { useAppDispatch } from '../../../redux/hooks'
import { CLICK, createAskForContactActivity } from '../../../redux/contact/contactSlice'

const NoSellInScreen = () => {
    const dispatch = useAppDispatch()
    const [contactMessage, setContactMessage] = useState(false)
    const _createAskForContactActivity = useCallback(async () => {
        await dispatch(
            createAskForContactActivity(CLICK.PROMO)
        )
    }, [dispatch])
    return (
        <Paper
            sx={{
                width: 'calc(100% - 48px)',
                height: '86vh',
                margin: 'auto',
                marginTop: '1vw',
            }}
        >
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                height={'100%'}
                wrap="nowrap"
            >
                <img alt="Chargement" src={noData} style={{ width: '30vw' }} />
                <Title mt="2vh">Pas de données Sell-in</Title>
                <Grid container flexDirection="row" justifyContent="center">
                    <SubTitle bold mt={1} mb="4vh" color={BLACK} mr="1vw">
                        Vous avez filtré sur une référence sans données
                        disponibles ?
                    </SubTitle>
                    <SubTitle mt={1} mb="4vh" color={BLACK}>
                        Modifiez vos filtres
                    </SubTitle>
                </Grid>
                <Grid container flexDirection="row" justifyContent="center">
                    <SubTitle bold mt={1} mb="1.5vh" color={BLACK} mr="1vw">
                        Si vous n'avez pas encore intégré vos données Sell-in,
                    </SubTitle>
                    <SubTitle mt={1} color={BLACK}>
                        KaryonFood se tient à votre disposition
                    </SubTitle>
                </Grid>
                <PrimaryButton
                    type="submit"
                    onClick={(e) => {
                        _createAskForContactActivity()
                        setContactMessage(true)
                    }}
                >
                    Être contacté.e
                </PrimaryButton>
                {contactMessage && (
                    <SecondaryText color={GREEN} mt={1}>
                        Le message a été envoyé à nos équipes, vous allez être
                        bientôt recontacté.e
                    </SecondaryText>
                )}
            </Grid>
        </Paper>
    )
}

export default NoSellInScreen
