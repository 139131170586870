import React, { useCallback, useEffect, useState } from 'react'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import { fetchDrillDownWarehouses, fetchDrillDownWarehousesNotDistributors } from '../../../redux/fsProducts/fsProductsSlice'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
} from './othersDrillDownComponents'
import { CellTooltip, getFSRows, getWarehousesColumns } from './setupDrillDown'
import Loading from '../screens/LoadingScreen'
import { GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid'
import { PrimaryText } from '../../ui/Text'
import { BLACK } from '../../ui/Colors'


export const ProductsMetroDrillDownContent = ({
    row,
    isFirstVisit,
    value,
    handleChange,
    type,
}: DrillDownContentProps) => {
    const [isLoadingWarehouses, setLoadingWarehouses] = useState(true)
    const [isLoadingWarehousesNotDistributors, setLoadingWarehousesNotDistributors] = useState(true)
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { drillDownWarehouses, drillDownWarehousesNotDistributors } = useAppSelector((state) => state.fsProducts)
    const { unit } = useAppSelector((state) => state.global)

    const warehouseslength = drillDownWarehouses
        ? drillDownWarehouses.length
        : ''
    const warehousesNotDistributorsLength = drillDownWarehousesNotDistributors
        ? drillDownWarehousesNotDistributors.length
        : ''

    const _fetchDrillDownWarehouses = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownWarehouses(filters))
        },
        [dispatch]
    )

    const _fetchDrillDownWarehousesNotDistributors = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownWarehousesNotDistributors(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        min: activeFsFilters.min,
        max: activeFsFilters.max,
        products: [row.id],
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }
    useEffect(() => {
        const fetchDataWarehouses = async () => {
            await _fetchDrillDownWarehouses(filters)
        }

        const fetchDataWarehousesNotDistributors = async () => {
            await _fetchDrillDownWarehousesNotDistributors(filters)
        }
        fetchDataWarehousesNotDistributors().then(() => setLoadingWarehousesNotDistributors(false))

        fetchDataWarehouses().then(() => setLoadingWarehouses(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={['Informations', `Dépôts (${warehouseslength})`, `Dépôts non distributeurs (${warehousesNotDistributorsLength})`,]}
            row={row}
            value={value}
            type={type}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownWarehouses && !isLoadingWarehouses && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownWarehouses)}
                        columns={getWarehousesColumns(type, unit)}
                        message={''}
                    />
                )}
                {isLoadingWarehouses && <Loading text={false} height="54vh" />}

            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {drillDownWarehousesNotDistributors && !isLoadingWarehousesNotDistributors && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={drillDownWarehousesNotDistributors}
                        columns={[
                            {
                                field: 'warehouse',
                                headerName: 'Dépôts',
                                editable: false,
                                flex: 1,
                                renderHeader: (params: GridColumnHeaderParams) => {
                                    return (
                                        <PrimaryText color={BLACK}>
                                            {params.colDef.headerName}
                                        </PrimaryText>
                                    )
                                },
                                renderCell: (params: GridRenderCellParams) => {
                                    return <CellTooltip text={params.value} />
                                },
                            },]}
                        message={`Psssst, ci-dessous la précieuse liste des dépôts non distributeurs de la référence ${row.row.productName}`}
                    />
                )}
                {isLoadingWarehousesNotDistributors && <Loading text={false} height="54vh" />}

            </CustomTabPanel>
        </CommonTabs>
    )
}
