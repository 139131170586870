import React from 'react'
import { Typography } from '@mui/material'

export const ErrorMessageForm = ({
    message,
}: {
    message: string | string[]
}) => {
    return (
        <Typography
            sx={{
                fontFamily: 'Cabin',
                fontWeight: 400,
                fontSize: '0.75rem',
                color: '#d32f2f',
            }}
        >
            {message}
        </Typography>
    )
}
