import React from 'react'
import Filters, {
    FiltersType,
} from '../../../components/features/filters/Filters'
import { useAppSelector } from '../../../redux/hooks'
import PrivatePage from '../../../components/features/GlobalPages/Private/PrivatePage'
import { listOfRetailDistributors } from './types'
import CarrefourDashboard from './Carrefour/dashboard/CarrefourDashboard'
import AuchanDashboard from './Auchan/dashboard/AuchanDashboard'

export const DashboardRetailPage = () => {
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)

    const getRetailDashboardContent = (envName: string) => {
        switch (envName) {
            case listOfRetailDistributors.CARREFOUR:
                return <CarrefourDashboard />
            case listOfRetailDistributors.AUCHAN:
                return <CarrefourDashboard />
            default:
                return <CarrefourDashboard />
        }
    }
    return (
        <PrivatePage title="Tableau de bord" period>
            <Filters
                filtersType={FiltersType.RETAIL}
                filters={activeRetailFilters}
            />
            {getRetailDashboardContent(env.name)}
        </PrivatePage>
    )
}
