import React from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import PrivateHeader from './PrivateHeader'
import { LIGHT_GREY } from '../../../ui/Colors'
import {
    MobileSidebar,
    NAVBAR_PADDING,
    Sidebar,
} from '../../navigation/routing/Sidebar'
import ViewsDialog from '../../views/ViewsDialog'

export const HEADER_HEIGHT = 7
export const NAVBAR = NAVBAR_PADDING * 2
export const MOBILE_HEADER_HEIGHT = 85

interface OwnProps {
    children: JSX.Element | JSX.Element[]
    title?: string
    attribute?: number | null
    period?: boolean
    onViewPage?: boolean
    hasOtherUnitAvailable?: boolean
}

const PrivatePage = ({
    children,
    title,
    attribute,
    period = false,
    hasOtherUnitAvailable = false,
    onViewPage = false,
}: OwnProps) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const contentWidth = isMobile ? '100%' : `calc(88vw - ${NAVBAR}vw)`
    const marginLeft = isMobile ? 0 : `14vw`
    const contentHeight = isMobile
        ? `calc(100% - ${MOBILE_HEADER_HEIGHT}px)`
        : `calc(100% - ${HEADER_HEIGHT}vh)`

    return (
        <Grid
            container
            direction={isMobile ? 'column' : 'row'}
            flexWrap="nowrap"
            sx={{ background: LIGHT_GREY, height: 'auto' }}
        >
            <Grid item>{isMobile ? <MobileSidebar /> : <Sidebar />}</Grid>
            <Grid item sx={{ width: contentWidth, marginLeft: marginLeft }}>
                {title && (
                    <PrivateHeader
                        title={title}
                        attribute={attribute}
                        period={period}
                        hasOtherUnitAvailable={hasOtherUnitAvailable}
                        onViewPage={onViewPage}
                    />
                )}

                <Grid item sx={{ height: contentHeight }}>
                    {children}
                </Grid>
            </Grid>
            <ViewsDialog />
        </Grid>
    )
}

export default PrivatePage
