import { Grid } from '@mui/material'
import React from 'react'
import Activated from '../../../../../../assets/inactivated_carrefour.svg'
import { PrimaryText, SubTitle } from '../../../../../../components/ui/Text'
import { DARK_GREY, GREEN, WHITE } from '../../../../../../components/ui/Colors'
import { DialogFooter } from '../../../../../../components/features/Dialog/BasicFooterDialog'
import { ConfigProps } from '../AutoInactivated'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const CarrefourInactivatedStatus = ({ config }: { config: ConfigProps }) => {
    return (
        <Grid style={{ width: '70vw', height: '100%' }}>
            <Grid container wrap="nowrap" alignItems="center">
                <Grid textAlign="center" padding={5} flex={1}>
                    <Grid>
                        <img
                            alt="une dame près d'un arbre"
                            src={Activated}
                            style={{ width: '18em', height: '15em' }}
                        />
                    </Grid>
                    <PrimaryText>
                        <a
                            rel="noopener noreferrer"
                            target={'_blank'}
                            style={{ color: DARK_GREY, fontSize: '16px' }}
                            href="https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed"
                        >
                            Besoin d'aide ?
                        </a>
                    </PrimaryText>
                </Grid>
                <Grid flex={3} justifyContent="center">
                    <Grid
                        sx={{
                            display: 'flex',
                            background: '#E7EEFB',
                            padding: '1vh',
                            width: '14vw',
                            color: WHITE,
                            alignItems: 'center',
                            textAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            gap: 2,
                        }}
                    >
                        <PrimaryText color="#0C50D3" bold>
                            Connecteur activé !
                        </PrimaryText>
                        <AccessTimeIcon sx={{ color: '#0C50D3' }} />
                    </Grid>
                    <Grid
                        width="60%"
                        textAlign="center"
                        sx={{ marginLeft: 'auto', marginRight: 'auto' }}
                    >
                        <SubTitle marginTop="2vh">
                            Rien à faire de plus !
                        </SubTitle>
                        <SubTitle marginTop="2vh">
                            L’intégration de vos données peut prendre 24h, vous
                            serez notifié lorsque elles seront disponibles.
                        </SubTitle>
                    </Grid>
                </Grid>
            </Grid>
            <DialogFooter
                rightButtonFunc={(e) => {
                    e.stopPropagation()
                    config.goToDashboard()
                }}
                rightButtonText={'Accéder à mon tableau de bord'}
                leftButtonFunc={(e) => {
                    e.stopPropagation()
                    config.handlePrevious()
                }}
                leftButtonText="Retour"
            />
        </Grid>
    )
}

export default CarrefourInactivatedStatus
