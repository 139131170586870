import { Grid } from '@mui/material'
import React from 'react'
import ChartBlock from '../../../../../components/features/ChartBlock'
import LineChart from '../../../../../components/features/foodService/dashboard/LineChart'
import LineChartCard from '../../../../../components/features/foodService/dashboard/LineChartCard'
import { LIGHT_MOCK } from '../../../../../components/ui/Colors'
import { useAppSelector } from '../../../../../redux/hooks'
import { type Products } from '../../../../../redux/user/userTypes'
import { Separator } from '../../../../../components/ui/Separator'

export const UniqueCard = ({ data }: { data: Products }) => {
    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            flex={1.5}
            sx={{ borderRight: `1px solid ${LIGHT_MOCK}` }}
            overflow="hidden"
        >
            <LineChartCard isTop border={false} data={data} />
            <Separator />
        </Grid>
    )
}

export const MultipleCards = ({
    top,
    flop,
}: {
    top: Products
    flop: Products
}) => {
    return (
        <Grid container direction="column" wrap="nowrap" flex={1.5} overflow="hidden">
            <LineChartCard isTop data={top} />
            <Separator />
            <LineChartCard isTop={false} data={flop} />
        </Grid>
    )
}

const FsLineChart = () => {
    const { products } = useAppSelector((state) => state.global.fsDashboardData)
    const { unit } = useAppSelector((state) => state.global)
    const { name } = useAppSelector((state) => state.global.env)

    return (
        <ChartBlock
            title={`Ventes par famille par mois (${unit})`}
            link={`/food-service/${name}/produits`}
        >
            <Grid container wrap="nowrap" height="100%">
                {products.length > 1 ? (
                    <MultipleCards
                        top={products[0]}
                        flop={products[products.length - 1]}
                    />
                ) : (
                    <UniqueCard data={products[0]} />
                )}
                <Grid item flex={3} p="0px 1vw 1vw 1vw">
                    <LineChart />
                </Grid>
            </Grid>
        </ChartBlock>
    )
}

export default FsLineChart
