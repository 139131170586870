import { Env, KaryonModules, UsersRole } from "../user/userTypes"

export interface InvitationState {
    open: boolean
    config: InvitationsConfig
    data: InvitationsData
}

export interface InvitationsConfig {
    steps: InvitationsSteps[]
}

export interface InvitationsData {
    email: string,
    role: UsersRole,
    env: Env[],
    modules: KaryonModules[]
}

export enum InvitationsSteps {
    CONTACTSTEP = "contact-step",
    ENVSTEP = "environnement-step",
    ENDSTEP = "end-step"
}

export interface InvitationResponse {
    result: { success: string } | { error: string }
}
