import { Grid } from '@mui/material'
import React, { useCallback } from 'react'
import {
    ConnectorType,
    Env,
    EnvName,
    EnvStatus,
    EnvType,
} from '../../../../../redux/user/userTypes'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { UpdateUserEnv } from '../../../../../redux/user/userSlice'
import { SubTitle } from '../../../../../components/ui/Text'
import ImportUpload from '../ImportUpload'
import { DialogFooterOneAction } from '../../../../../components/features/Dialog/BasicFooterDialog'
import { useNavigate } from 'react-router-dom'

interface OwnProps {
    distributor: string
    type: EnvType
    connectorType: ConnectorType
}

const ManualInactivated = ({ distributor, type, connectorType }: OwnProps) => {
    const user = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _updateUserEnv = useCallback(
        async (userId: string, env: Env) =>
            await dispatch(UpdateUserEnv(userId, env)),
        [dispatch]
    )
    const navigate = useNavigate()

    return (
        <Grid>
            <Grid
                container
                direction="column"
                flexWrap="nowrap"
                height="100%"
                justifyContent="center"
                alignItems="center"
                mt="3vh"
                mb="3vh"
            >
                <SubTitle mb="5vh">
                    Importez votre premier fichier {distributor} pour créer
                    votre environnement !
                </SubTitle>
                <Grid item sx={{ width: '30vw' }}>
                    <ImportUpload
                        actualDistrib={distributor}
                        actualEnv={type}
                        onUpload={async () =>
                            await _updateUserEnv(user._id, {
                                name: distributor as EnvName,
                                type: type,
                                status: EnvStatus.ACTIVATED,
                                connectorType: connectorType,
                            })
                        }
                    />
                </Grid>
            </Grid>
            <Grid>
                <DialogFooterOneAction
                    text="Tableau de bord"
                    buttonFunc={() => {
                        navigate(`/${type}/${distributor}/dashboard`)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default ManualInactivated
