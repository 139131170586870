import React, { useCallback, useEffect, useState } from 'react'
import { Grid, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import StepperLayout from './StepperLayout'
import {
    StepperData,
    StepperDataConfig,
} from '../../../../../redux/stepper/stepperTypes'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { setData } from '../../../../../redux/stepper/stepperSlice'
import { PrimaryText } from '../../../../../components/ui/Text'
import { BLACK } from '../../../../../components/ui/Colors'
import PublicPage from '../../../../../components/features/GlobalPages/PublicPage'

export enum BackType {
    DEFAULT = 'default',
    HISTORY = 'history',
}

export interface StepContentComponent {
    handleNext: () => void
    getData: () => StepperData
    updateData: (payload: StepperData) => void
    validateData: () => void
}

export interface StepContentConfig {
    component: (props: StepContentComponent) => JSX.Element
    back?: boolean
    backType?: BackType
    backLocalisation?: string
}

export interface StepTimelineConfig {
    labels: string[]
    triggerDisabled?: number
}

interface OwnProps {
    stepsContent: StepContentConfig[]
    stepTimeline: StepTimelineConfig
    stepDataConfig?: StepperDataConfig
}

const AuthLayout = ({
    stepsContent,
    stepTimeline,
    stepDataConfig,
}: OwnProps) => {
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState<number>(0)
    const [stepData, setStepData] = useState<StepperData>(
        stepDataConfig?.initialData || {}
    )
    const [isValid, setIsValid] = useState<boolean>(false)
    const [save, setSave] = useState<Set<number>>(new Set<number>())

    const dispatch = useAppDispatch()
    const _storeStepData = useCallback(
        (data: Record<string, StepperData>) => dispatch(setData(data)),
        [dispatch]
    )

    const { data } = useAppSelector((state) => state.stepper)

    const StepComponentData = stepsContent[activeStep]
    const StepComponent = StepComponentData?.component

    useEffect(() => {
        setSave((prevSave) => {
            const newSave = new Set(prevSave.values())
            newSave.add(activeStep)

            return newSave
        })
    }, [activeStep])

    useEffect(() => {
        const stepKey = stepDataConfig?.key || ''
        if (data[stepKey]) {
            setIsValid(true)
            setStepData(data[stepKey])
        }
    }, [data, stepDataConfig])

    const handleNext = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        if (StepComponentData?.backType === BackType.HISTORY) {
            const location = StepComponentData.backLocalisation || '/'
            navigate(location)
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
        }
    }

    const updateData = (payload: StepperData) => {
        setStepData((prevState: StepperData) => ({ ...prevState, ...payload }))
    }

    const getData = () => {
        return stepData
    }

    const validateData = () => {
        const key = stepDataConfig?.key || ''
        const validData = {} as Record<string, StepperData>
        validData[key] = stepData
        _storeStepData(validData)
    }

    const StepperLine = () => (
        <Grid container justifyContent="center">
            <StepperLayout
                activeStep={activeStep}
                steps={stepTimeline.labels}
                setActiveStep={setActiveStep}
                isValid={isValid}
                triggerDisabled={stepTimeline.triggerDisabled}
                save={save}
            />
        </Grid>
    )

    return (
        <PublicPage staticComponent={<StepperLine />}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                sx={{ margin: 'auto', width: '100%' }}
            >
                {StepComponentData?.back && (
                    <Grid
                        container
                        alignItems="center"
                        sx={{
                            '& svg': { width: { xs: '20px', md: '1.2vw' } },
                            cursor: 'pointer',
                        }}
                        onClick={handleBack}
                    >
                        <IconButton>
                            <ArrowBackIcon />
                        </IconButton>
                        <PrimaryText color={BLACK}>Retour</PrimaryText>
                    </Grid>
                )}
                {StepComponent && (
                    <StepComponent
                        handleNext={handleNext}
                        getData={getData}
                        updateData={updateData}
                        validateData={validateData}
                    />
                )}
            </Grid>
        </PublicPage>
    )
}

export default AuthLayout
