import React, { useState } from 'react'
import { TextField, type TextFieldProps } from 'formik-material-ui'
import { PRIMARY } from './Colors'
import { Field, type FieldAttributes } from 'formik'
import { Box, InputAdornment } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

export const EnhancedField = (props: FieldAttributes<any>) => {
    return (
        <Box
            sx={{
                width: '100%',
                '& div': { width: '100%', height: '100%' },
                '& label': { fontSize: { xs: '16px', md: '1.6vmin' } },
                '& input': {
                    padding: '2vh 1vw',
                    fontSize: { xs: '18px', md: '1.8vmin' },
                    height: '100%',
                },
            }}
        >
            <Field
                style={{
                    height: '6vh',
                    minHeight: '55px',
                    marginTop: '2vh',
                    marginBottom: '3vh',
                }}
                {...props}
            />
        </Box>
    )
}


export const EnhancedFieldLarge = (props: FieldAttributes<any>) => {
    return (
        <Box
            sx={{
                width: '100%',
                '& div': { width: '100%', height: '100%' },
                '& label': { fontSize: { xs: '16px', md: '1.6vmin' } },
                '& input': {
                    padding: '2vh 1vw',
                    fontSize: { xs: '18px', md: '1.8vmin' },
                    height: '100%',
                },
            }}
        >
            <Field
                style={{
                    height: '12vh',
                    minHeight: '55px',
                    marginTop: '2vh',
                    marginBottom: '3vh',
                }}
                {...props}
            />
        </Box>
    )
}

export const TextInput = ({ InputProps, ...attr }: TextFieldProps) => {
    return (
        <TextField
            variant="outlined"
            sx={{
                '& label.Mui-focused': {
                    color: PRIMARY,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: PRIMARY,
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: PRIMARY,
                    },
                },
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{ ...InputProps }}
            {...attr}
        />
    )
}

interface OwnProps {
    name: string
    label: string
}

export const InputPasswordReveal = ({ name, label }: OwnProps) => {
    const [isText, setIsText] = useState<boolean>(false)

    return (
        <Field
            sx={{
                width: '100%',
                '& label': {
                    fontSize: '1.6vmin',
                },
            }}
            margin="normal"
            name={name}
            label={label}
            type={isText ? 'text' : 'password'}
            component={TextInput}
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        position="end"
                        style={{ outline: 'none', cursor: 'pointer' }}
                        onClick={() => setIsText(!isText)}
                    >
                        {isText ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </InputAdornment>
                ),
            }}
        />
    )
}
