import React from 'react'
import { Grid } from '@mui/material'
import { PrimaryText } from '../../../../../components/ui/Text'
import ColorSquare, {
    ColorSquareProps,
} from '../../../../../components/ui/ColorSquare'
import { BLACK } from '../../../../../components/ui/Colors'

export interface OwnProps extends ColorSquareProps {
    label: string
    toggleDataSets?: (e: React.MouseEvent, layer: number) => void
    clickHandler?: () => void
    hide: boolean
    layer?: number
}

const LineChartLabel = ({
    label,
    color,
    size,
    toggleDataSets,
    hide,
    layer,
}: OwnProps) => {
    return (
        <Grid
            container
            alignItems="center"
            style={{ cursor: 'default' }}
            // onClick={(e) => toggleDataSets(e, layer)}
            wrap="nowrap"
        >
            <ColorSquare color={color} size={size} />
            <PrimaryText
                color={BLACK}
                ml="0.5vw"
                textDecoration={hide ? 'line-through' : 'none'}
            >
                {label}
            </PrimaryText>
        </Grid>
    )
}

export default LineChartLabel
