import React, { useCallback } from 'react'
import {
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { DialogContent, Grid } from '@mui/material'
import { object, string } from 'yup'
import {
    type StepConfig,
} from '../../../../redux/views/viewsTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { UsersRole } from '../../../../redux/user/userTypes'
import { PrimaryText } from '../../../../components/ui/Text'
import { BLACK } from '../../../../components/ui/Colors'
import { EnhancedField, TextInput } from '../../../../components/ui/Inputs'
import { DialogFooter } from '../../../../components/features/Dialog/BasicFooterDialog'
import { updateInvitation } from '../../../../redux/invitations/invitationsSlice'
import { RadioButtonsGroup } from '../../../../components/ui/RadioButtonsGroup'
import { roles } from './utilsAdminPage'

interface OwnProps {
    step: number
    config: StepConfig
}

interface FormValues {
    email: string
    role: UsersRole
}

interface FormProps {
    step: number
    config: StepConfig
    initialValues: FormValues
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}



const PureSettingsForm = ({
    config,
    step,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '42vh' }}>
                <Grid container alignItems="center" height="100%"
                >
                    <Grid>

                        <PrimaryText color={BLACK} >Renseignez l'adresse email du collaborateur à inviter</PrimaryText>
                        <EnhancedField
                            type="email"
                            name="email"
                            margin="normal"
                            component={TextInput}
                            placeholder="Email"
                        />
                    </Grid>
                    <Grid>
                        <RadioButtonsGroup
                            onChange={(event, value) =>
                                props.setFieldValue('role', value)
                            }
                            values={roles}
                            label={`Rôle de l'utilisateur`}
                            defaultCheck={UsersRole.USER}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={() => {
                    config.handleReset()
                    config.handleClose()
                }
                }
                leftButtonText={'ANNULER'}
                rightButtonFunc={async () => {
                    await props.submitForm()
                }}
                rightButtonText={
                    'SUIVANT'
                }
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        email: props.initialValues.email,
        role: props.initialValues.role,
    }),
    validationSchema: () => {
        return object().shape({
            email: string().email("Merci de saisir une adresse email valide").required(`L'email est un champ obligatoire`),
            role: string()
                .oneOf(Object.values(UsersRole))
                .required('Le choix du type de vue est obligatoire'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const InvitationUserStep = ({ step, config }: OwnProps) => {
    const dispatch = useAppDispatch()

    const { data } = useAppSelector((state) => state.invitations)
    const _updateInvitation = useCallback(({ email, role }: { email: string, role: UsersRole }) => dispatch(updateInvitation({ data: { email, role } })), [dispatch])

    const handleSubmit = (values: FormValues) => {
        _updateInvitation({
            email: values.email,
            role: values.role,

        })
        config.handleNext()

    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            initialValues={{
                email: data.email,
                role: data.role,
            }}
        />
    )
}

export default InvitationUserStep
