import React from "react"
import { Box, Dialog, Grid, DialogContent } from "@mui/material"
import { DialogHeader } from "../Dialog/BasicDialog"
import { DialogFooterOneAction } from "../Dialog/BasicFooterDialog"
import { BLACK, MEDIUM_GREY } from "../../ui/Colors"
import Illustration from "../../../assets/kitNegoIllustration.png"
import { PrimaryText, SubTitle } from "../../ui/Text"


export const NegoKitDialog = ({
    isOpen,
    setOpen
}: {
    setOpen: (open: boolean) => void
    isOpen: boolean
}) => {

    const handleDownload = () => {
        //Download
        const link = document.createElement('a');
        link.href = "https://8639535.fs1.hubspotusercontent-na1.net/hubfs/8639535/Guides et e-books/KaryonFood - Kit  Négociations Commerciales.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        //Close Dialog
        setOpen(false)
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            onClose={() => {
                setOpen(false)
            }}
            open={isOpen}
            PaperProps={{
                sx: { borderRadius: '1em', minWidth: '55vw', padding: '1em' },
            }}
        >
            <DialogHeader title="KaryonFood, l’allié de vos négociations 2025 !" setOpen={() => setOpen(false)} xl />
            <DialogContent sx={{ borderBottom: `solid ${MEDIUM_GREY} 1px` }}>
                <Grid container flexWrap="nowrap" alignItems="center" justifyContent="center">
                    <Box
                        sx={{ width: "70%" }}
                        component="img"
                        src={Illustration}
                        alt="Logo Karyon"
                    />
                    <Grid ml="2vw" >
                        <SubTitle bold mb="8vh">Grâce à ce kit, découvrez comment vos données sell-out peuvent vous permettre
                            d’appuyer vos négociations commerciales avec vos distributeurs.</SubTitle>
                        <PrimaryText color={BLACK} >
                            A l’intérieur : <br></br><br></br>
                            - Le petit guide des données disponibles, qui vous permet d’avoir un aperçu des types de données disponibles sur le marché en RHD par distributeur ; <br></br><br></br>
                            - Le livre blanc : « La donnée sell-out réconciliatrice des négociations commerciales »,
                            qui réunit tous les éléments nécessaires au bon déroulé de vos échanges pendant cette période cruciale ;<br></br><br></br>
                            - Des slides PowerPoint que vous n’aurez qu’à insérer en deux clics dans vos présentations pour négocier l’accès à vos données sortie d’entrepôt.
                            <br></br><br></br>
                            Bonne lecture !
                        </PrimaryText>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogFooterOneAction buttonFunc={handleDownload}
                text="Télécharger le kit"
            />
        </Dialog>
    )
}
