import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LineChartLabel from './LineChartLabel'
import { useAppSelector } from '../../../../../redux/hooks'
import { sortTwoArrSameWay } from '../../../../../helpers/sort'
import { SquareColor } from '../../../../../components/ui/ColorSquare'
import { TreeView } from '@mui/x-tree-view'
import { formateLegend } from '../../../../../components/features/foodService/dashboard/utils/formateProductsData'
import { Units } from '../../../../../redux/global/globalTypes'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export const backgroundColor = [
    '#3A4D87',
    '#B9C7F9',
    '#EF9BFD',
    '#F7EAA6',
    '#F7B0A6',
    '#FACE5D',
    '#A17D5B',
]

export const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: (model: any, tooltipItem: any) => {
                    return `${model.dataset.label} : ${model.raw} ${Units.uvc}`
                },
            },
        },
    },
}

export const LineChartProduct = () => {
    const { chart } = useAppSelector((state) => state.retailProducts.products)
    const chartRef = React.useRef()

    const datasets = chart.chart.map((product, index) => {
        const sortedData = sortTwoArrSameWay(product.labels, product.data, true)

        const color = backgroundColor[index % 7]

        return {
            label: chart.legend[index].name,
            data: [null, ...sortedData.second, null],
            borderColor: color,
            backgroundColor: color,
        }
    })

    const sortedLabels = sortTwoArrSameWay(
        chart.chart[0].labels,
        chart.chart[0].data,
        true
    )

    const formatedLegend = formateLegend(chart.timeline)

    const data = {
        labels: [null, ...formatedLegend, null],
        datasets: datasets,
    }

    return (
        <Grid container wrap="nowrap" alignItems="flex-start">
            <Grid
                sx={{
                    height: '34vh',
                    width: '40vh',
                    padding: '1vw',
                }}
                flex={2}
            >
                <Line ref={chartRef} options={options as any} data={data} />
            </Grid>
            <Grid
                flex={1}
                pt="1vw"
                pb="1vw"
                sx={{
                    height: '34vh',
                    '& .MuiTreeItem-label': { fontSize: '1.6vmin !important' },
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {chart.legend.map((product, index) => {
                    const color = backgroundColor[index % 7]

                    return (
                        <TreeView
                            key={index}
                            aria-label="data"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{
                                maxHeight: '100%',
                            }}
                        >
                            <LineChartLabel
                                label={product.name}
                                color={color}
                                size={SquareColor.SM}
                            />
                        </TreeView>
                    )
                })}
            </Grid>
        </Grid>
    )
}
