import React from "react"
import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { BLACK } from "./Colors"
import { PrimaryText } from "./Text"
import { checkboxCSS } from "../features/filters/selects/FilterComponent"

export const CheckboxList = ({ titleList, list, handleChange }: { titleList: string, list: string[], handleChange: any }) => {

    return (
        <Grid item flex={1}>
            <PrimaryText color={BLACK} >{titleList}</PrimaryText>
            <Grid container alignItems="flex-start" direction="column">
                {list.map((input, index) => {
                    return (
                        <Grid>
                            <FormControlLabel

                                control={
                                    <Checkbox
                                        onChange={() =>
                                            handleChange(input)
                                        }

                                        sx={checkboxCSS}
                                    />
                                }
                                label={input}
                            />
                        </Grid>
                    )
                })}
            </Grid>

        </Grid>)
}