import React from 'react'
import { useAppSelector } from '../../../../redux/hooks'
import {
    ConnectorType,
    EnvStatus,
    EnvType,
} from '../../../../redux/user/userTypes'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Import from './Import'

const ImportRetail = () => {
    const { user } = useAppSelector((state) => state.user)
    const { RetailHistoric } = useAppSelector((state) => state.imports)
    let list = []

    if (user.fs) {
        const enhancedDistributor = user.retail.distributer
            .filter((distrib) => !user.env.some((env) => env.name === distrib))
            .map((distrib) => {
                return {
                    name: distrib,
                    type: EnvType.FOOD_SERVICE,
                    status: EnvStatus.INACTIVATED,
                    connectorType: ConnectorType.MANUAL,
                }
            })
        const activeDistributor = user.env.filter(
            (env) => env.type === EnvType.RETAIl
        )
        list = [...activeDistributor, ...enhancedDistributor]
    }

    return (
        <PrivatePage title="Importer des données">
            <Import
                type={EnvType.RETAIl}
                envList={list}
                historicImports={RetailHistoric}
            />
        </PrivatePage>
    )
}

export default ImportRetail
