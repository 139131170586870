import React from 'react'
import { Grid } from '@mui/material'
import { BLACK } from '../ui/Colors'
import NumberWithSpace from '../ui/NumberWithSpace'
import { PrimaryText, SubTitle } from '../ui/Text'
import Tendency from '../ui/Tendency'

export interface KpiCard {
    name: string
    number: number
    units?: string
    difference?: number
    route?: string
    icon?: any
    evolution?: number
}


export const KpiCard = ({
    name,
    number,
    units,
    difference,
    icon,
    evolution
}: KpiCard) => {

    return (

        <Grid width="100%">
            <Grid container justifyContent="space-between" mb="2vh">
                <PrimaryText bold color={BLACK} gutterBottom mb="1vh">
                    {name}
                </PrimaryText>
                {icon ? icon : <></>}
            </Grid>
            <Grid
                container
                alignItems="flex-bottom"
                justifyContent="space-between"
                wrap="nowrap"
            >
                <Grid item>
                    <Grid container alignItems="baseline">
                        <SubTitle color={BLACK}>
                            <NumberWithSpace number={number} />
                            {units}
                        </SubTitle>
                    </Grid>
                </Grid>
                {difference != undefined && (
                    <Grid item>
                        <Grid
                            container
                            alignItems="flex-end"
                        >
                            <Tendency
                                kpi
                                difference={difference}
                                unit={units}
                                evolution={evolution}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
