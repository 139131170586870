import React from 'react'
import { Button, type ButtonProps } from '@mui/material'
import {
    BLACK,
    DARK_BLUE,
    DARK_GREY,
    GREY,
    LIGHT_GREY,
    PRIMARY,
    WHITE,
} from './Colors'
import { Link, type LinkProps } from 'react-router-dom'
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const buttonTextStyle = {
    fontWeight: 700,
    fontSize: { xs: '16px', md: '1.5vmin' },
    color: WHITE,
}

export const PrimaryButton = (props: ButtonProps) => {
    return (
        <Button
            sx={{
                ...buttonTextStyle,
                background: props.disabled ? LIGHT_GREY : PRIMARY,
                '&:hover': { background: DARK_BLUE, color: LIGHT_GREY },
            }}
            {...props}
        >
            {props.children}
        </Button>
    )
}

export const SecondaryButton = (props: ButtonProps) => (
    <Button
        sx={{
            ...buttonTextStyle,
            background: GREY,
            '&:hover': { background: DARK_GREY },
        }}
        {...props}
    >
        {props.children}
    </Button>
)

export const TertiaryButton = (props: ButtonProps) => (
    <Button sx={{
        color: BLACK,
        background: WHITE,
        width: "100%",
        fontSize: { xs: '16px', md: '1.5vmin' },
        '&:hover': { background: LIGHT_GREY },
        textDecoration: "none",
        borderRadius: 50,
        textTransform: "none",
        fontWeight: 700,
        pt: "2vh",
        pb: "2vh",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
    }}
        {...props}>{props.children}<ArrowForwardIcon /></Button>
)

export const AltButton = (props: ButtonProps) => (
    <Button
        sx={{
            ...buttonTextStyle,
            color: PRIMARY,
            background: WHITE,
            border: `1px solid ${PRIMARY}`,
            '&:hover': { border: `1px solid ${DARK_BLUE}` },
        }}
        {...props}
    >
        {props.children}
    </Button>
)

export const AltButtonSpecial = (props: ButtonProps) => (
    <Button
        sx={{
            color: PRIMARY,
            background: WHITE,
            border: `1px solid ${PRIMARY}`,
            '&:hover': { border: `1px solid ` },
            textTransform: "none",
            textDecoration: "underline",
            padding: '1vh 1vw',
            borderRadius: "12.7px",
            fontSize: { xs: '16px', md: '1.5vmin' },
        }}
        {...props}
    >
        <StarIcon sx={{ color: "#FFD233" }} />
        {props.children}
    </Button>
)

export const LinkButton = (props: LinkProps & ButtonProps) => (
    <Link to={props.to}>
        <Button
            sx={{ fontSize: { xs: '14px', md: '1.3vmin' }, color: DARK_GREY }}
            {...props}
        >
            {props.children}
        </Button>
    </Link>
)
