import * as React from 'react'
import Box from '@mui/material/Box'
import {
    DataGrid,
    type GridColumnHeaderParams,
    type GridRenderCellParams,
} from '@mui/x-data-grid'
import { useAppSelector } from '../../../../../redux/hooks'
import { Grid, Tooltip } from '@mui/material'
import { PrimaryText, SecondaryText } from '../../../../../components/ui/Text'
import { BLACK } from '../../../../../components/ui/Colors'
import NumberWithSpace from '../../../../../components/ui/NumberWithSpace'
import { roundIfNotZero } from '../../../../../helpers/roundIfNotZero'

const columns = [
    {
        field: 'region',
        headerName: 'Région',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText
                    color={BLACK}
                    fontStyle={params.value ? null : 'italic'}
                >
                    {params.value ? params.value : 'Non renseignée'}
                </SecondaryText>
            )
        },
    },
    {
        field: 'stores',
        headerName: 'Nombre de PDV',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Tooltip title="Nombre de PDV">
                    <Box>
                        <PrimaryText color={BLACK}>
                            {params.colDef.headerName}
                        </PrimaryText>
                    </Box>
                </Tooltip>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return params.value ? (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={Math.round(params.value)} />
                </SecondaryText>
            ) : null
        },
    },
    {
        field: 'sells',
        headerName: '% ventes (UVC)',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            if (!params.value)
                return <SecondaryText color={BLACK}>-</SecondaryText>

            return (
                <Grid item sx={{ width: '4vw' }}>
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                        %
                    </SecondaryText>
                </Grid>
            )
        },
    },
]

export default function RetailDataGridDashboard() {
    const { stores } = useAppSelector(
        (state) => state.global.retailDashboardData
    )
    const { sells: totalSells } = useAppSelector(
        (state) => state.global.retailDashboardData.kpis
    )

    /*  // Put unknown region as last elem
    const unknownRegions = stores.filter((el) => el.region === 'Non renseigné')
    const knownRegions = stores.filter((el) => el.region !== 'Non renseigné')
    const filteredStores = [...knownRegions, ...unknownRegions] */

    const enhancedStores = stores.map((store) => {
        return {
            ...store,
            sells: (store.values / totalSells) * 100,
        }
    })

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                '& .MuiDataGrid-columnHeadersInner': { height: '100%' },
                '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': { height: '100%' },
                '& .MuiDataGrid-columnHeader': {
                    padding: '0 1vw',
                    height: '100% !important',
                },
                '& .MuiDataGrid-row': {
                    height: '3.5vh',
                },
                '& .MuiDataGrid-cell--withRenderer': {
                    height: '3.5vh',
                },
                '& div.MuiDataGrid-columnHeader:nth-child(1) > div:nth-child(1) > div:nth-child(1)':
                {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft:first-child': {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft': {
                    justifyContent: 'center !important',
                },
            }}
        >
            <DataGrid
                rows={enhancedStores}
                columns={columns}
                experimentalFeatures={{ newEditingApi: false }}
                getRowId={(row) => row._id}
                hideFooter={true}
                disableColumnMenu={true}
            />
        </Box>
    )
}
