import React from 'react'
import { DialogContent, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import { object, string } from 'yup'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import { PrimaryText } from '../../ui/Text'
import { BLACK } from '../../ui/Colors'
import { StepperData } from '../../../redux/stepper/stepperTypes'
import { ExportComponentProps, StepExportConfig } from './ExportSetup'
import { useAppSelector } from '../../../redux/hooks'
import { EnvType } from '../../../redux/user/userTypes'
import { TypeOfItem } from '../utils/OptionsChoicesBox'
import { OptionsChoiceComponent } from '../utils/OptionsChoices'
import ErrorMessage from '../../ui/ErrorMessage'
//Icons
import retailIcon from '../../../assets/icon/retailIcon.svg'
import fsIcon from '../../../assets/icon/fsIcon.svg'

interface FormValues {
    environment: string
}

interface FormProps {
    initialValues: { environment: string }
    config: StepExportConfig
    step: number
    envType: string
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

const EnvChoice: TypeOfItem[] = [
    {
        id: 1,
        label: 'Foodservice',
        icon: <img src={fsIcon} />,
        type: EnvType.FOOD_SERVICE,
        locked: false,
    },
    {
        id: 2,
        label: `Retail`,
        icon: <img src={retailIcon} />,
        type: EnvType.RETAIl,
        locked: false,
    },
]

const PureEnvChoiceForm = ({
    config,
    step,
    envType,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    return (
        <Form>
            <DialogContent sx={{ height: '32vh' }}>
                <Grid container direction="column">
                    <PrimaryText color={BLACK} style={{ marginBottom: '3vh' }}>
                        Quel type de données souhaitez-vous exporter ?
                    </PrimaryText>
                    <OptionsChoiceComponent
                        listOfChoice={EnvChoice}
                        setValue={props.setFieldValue}
                        name="environment"
                    />
                </Grid>
                {props.submitCount > 0 && props.errors.environment && (
                    <Grid item mt="1vh" display="flex" justifyContent="center">
                        <ErrorMessage
                            msg={props.errors.environment as string}
                        />
                    </Grid>
                )}
            </DialogContent>
            <DialogFooter
                leftButtonFunc={(e) => {
                    config.handleClose()
                }}
                leftButtonText={'ANNULER'}
                rightButtonFunc={(e) => {
                    e.stopPropagation()
                    props.submitForm()
                }}
                rightButtonText={'SUIVANT'}
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        environment: props.initialValues.environment,
    }),
    validationSchema: () => {
        return object().shape({
            environment: string()
                .oneOf(Object.values(EnvType))
                .required(
                    'Sélectionnez le canal de distribution qui vous intéresse'
                ),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureEnvChoiceForm)

const MultiExportEnvChoiceStep = ({
    step,
    config,
    env,
}: ExportComponentProps) => {
    const { data } = useAppSelector((state) => state.exports)

    const handleSubmit = (values: FormValues) => {
        config.updateData(values as unknown as StepperData)
        config.handleNext()
    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            envType={env.type}
            initialValues={{
                environment: data.environment,
            }}
        />
    )
}

export default MultiExportEnvChoiceStep
