import React from 'react'
import InventoryIcon from '@mui/icons-material/Inventory'
import RoomIcon from '@mui/icons-material/Room'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import {
    ActiveFsFilters,
    ActiveRetailFilters,
    FsViewFilters,
    RetailViewFilters,
} from '../../../redux/filters/filtersTypes'
import { useAppSelector } from '../../../redux/hooks'
import { EnvName } from '../../../redux/user/userTypes'

export enum MenuType {
    PRODUCTS = 'products',
    FAMILY = 'family',
    CLIENTS = 'clients',
    REGION = 'regions',
    STORES = 'stores',
    CATEGORIES = 'categories',
    DISTRIBUTORS = 'distributors',
    DISTRIBUTIONSCHANNELS = 'distributionChannels',
}
export interface FiltersItem {
    id: number
    name: string
    icon: JSX.Element
    placeholder: string
    type: MenuType
    locked?: boolean
}

const filtersConfig = (distrib: string): FiltersItem[] => {
    return [
        {
            id: 0,
            name: 'products',
            icon: <InventoryIcon />,
            placeholder: 'Références',
            type: MenuType.PRODUCTS,
        },
        {
            id: 1,
            name: 'regions',
            icon: <RoomIcon />,
            placeholder:
                distrib === EnvName.FOOD_SERVICE_CROSS ? "Régions" : 'Dépôts',
            type: MenuType.REGION,
        },
        {
            id: 2,
            name: 'clients',
            icon: <EmojiPeopleIcon />,
            placeholder:
                distrib === EnvName.FRANCE_FRAIS
                    ? 'Types de clients'
                    : "Types d'utilisateurs",
            type: MenuType.CLIENTS,
        },
        {
            id: 1,
            name: 'stores',
            icon: <RoomIcon />,
            placeholder: 'Points de vente',
            type: MenuType.STORES,
        },
        {
            id: 1,
            name: 'categories',
            icon: <EmojiPeopleIcon />,
            placeholder:
                distrib === EnvName.SODEXO
                    ? 'Entités'
                    : 'Marché / Chaînage client',
            type: MenuType.CATEGORIES,
        },
        {
            id: 1,
            name: 'distributors',
            icon: <EmojiPeopleIcon />,
            placeholder: 'Distributeurs',
            type: MenuType.DISTRIBUTORS,
        },
        {
            id: 1,
            name: 'distributionChannels',
            icon: <EmojiPeopleIcon />,
            placeholder: 'Circuits de distribution',
            type: MenuType.DISTRIBUTIONSCHANNELS,
        },
    ]
}

export const getFiltersConfig = (keys: string[], distrib: string) => {
    const config = keys.map((key) =>
        filtersConfig(distrib).filter((filter) => key === filter.name)
    )

    return config.flat()
}

export const isViewFiltered = (
    viewFilters: FsViewFilters | RetailViewFilters,
    selectedViewFSValues: ActiveFsFilters | ActiveRetailFilters
) => {
    const keys = Object.keys(viewFilters)

    const isFiltered = keys.some((key) =>
        isArraysEqual(viewFilters[key], selectedViewFSValues[key])
    )

    return isFiltered
}

const isArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
        return true
    }

    for (let element of array1) {
        if (!array2.includes(element)) {
            return true
        }
    }

    for (let element of array2) {
        if (!array1.includes(element)) {
            return true
        }
    }

    return false
}
