import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StepperData, StepperState } from "./stepperTypes";

const initialState: StepperState = {
  data: {},
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setData(state, action: PayloadAction<{ [key: string]: StepperData }>) {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { setData } = stepperSlice.actions;
export default stepperSlice.reducer;
