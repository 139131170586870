import React, { useState, useCallback, useEffect } from 'react'
import { Grid } from '@mui/material'
import ImportHistoric from './ImportHistoric'
import ContactDialog from './dialog/contact/ContactDialog'
import { fetchImports } from '../../../../redux/imports/importsSlice'
import { distributorImages } from '../../../../helpers/distributorsList'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { setGlobalEnv } from '../../../../redux/global/globalSlice'
import { useNavigate } from 'react-router-dom'
import DisplayDistributor from '../../DisplayDistributor'
import {
    ConnectorType,
    Env,
    EnvName,
    EnvStatus,
    EnvType,
} from '../../../../redux/user/userTypes'
import AddDistributorDialog from '../../../../components/features/addDistributor/AddDistributorDialog'
import ImportDialog from '../../../../components/features/import/ImportDialog'
import { Historic, LastImport } from '../../../../redux/imports/importsTypes'
import { UpdateUserEnv } from '../../../../redux/user/userSlice'

interface OwnProps {
    envList: Env[]
    type: EnvType.RETAIl | EnvType.FOOD_SERVICE
    historicImports: Historic
}

export interface DialogData {
    isOpen: boolean
    envName: EnvName
    status: EnvStatus
    connectorType?: ConnectorType
}

const resetState = {
    isOpen: false,
    envName: EnvName.MYDISTRIB,
    status: EnvStatus.INACTIVATED,
}

const Import = ({ envList, type, historicImports }: OwnProps) => {
    const navigate = useNavigate()
    const { plan } = useAppSelector((state) => state.companies.company)
    const [dialogData, setDialogData] = useState<DialogData>(resetState)
    const [dialogDataAdd, setDialogDataAdd] = useState<DialogData>(resetState)
    const [dialogContactData, setDialogContactData] =
        useState<DialogData>(resetState)
    const dispatch = useAppDispatch()
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )
    const _getImports = useCallback(
        async (type: EnvType.FOOD_SERVICE | EnvType.RETAIl) => {
            await dispatch(fetchImports(type))
        },
        [dispatch]
    )

    const { _id: userId } = useAppSelector((state) => state.user.user)
    useEffect(() => {
        const getHistory = async (
            type: EnvType.FOOD_SERVICE | EnvType.RETAIl
        ) => {
            _getImports(type)
        }

        if (userId) {
            getHistory(type)
        }
    }, [_getImports, type, userId])

    const _updateUserEnv = useCallback(
        async (userId: string, env: Env) =>
            await dispatch(UpdateUserEnv(userId, env)),
        [dispatch]
    )
    const goToDashboard = () => {
        _updateUserEnv(userId, {
            name: dialogData.envName,
            type: type,
            status: EnvStatus.ACTIVATED,
            connectorType: dialogData.connectorType,
        }).then(() => {
            _setEnv({
                type,
                name: dialogData.envName,
                status: dialogData.status,
            })
            navigate(`/${type}/${dialogData.envName}/dashboard`)
        })
    }

    const getDistribLastImport = (
        allLastImport: LastImport[],
        distrib: string
    ) => {
        const lastImport = allLastImport.filter(
            (element) => element.distributorName === distrib
        )
        return lastImport.length > 0 ? lastImport[0].created_at : ''
    }

    return (
        <Grid container direction="column" wrap="nowrap" p="1vw">
            <Grid
                container
                wrap="nowrap"
                justifyContent="flex-start"
                minHeight="55%"
                mb="1vw"
            >
                <Grid item width="100%" height="100%">
                    <DisplayDistributor
                        handleClickAdd={(distrib) => {
                            setDialogDataAdd({
                                isOpen: true,
                                envName: distrib,
                                status: EnvStatus.INACTIVATED,
                            })
                        }}
                        handleClick={
                            plan === 'plan 2' //user have multidistributors
                                ? (distrib) =>
                                      setDialogContactData({
                                          isOpen: true,
                                          envName: distrib.name,
                                          status: distrib.status,
                                      })
                                : (distrib: Env) => {
                                      setDialogData({
                                          isOpen: true,
                                          envName: distrib.name,
                                          status: distrib.status,
                                          connectorType: distrib.connectorType,
                                      })
                                  }
                        }
                        envList={envList}
                        lastImports={historicImports.lastImport}
                    />
                </Grid>
            </Grid>
            <Grid item height="45vh">
                <ImportHistoric list={historicImports.list} />
            </Grid>
            <ContactDialog
                isOpen={dialogContactData.isOpen}
                handleClose={() => {
                    setDialogContactData(resetState)
                }}
            />
            <ImportDialog
                img={distributorImages[dialogData.envName]}
                dialog={dialogData}
                type={type}
                connectorType={dialogData.connectorType}
                goToDashboard={goToDashboard}
                handleClose={() => {
                    setDialogData({
                        ...resetState,
                        connectorType: dialogData.connectorType,
                    })
                }}
                lastImportDate={getDistribLastImport(
                    historicImports.lastImport,
                    dialogData.envName
                )}
            />
            <AddDistributorDialog
                type={type}
                handleClose={() => {
                    setDialogDataAdd(resetState)
                }}
                isOpen={dialogDataAdd.isOpen}
            />
        </Grid>
    )
}
export default Import
