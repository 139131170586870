import React from 'react'
import {
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { DialogContent, Grid } from '@mui/material'
import {
    type StepConfig,
} from '../../../../redux/views/viewsTypes'
import { PrimaryText } from '../../../../components/ui/Text'
import { BLACK } from '../../../../components/ui/Colors'
import { DialogFooterOneAction } from '../../../../components/features/Dialog/BasicFooterDialog'
import Illustration from '../../../../assets/invitations.svg'
import { PrimaryButton } from '../../../../components/ui/Button'

interface OwnProps {
    step: number
    config: StepConfig
}

interface FormValues { }

interface FormProps {
    step: number
    config: StepConfig
}

const PureSettingsForm = ({
    config,
    step,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '42vh' }}>
                <Grid container direction="row" alignItems="center" height="100%">
                    <Grid item flex={1}>
                        <img
                            src={Illustration}
                            alt="Illustration"

                        />
                    </Grid>
                    <Grid container direction="column" flex={1} justifyContent="center">
                        <PrimaryText color={BLACK} mb="3vh" mt="3vh" bold>Un email d'invitation a été envoyé à votre collaborateur</PrimaryText>
                        <PrimaryButton onClick={config.handleReset}>Inviter un autre collaborateur ?</PrimaryButton>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogFooterOneAction
                buttonFunc={() => {
                    config.handleClose()
                }}
                text="Fermer"
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {

    },
})(PureSettingsForm)

const InvitationEndStep = ({ step, config }: OwnProps) => {

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
        />
    )
}

export default InvitationEndStep
