import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import { Navigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { FsViewFilters } from '../../../../redux/filters/filtersTypes'
import { DashboardFSPage } from '../Pages/DashboardFSPage'
import { KPIsEpiSaveursConfig } from './EpiSaveursDashboard'
import { EpiSaveursAndPassionFroidColumnsDashboard } from '../utils'
import { ViewsLayout } from '../layout/ViewsLayout'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import Loading from '../../../../components/features/screens/LoadingScreen'

export const ViewPageEpiSaveurs = () => {
    const { viewId } = useParams()
    const { views } = useAppSelector((state) => state.views)
    const view = views.length > 0 ? views.find((view) => view._id === viewId) : null
    const { unit } = useAppSelector((state) => state.global)
    const filters = view ? view.filters as FsViewFilters : null

    if (views.length === 0) {
        return <PrivatePage><Loading /></PrivatePage>
    }

    if (!view) {
        return <Navigate to="/" />
    } else {
        return (
            <PrivatePage title={view.name} period onViewPage>
                <Filters filtersType={FiltersType.VIEWSFS} filters={filters} />
                <ViewsLayout view={view}>
                    <DashboardFSPage
                        KPIsConfig={KPIsEpiSaveursConfig}
                        warehousesConfig={EpiSaveursAndPassionFroidColumnsDashboard(
                            unit
                        )}
                        drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSES}
                        drilldownTypeClientSegmentation={DrillDownType.CLIENTS}
                        dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
                        segmentationChartTitle={`Segmentation utilisateurs par volume (${unit})`}
                    />
                </ViewsLayout>
            </PrivatePage>
        )
    }
}
