import { EnvType } from '../user/userTypes'

export interface InitialState {
    config: { steps: ExportSteps[] }
    data: FormatedExportData
    retailExportFilters: RetailExportFilters
    fsExportFilters: FsExportFilters
    activeFsExportFilters: ActiveFsExportFilters
    activeRetailExportFilters: ActiveRetailExportFilters
    listExport: ListExport
}

export enum ExportSteps {
    EnvChoice = 'env-export',
    TypeOfExport = 'type-export',
    FiltersFS = 'filters-fs',
    FiltersRetail = 'filters-retail',
    FiltersMulti = 'filters-multi',
    reasonOfExport = 'export-reason',
}

export enum ExportTypes {
    EXCEL = 'raw-data',
    PRESENTATION = 'presentation-ppt',
}

export interface ExportData {
    type: ExportTypes
    distributors: string[]
    startDate: Date
    endDate: Date
    products: string[]
    regions: string[]
    clients?: string[]
    family?: string[]
    stores?: string[]
    warehouses?: string[]
    reason: string
    environment: EnvType
    multiOrMono: MultiOrMono
    filename?: string[]
}

//Filters
export interface FsExportFilters {
    products: { name: string; id: string }[]
    clients: { name: string; id: string }[]
    regions: { name: string; id: string }[]
}

export interface RetailExportFilters {
    products: { name: string; id: string }[]
    regions: { name: string; id: string }[]
}

export interface ActiveFsExportFilters {
    startDate: string
    endDate: string
    products: string[]
    regions: string[]
    clients: string[]
}

export interface ActiveRetailExportFilters {
    startDate: string
    endDate: string
    products: string[]
    regions: string[]
}

export interface UserFormatedExportData extends FormatedExportData {
    userId: string
}
export interface FormatedExportData {
    type: ExportTypes
    distributors: string[]
    filters: {
        startDate: string
        endDate: string
        products: string[]
        regions: string[]
        clients: string[]
        family: string[]
        stores: string[]
        warehouses: string[]
    }
    reason: string
    environment: EnvType
    multiOrMono: MultiOrMono
}

export enum MultiOrMono {
    MULTI = 'multi',
    MONO = 'mono',
}

export interface ExportDataResponse {
    exportedData: {
        list: ResponseExportFs | ResponseExportRetail
    }
    brand?: string
}

interface ResponseExportFs {
    Mois: string
    Distributeur: string
    'Code produit': '$productId'
    'Désignation produit': string
    "Type d'utilisateurs finaux": string
    "Sous-type d'utilisateurs finaux": string
    "Sous-sous-type d'utilisateurs finaux": string
    Marque: string
    Familles: string
    'Sous-Familles': string
    Dépôts: string
    'Volume en kg': string
    'Quantité en UVC': string
}

interface ResponseExportRetail {
    Mois: string
    Distributeurs: string
    'Désignation produit': string
    Ean: '$productId'
    Magasins: string
    'Quantité en UVC': string
}

export type ListExport = {
    id: string
    filename: string
    period: string
}[]
