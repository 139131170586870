import React, { useCallback } from 'react'
import {
    Field,
    type FieldAttributes,
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { DialogContent, Grid } from '@mui/material'
import { object, string } from 'yup'
import {
    type StepConfig,
    ViewTypes,
    ViewCreationMode,
} from '../../../../redux/views/viewsTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { TextInput } from '../../../ui/Inputs'
import { updateData } from '../../../../redux/views/viewsSlice'
import { DialogFooter } from '../../Dialog/BasicFooterDialog'
import { PrimaryText } from '../../../ui/Text'
import { type EnvType } from '../../../../redux/user/userTypes'

// Icons
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import LockIcon from '@mui/icons-material/Lock'
import { OptionsChoiceComponent } from '../../utils/OptionsChoices'
import { TypeOfItem } from '../../utils/OptionsChoicesBox'
import { BLACK } from '../../../ui/Colors'

interface OwnProps {
    step: number
    config: StepConfig
}

interface FormValues {
    name: string
    type: ViewTypes
}

interface FormProps {
    step: number
    config: StepConfig
    initialValues: FormValues
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

export const typesOfView: TypeOfItem[] = [
    {
        id: 1,
        label: 'Tableau de bord',
        icon: (
            <DashboardOutlinedIcon
                style={{ width: '2.5vw', height: '2.5vh' }}
            />
        ),
        type: ViewTypes.Dashboard,
        locked: false,
    },
    {
        id: 2,
        label: 'Préparation rendez-vous dépôt',
        icon: (
            <LockIcon
                color="disabled"
                style={{ width: '2.5vw', height: '2.5vh' }}
            />
        ),
        type: ViewTypes.Map,
        locked: true,
        tooltip: "Vous ne pouvez pas choisir ce type pour l'instant",
    },
    {
        id: 3,
        label: 'Suivi rendez-vous dépôt',
        icon: (
            <LockIcon
                color="disabled"
                style={{ width: '2.5vw', height: '2.5vh' }}
            />
        ),
        type: ViewTypes.ListProduct,
        locked: true,
        tooltip: "Vous ne pouvez pas choisir ce type pour l'instant",
    },
    {
        id: 4,
        label: 'Suivi lancement de produit',
        icon: (
            <LockIcon
                color="disabled"
                style={{ width: '2.5vw', height: '2.5vh' }}
            />
        ),
        type: ViewTypes.ListShop,
        locked: true,
        tooltip: "Vous ne pouvez pas choisir ce type pour l'instant",
    },
]

const PureSettingsForm = ({
    config,
    step,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '42vh' }}>
                <Grid container direction="column">
                    <PrimaryText
                        bold
                        color={BLACK}
                        style={{ marginBottom: '3vh', marginTop: '1vh' }}
                    >
                        Paramétrez votre vue :
                    </PrimaryText>
                    <Grid container direction="column">
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            m="3vh 0"
                            sx={{
                                '& .MuiFormHelperText-root': {
                                    fontSize: '1.6vh',
                                },
                            }}
                        >
                            <PrimaryText>Nom</PrimaryText>
                            <Field
                                size="small"
                                sx={{
                                    width: '20vw',
                                    '& div': {
                                        fontSize: '1.6vmin',
                                    },
                                }}
                                autoFocus
                                name="name"
                                type="text"
                                component={TextInput}
                            />
                        </Grid>
                        <Grid item>
                            <PrimaryText paragraph> Type de vue</PrimaryText>
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ marginBottom: '3vh', marginTop: '1vh' }}
                            >
                                <OptionsChoiceComponent
                                    listOfChoice={typesOfView}
                                    setValue={props.setFieldValue}
                                    name="type"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <PrimaryButton>
            Recevoir une notification dès que les fonctionnalités seront
            disponibles
          </PrimaryButton> */}
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={
                    step === 0 ? config.handleReset : config.handlePrevious
                }
                leftButtonText={step === 0 ? 'ANNULER' : 'PRÉCÉDENT'}
                rightButtonFunc={async () => {
                    await props.submitForm()
                }}
                rightButtonText={
                    step === config.stepsNumber - 1 ? 'CRÉER MA VUE' : 'SUIVANT'
                }
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        name: props.initialValues.name,
        type: props.initialValues.type,
    }),
    validationSchema: () => {
        return object().shape({
            name: string().required('Nom est un champ obligatoire'),
            type: string()
                .oneOf(Object.values(ViewTypes))
                .required('Le choix du type de vue est obligatoire'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const FsCrossSettingsStep = ({ step, config }: OwnProps) => {
    const dispatch = useAppDispatch()
    const { data } = useAppSelector((state) => state.views)
    const { env } = useAppSelector((state) => state.global)
    const _updateData = useCallback(
        ({
            name,
            type,
            environment,
            distributor,
            creationMode,
        }: {
            name: string
            type: string
            environment: EnvType
            distributor: string
            creationMode: ViewCreationMode
        }) =>
            dispatch(
                updateData({
                    data: {
                        name,
                        type: type as ViewTypes,
                        distributor,
                        environment,
                        creationMode,
                    },
                })
            ),
        [dispatch]
    )

    const handleSubmit = (values: FormValues) => {
        _updateData({
            name: values.name,
            type: values.type,
            environment: env.type,
            distributor: env.name,
            creationMode: ViewCreationMode.GLOBAL,
        })
        config.handleNext()
    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            initialValues={{
                name: data.name,
                type: data.type,
            }}
        />
    )
}

export default FsCrossSettingsStep
