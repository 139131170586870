import React from 'react'
import { Box } from '@mui/material'

export interface ColorSquareProps {
    color: string
    size: SquareColor
}

export enum SquareColor {
    SM,
    MD,
    LG,
}

const ColorSquare = ({ color, size }: ColorSquareProps) => {
    const length = size + 0.7

    return (
        <Box
            style={{
                background: color,
                width: `${length}vw`,
                height: `${length}vw`,
            }}
        />
    )
}

export default ColorSquare
