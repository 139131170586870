import { Grid, SxProps } from '@mui/material'
import React from 'react'
import { PrimaryText } from '../../ui/Text'
import { TypeOfItem } from './OptionsChoicesBox'

interface Props {
    handleSelection?: () => void
    style: SxProps
    item: TypeOfItem
}

export const OptionsChoicesItem = ({ handleSelection, style, item }: Props) => {
    return (
        <Grid
            mb="1vh"
            container
            direction="column"
            justifyContent="space-evenly"
            onClick={handleSelection}
            flex={1}
            sx={style}
        >
            <Grid container justifyContent="center">
                {item.icon}
            </Grid>
            <Grid item>
                <PrimaryText>{item.label}</PrimaryText>
            </Grid>
        </Grid>
    )
}
