import { Grid } from '@mui/material'
import React, { useSyncExternalStore } from 'react'
import { Line } from 'react-chartjs-2'
import { formateLegend } from '../../../../../../components/features/foodService/dashboard/utils/formateProductsData'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../../../helpers/ExternalStore'
import { Chart } from '../../../../../../redux/fsProducts/fsProductsTypes'
import { legendMarginPlugin } from './EvolLineChart'

const formatDatasets = (datasets: Chart) => {

    if (datasets.name === "Sell-out (kg)") {
        return {
            label: datasets.name,
            data: [null, ...datasets.data, null],
            borderColor: '#3A4D87',
            backgroundColor: '#3A4D87',
            yAxisID: 'y',
        }
    } else if (datasets.name === "Sell-in (kg)") {
        return {
            label: datasets.name,
            data: [null, ...datasets.data, null],
            borderColor: 'purple',
            backgroundColor: 'purple',
            yAxisID: 'y',
        }
    } else if (datasets.name === "Promotions (€)") {
        return {
            label: datasets.name,
            data: [null, ...datasets.data, null],
            borderColor: '#EF9BFD',
            backgroundColor: '#EF9BFD',
            yAxisID: 'y1',
        }
    }

}


export const MultiAxes2 = ({
    datasets,
    period,
}: {
    datasets: any
    period: string[]
}) => {
    const formatedLegend = formateLegend(period)

    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        pointBorderWidth: height * 0.005,
        borderWidth: height * 0.004,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                ticks: {
                    font: { size: height * 0.012 },
                },
            },
            y: {
                ticks: {
                    font: { size: height * 0.012 },
                },
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
            },
            y1: {
                ticks: {
                    font: { size: height * 0.012 },
                },
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any, tooltipItem: any) => {
                        const unit = model.dataset.label === "Promotions (€)"
                            ? '€'
                            : 'kg'

                        const data = Math.round(model.raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return ` ${model.dataset.label} : ${data} ${unit} `
                    },
                },
            },
        },
    }


    const toto = datasets.map((data) => formatDatasets(data))

    return (
        <Grid
            sx={{
                height: '36vh',
                padding: '1vw',
            }}
        >
            <Line
                options={options as any}
                data={{
                    labels: [null, ...formatedLegend, null],
                    datasets: toto,
                }}
                plugins={legendMarginPlugin}
            />
        </Grid>
    )
}
