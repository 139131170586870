import React, { useEffect, useState } from 'react'
import { Grid, Tooltip, Box } from '@mui/material'
import PrimaryKpiCard, {
    type KpiCardType,
    MockedPrimaryCard,
} from '../../../../../components/features/PrimaryKpiCard'
import { useAppSelector } from '../../../../../redux/hooks'
import { Units } from '../../../../../redux/global/globalTypes'

export interface KpiData extends KpiCardType {
    id: number
    description?: string
}

const RetailKpis = () => {
    const [enhancedKpis, setKpis] = useState<KpiData[] | null>(null)
    const { kpis } = useAppSelector((state) => state.global.retailDashboardData)
    const { env } = useAppSelector((state) => state.global)

    useEffect(() => {
        if (kpis) {
            setKpis([
                {
                    id: 1,
                    name: 'Volume total',
                    units: Units.uvc,
                    description:
                        'Volume total vendu sur la période sélectionnée (Volume A). Variation par rapport à l’année précédente (Volume A-1).',
                    number: Math.round(kpis.sells),
                    difference: kpis.sells - kpis.sellsLastYear,
                    route: `/retail/${env.name}/produits`,
                },
                {
                    id: 3,
                    name: 'Nombre de PDV',
                    units: Units.pdv,
                    description:
                        'Nombre de points de vente distribuant vos références sur la période sélectionnée (A). Variation par rapport à l’année précédente (A-1).',
                    number: kpis.stores,
                    difference: kpis.stores - kpis.storesLastYear,
                    route: `/retail/${env.name}/magasins`,
                },
                {
                    id: 2,
                    name: 'Rotation',
                    units: Units.uvcPerStorePerMonth,
                    description:
                        'Volume mensuel moyen vendu par point de vente sur la période sélectionnée (A). Variation par rapport à l’année précédente (A-1).',
                    number: Math.round(kpis.vmm),
                    difference: kpis.vmm - kpis.vmmLastYear,
                    route: `/retail/${env.name}/produits`,
                },
                {
                    id: 4,
                    name: 'Nombre de références distribuées',
                    units: 'références',
                    description:
                        'Nombre de références distribuées sur la période sélectionnée (A). Variation par rapport à l’année précédente (A-1).',
                    number: kpis.products,
                    difference: kpis.products - kpis.productsLastYear,
                    route: `/retail/${env.name}/produits`,
                },
            ])
        }
    }, [kpis])

    return (
        <Grid container justifyContent="space-between">
            {enhancedKpis
                ? enhancedKpis.map((kpi) => (
                    <Tooltip key={kpi.id} title={kpi.description}>
                        <Box
                            sx={{ flex: 1, maxWidth: '24%', height: '100%' }}
                        >
                            <PrimaryKpiCard
                                difference={kpi.difference}
                                name={kpi.name}
                                number={kpi.number}
                                units={kpi.units}
                                route={kpi.route}
                            />
                        </Box>
                    </Tooltip>
                ))
                : Array(4)
                    .fill('a')
                    .map((item, index) => (
                        <MockedPrimaryCard key={`${item}-${index}`} />
                    ))}
        </Grid>
    )
}

export default RetailKpis
