import React from 'react'
import { RED } from './Colors'
import { PrimaryText } from './Text'
import { Grid, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface OwnProps {
    msg: string
    linkMsg: string
    link: string
}

export const ErrorMessageWithLink = ({ msg, linkMsg, link }: OwnProps) => {
    const navigate = useNavigate()

    return (
        <Grid>
            <PrimaryText color={RED} fontWeight="400">
                {msg}{' '}
                <Link
                    onClick={() => {
                        navigate(link)
                    }}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    {linkMsg}
                </Link>
            </PrimaryText>
        </Grid>
    )
}
