import React, { useCallback, useEffect, useState } from 'react'
import Loading from '../../../../components/features/screens/LoadingScreen'
import WaitingScreen from '../../../../components/features/screens/WaitingScreen'
import { NoDataScreen } from '../../../../components/features/screens/NoDataScreen'
import { Button, Grid, Paper } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { ActiveFsFilters } from '../../../../redux/filters/filtersTypes'
import { fetchFsClients } from '../../../../redux/fsProducts/fsProductsSlice'
import { EnvName, UsersStatus } from '../../../../redux/user/userTypes'
import { PrimaryText } from '../../../../components/ui/Text'
import { BLACK, DARK_GREY } from '../../../../components/ui/Colors'
import UploadIcon from '@mui/icons-material/Upload';
import { getPlaceholderDates } from '../../../../helpers/months'
import { StandaloneScreen } from '../../../../components/features/screens/StandAloneScreen'

const MetroFallback = () => {
    const { startDate, endDate } = useAppSelector(state => state.filters.activeFsFilters)
    const period = getPlaceholderDates(
        new Date(startDate),
        new Date(endDate)
    )

    return (<Paper
        sx={{
            width: 'calc(100% - 48px)',
            height: '86vh',
            margin: 'auto',
            marginTop: '1vw',
        }}
    >
        <Grid alignItems="center" justifyContent="center" direction="column" container p="1vw" textAlign="center" height="100%">
            <Grid mb="8vh">
                <UploadIcon sx={{ mb: "1vh" }} />
                <PrimaryText color={BLACK}>
                    Pour accéder à la segmentation d'utilisateurs finaux METRO, transmettez-nous vos fichiers de données METRO "CIM 2" correspond à la période suivante :
                </PrimaryText>
                <PrimaryText mt="2vh" color={BLACK} bold>{period}</PrimaryText>
            </Grid>
            <Grid>
                <PrimaryText color={BLACK}>
                    Vous pouvez joindre vos données par mail à l'adresse suivante
                </PrimaryText>
                <Button sx={{ fontSize: { xs: '14px', md: '1.3vmin' }, color: DARK_GREY }} href={"mailto: hind@karyonfood.com"}> hind@karyonfood.com</Button>
            </Grid>
        </Grid>
    </Paper>)
}

export const CustomersLayout = ({ children }: { children: JSX.Element }) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { isLoading, unit, env } = useAppSelector((state) => state.global)
    const { brand, status } = useAppSelector((state) => state.user.user)
    const { clients } = useAppSelector((state) => state.fsProducts)

    const dispatch = useAppDispatch()
    const _fetchClients = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchFsClients(filters))
        },
        [dispatch]
    )

    useEffect(() => {
        if (brand && activeFsFilters.startDate) {
            _fetchClients(activeFsFilters)
            setIsFirstLoad(false)
        } else if (activeFsFilters.startDate === '') {
            setIsFirstLoad(false)
        }
    }, [brand, activeFsFilters, _fetchClients, unit])


    if (status === UsersStatus.STANDALONE) {
        return <StandaloneScreen />
    }

    if (isLoading || isFirstLoad) {
        return <Loading />
    }

    if (clients.list.length === 0 && env.name === EnvName.METRO) {
        return <MetroFallback />
    }

    if (
        !clients ||
        !activeFsFilters.startDate ||
        env.status === 'onload-init'
    ) {
        return <WaitingScreen />
    }

    if (clients.list.length === 0) {
        return <NoDataScreen />
    }
    return <Grid>{children}</Grid>
}
