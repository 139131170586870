import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import { useAppSelector } from '../../../../redux/hooks'
import { listOfFSDistributors } from '../types'
import { CercleVertColumnsCustomersPage } from '../utils'
import { CustomersLayout } from '../layout/CustomersLayout'
import Customers from '../Pages/FsCustomersPage'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'

export const CustomersPageCercleVert = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage title="Utilisateurs finaux" period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.CLIENTS}
            />
            <CustomersLayout>
                <Customers
                    envName={listOfFSDistributors.CERCLEVERT}
                    columns={CercleVertColumnsCustomersPage()}
                    titles={{
                        doughnutTitle: `Volume livré par type d'utilisateurs finaux`,
                        dataGridTitle: `Liste des sous-sous-types d'utilisateurs finaux`,
                    }}
                    drillDownType={DrillDownType.CLIENTSCERCLEVERT}
                />
            </CustomersLayout>
        </PrivatePage>
    )
}
