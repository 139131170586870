import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import { Notification, NotificationsState } from './notificationsTypes'
import client from '../../api/config'

const initialState: NotificationsState = {
    notifications: null,
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotifications: (state, action: PayloadAction<Notification[]>) => {
            state.notifications = action.payload
        },
        deleteNotification: (
            state,
            action: PayloadAction<{ notificationId: string }>
        ) => {
            const index = state.notifications.findIndex(
                (notification) =>
                    notification._id === action.payload.notificationId
            )

            state.notifications = [
                ...state.notifications.slice(0, index),
                ...state.notifications.slice(index + 1),
            ]
        },
        updateNotifications: (
            state,
            action: PayloadAction<{
                notificationId: string
                update: Partial<Notification>
            }>
        ) => {
            const index = state.notifications.findIndex(
                (notification) =>
                    notification._id === action.payload.notificationId
            )

            state.notifications = [
                ...state.notifications.slice(0, index),
                {
                    ...state.notifications[index],
                    ...action.payload.update,
                },
                ...state.notifications.slice(index + 1),
            ]
        },
    },
})

export const { setNotifications, deleteNotification, updateNotifications } =
    notificationsSlice.actions
export default notificationsSlice.reducer

export const fetchNotifications =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            await client
                .get('/notifications')
                .then((response) => {
                    dispatch(setNotifications(response.data))
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                })
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchDeleteNotification =
    (notificationId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            await client
                .delete(`/notifications/${notificationId}`)
                .then((response) => {
                    dispatch(deleteNotification({ notificationId }))
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                })
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchUpdatedNotification =
    (
        notificationId: string,
        update: Partial<Notification>
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            await client
                .put(`/notifications/${notificationId}`, update)
                .then((response) => {
                    dispatch(updateNotifications({ notificationId, update }))
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                })
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }
