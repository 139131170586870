import React, { useEffect, useState } from 'react'
import client from '../../../api/config'
import { supabase } from '../../../../src/supabaseClient.js'
import { DARK_BLUE, DARK_GREY, GREEN, LIGHT_GREY, RED } from '../../ui/Colors'
import { PrimaryText } from '../../ui/Text'
import { Box, Grid } from '@mui/material'
import { CircularProgressWithLogoWithoutText } from '../screens/LoadingScreen'
import { AltButton } from '../../ui/Button'
import { useAppSelector } from '../../../redux/hooks'
import Dropzone, { FileRejection } from 'react-dropzone'
import {
    ERROR_FILES_NUMBER,
    ERROR_FILES_TYPE,
    ERROR_UPLOAD,
    MAX_FILES_UPLOADED,
} from '../../../helpers/fileUploadMsg'

const UPLOAD_MSG =
    'Déposez un fichier ici ou importez un fichier en cliquant sur le bouton ci-dessous.'
const FILE_UPLOADED =
    'Votre fichier a bien été importé ! Importez en un autre ou rendez vous sur votre tableau de bord.'

enum DROP_ERRORS {
    TOO_MANY_FILES = 'too-many-files',
    WRONG_TYPE = 'file-invalid-type',
}

export interface DropStatus {
    type: 'success' | 'error' | 'default'
    msg: string
}

interface OwnProps {
    onFileUpload: (files: any, alert: DropStatus) => void
}

const UploadPlaceholder = ({
    isLoading,
    alert,
}: {
    isLoading: boolean
    alert: DropStatus
}) => {
    let mainColor = ''

    switch (alert.type) {
        case 'error':
            mainColor = RED
            break
        case 'success':
            mainColor = GREEN
            break
        default:
            mainColor = DARK_GREY
            break
    }

    return (
        <Grid
            container
            alignContent="center"
            justifyContent="center"
            sx={{
                height: '20vh',
                borderRadius: '5px',
                border: `dashed ${mainColor}`,
                background: LIGHT_GREY,
            }}
        >
            {isLoading ? (
                <Box p={2} textAlign="center">
                    <CircularProgressWithLogoWithoutText />
                    <PrimaryText color={DARK_BLUE} bold>
                        Import des données en cours...
                    </PrimaryText>
                </Box>
            ) : (
                <Box p={2} textAlign="center">
                    <PrimaryText color={mainColor}>{alert.msg}</PrimaryText>
                    <Box mt={3}>
                        <AltButton
                            style={{ color: mainColor, borderColor: mainColor }}
                        >
                            Importer un fichier
                        </AltButton>
                    </Box>
                </Box>
            )}
        </Grid>
    )
}

const FileUploader = ({ onFileUpload }: OwnProps) => {
    const { brand } = useAppSelector((state) => state.user.user)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [files, setFiles] = useState<any | null>(null)
    const [alert, setAlert] = useState<DropStatus>({
        type: 'default',
        msg: UPLOAD_MSG,
    })

    useEffect(() => {
        if (['error', 'success'].includes(alert.type)) {
            onFileUpload(files, alert)
        }
    }, [files, alert])

    const onDrop = async (
        acceptedFiles: any,
        rejectedFiles: FileRejection[]
    ) => {
        if (!acceptedFiles.length || rejectedFiles.length > 0) {
            return
        }

        const file = acceptedFiles[0]
        setFiles(file)

        setLoading(true)
        await uploadFile(file)
            .then((response) => {
                if (file) {
                    setAlert({
                        type: 'success',
                        msg: FILE_UPLOADED,
                    })
                }
            })
            .catch((e) => {
                setAlert(() => ({ type: 'error', msg: e.message }))
            })
        setLoading(false)
    }

    const uploadFile = async (file) => {
        const formData = new FormData()
        formData.append('file', file)

        if (brand === 'Karyon') {
            await client
                .post('/imports/file', formData)
                .then((resp) => resp)
                .catch((e) => {
                    throw new Error("Erreur pendant l'import")
                })
        } else {
            const { error } = await supabase.storage
                .from(process.env.REACT_APP_SUPABASE_BUCKET_RAW_DATA)
                .upload(`${brand}-${file.name}`, formData, {
                    contentType:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                })

            if (error) {
                switch (error.error) {
                    case 'Duplicate':
                        throw new Error('Le nom du fichier existe déjà.')
                    case 'Invalid Input':
                        throw new Error(
                            'Le nom de votre fichier contient des caractères invalides.'
                        )
                    default:
                        throw new Error(ERROR_UPLOAD)
                }
            }
        }
    }

    const handleDropErrors = (
        fileRejections: any,
        event: DragEvent | Event
    ) => {
        switch (fileRejections[0].errors[0].code) {
            case DROP_ERRORS.TOO_MANY_FILES:
                setAlert({ type: 'error', msg: ERROR_FILES_NUMBER })
                break
            case DROP_ERRORS.WRONG_TYPE:
                setAlert({ type: 'error', msg: ERROR_FILES_TYPE })
                break
            default:
                setAlert({ type: 'error', msg: ERROR_UPLOAD })
        }
    }

    return (
        <Box sx={{ margin: 'auto', height: '100%' }}>
            <Dropzone
                onDrop={onDrop}
                maxFiles={MAX_FILES_UPLOADED}
                onDropRejected={handleDropErrors}
                accept={{
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                        ['.xlsx'],
                    'application/vnd.ms-excel': ['.xls'],
                    'text/csv': ['.csv'],
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()} height="100%">
                        <input
                            {...getInputProps()}
                            accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        />
                        <UploadPlaceholder
                            alert={alert}
                            isLoading={isLoading}
                        />
                    </Box>
                )}
            </Dropzone>
        </Box>
    )
}

export default FileUploader
