import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { fetchFilters } from '../../../redux/filters/filtersSlice'
import { SwitchFiltersType } from './SwitchFiltersType'
import {
    ActiveFsFilters,
    ActiveRetailFilters,
} from '../../../redux/filters/filtersTypes'
import { PagesName } from '../navigation/types/pages'

export enum FiltersType {
    FS = 'FS',
    VIEWSFS = 'viewsFS',
    VIEWSFSCROSS = 'viewsFScROSS',
    RETAIL = 'Retail',
    VIEWSRETAIL = 'viewsRetail',
    FSCROSS = 'FS-CROSS',
}

const Filters = ({
    filtersType,
    filters,
    page,
}: {
    filtersType: FiltersType
    filters: ActiveFsFilters | ActiveRetailFilters
    page?: PagesName
}) => {
    const [isLoading, setLoading] = useState(false)
    const env = useAppSelector((state) => state.global.env)

    const dispatch = useAppDispatch()
    const _fetchFilters = useCallback(async () => {
        await dispatch(fetchFilters())
    }, [dispatch])

    useEffect(() => {
        if (!filters.startDate || [FiltersType.VIEWSFS, FiltersType.VIEWSRETAIL, FiltersType.VIEWSFSCROSS].includes(filtersType)) {
            setLoading(true)
            _fetchFilters().then(() => setLoading(false))
        }
    }, [env, _fetchFilters])

    return (
        <Grid
            container
            alignItems="center"
            wrap="nowrap"
            sx={{
                height: '3vh',
                paddingLeft: '1vw',
                paddingRight: '1vw',
                mt: '1vh',
            }}
        >
            {!isLoading && filters.startDate && (
                <Grid id="filters-container" container>
                    <SwitchFiltersType
                        filtersType={filtersType}
                        filters={filters}
                        page={page}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default Filters
