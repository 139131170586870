import React from 'react'
import ImportFs from '../../../../pages/private/Global/Import/ImportFs'
import { routerType } from './PublicNavigation'
import ImportRetail from '../../../../pages/private/Global/Import/ImportRetail'
import SetNewPassword from '../../../../pages/public/authentication/SetNewPassword'
import { ProfilPage } from '../../../../pages/private/Global/ProfilPage/ProfilPage'
import { AdminPage } from '../../../../pages/private/Global/AdminPage/AdminPage'

export const settingsPages: routerType[] = [
    {
        path: 'import-fs',
        element: <ImportFs />,
        title: 'import-fs',
    },
    {
        path: 'import-retail',
        element: <ImportRetail />,
        title: 'import-retail',
    },
    {
        path: 'profil',
        element: <ProfilPage />,
        title: 'profil',
    },
    {
        path: 'administration',
        element: <AdminPage />,
        title: 'Administration',
    },
    {
        path: 'setnewpassword',
        element: <SetNewPassword />,
        title: 'setnewpassword',
    },
]
