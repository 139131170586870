import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Grid } from '@mui/material'
import { GREEN, RED } from '../../../ui/Colors'
import { SecondaryText } from '../../../ui/Text'
import NumberWithSpace from '../../../ui/NumberWithSpace'
import { Units } from '../../../../redux/global/globalTypes'

interface OwnProps {
    variation: number
    unit: Units
}

const TendencyDashboard = ({ variation, unit }: OwnProps) => {
    const tendency = variation > 0
    const color = tendency ? GREEN : RED

    return (
        <Grid
            container
            wrap="nowrap"
            sx={{
                '& svg': { width: '1.2vw' },
                width: '10vw',
                marginLeft: '0.8em',
            }}
            alignItems="center"
        >
            {tendency ? (
                <ArrowUpwardIcon fontSize="large" sx={{ color: color }} />
            ) : (
                <ArrowDownwardIcon sx={{ color: color }} />
            )}
            <SecondaryText ml="0.1vw" color={color}>
                <NumberWithSpace number={variation} />
                {` ${unit}`}
            </SecondaryText>
        </Grid>
    )
}

export default TendencyDashboard
