type ToSortArray = string[] | number[]

export const sortTwoArrSameWay = (
    base: ToSortArray,
    second: ToSortArray,
    inverted?: boolean
) => {
    let list = []
    base.forEach((elem, index) =>
        list.push({ base: base[index], second: second[index] })
    )

    list.sort((a, b) => {
        if (inverted) {
            return a.base < b.base ? -1 : a.base === b.base ? 0 : 1
        }

        return a.base < b.base ? 1 : a.base === b.base ? 0 : -1
    })

    let newBase = []
    let newSecond = []
    list.forEach((elem, index) => {
        newBase[index] = list[index].base
        newSecond[index] = list[index].second
    })

    return {
        base: newBase,
        second: newSecond,
    }
}

export const sortFiveArrSameWay = (
    base: ToSortArray,
    second: ToSortArray,
    third: ToSortArray,
    fourth: ToSortArray,
    fifth: ToSortArray,
    inverted?: boolean
) => {
    let list = []
    base.forEach((elem, index) =>
        list.push({
            base: base[index],
            second: second[index],
            third: third[index],
            fourth: fourth[index],
            fifth: fifth[index],
        })
    )

    list.sort((a, b) => {
        if (inverted) {
            return a.base < b.base ? -1 : a.base === b.base ? 0 : 1
        }

        return a.base < b.base ? 1 : a.base === b.base ? 0 : -1
    })

    let newBase = []
    let newSecond = []
    let newThird = []
    let newFourth = []
    let newFifth = []
    list.forEach((elem, index) => {
        newBase[index] = list[index].base
        newSecond[index] = list[index].second
        newThird[index] = list[index].third
        newFourth[index] = list[index].fourth
        newFifth[index] = list[index].fifth
    })

    return {
        base: newBase,
        second: newSecond,
        third: newThird,
        fourth: newFourth,
        fifth: newFifth,
    }
}
