import React from 'react'
import ChartBlock from '../../../../../components/features/ChartBlock'
import DataGridDashboard from './RetailDataGrid'
import { useAppSelector } from '../../../../../redux/hooks'
import { Units } from '../../../../../redux/global/globalTypes'

const RegionTable = () => {
    const { env } = useAppSelector((state) => state.global)

    return (
        <ChartBlock
            title={`Répartition par région (${Units.uvc})`}
            link={`/retail/${env.name}/magasins`}
        >
            <DataGridDashboard />
        </ChartBlock>
    )
}

export default RegionTable
