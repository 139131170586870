import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Coordinates, MapState } from "./mapTypes";

const initialState: MapState = {
  zoom: 6,
  center: {
      lat: 47.8566,
      lng: 2.3522,
  }
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    setCenter: (state, action: PayloadAction<Coordinates>) => {
        state.center = action.payload;
    },
    setCoordinates: (state, action: PayloadAction<{zoom: number, center: Coordinates}>) => {
        state.zoom = action.payload.zoom;
        state.center = action.payload.center;
    },
  },
});

export const { setZoom, setCenter, setCoordinates } = mapSlice.actions;
export default mapSlice.reducer;