export const roundIfNotZero = (num: number, notRoundable?: boolean) => {
    if (typeof num !== "number") {
        return "N/A"
    }
    if (num === 0) {
        return num.toString()
    }
    if ((num > -1 && num < 1) || notRoundable) {
        return num.toFixed(2).toString().replace('.', ',')
    } else {
        return Math.round(num).toString()
    }
}
