import { DialogContent, Grid } from '@mui/material'
import React from 'react'
import { DialogFooterOneAction } from '../../Dialog/BasicFooterDialog'
import ViewShared from "../../../../assets/ViewSharedSuccess.png"
import { PrimaryText } from '../../../ui/Text'
import { ConfigProps } from '../ShareDialog'

const ValidationStep = ({
    handleClose,
    handleReset,
}: ConfigProps
) => {
    return (
        <Grid container direction="column" height="100%" justifyContent="space-around">
            <DialogContent>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <PrimaryText color="#41AF2F" bold>
                        Votre vue a bien été partagée !
                    </PrimaryText>
                    <img style={{ marginTop: "2vh" }} width="40%" src={ViewShared} />
                </Grid>
            </DialogContent>
            <DialogFooterOneAction
                buttonFunc={(e: any) => {
                    e.stopPropagation()
                    handleClose(e)
                    handleReset()
                }}
                text="Fermer"
            />
        </Grid>
    )
}

export default ValidationStep
