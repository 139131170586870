import { Grid } from '@mui/material'
import React, { useSyncExternalStore } from 'react'
import { BLACK } from '../../../../ui/Colors'
import { SecondaryText } from '../../../../ui/Text'
import ColorSquare, { type ColorSquareProps } from '../../../../ui/ColorSquare'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../../helpers/ExternalStore'
import { useAppSelector } from '../../../../../redux/hooks'
import { EnvType } from '../../../../../redux/user/userTypes'

interface OwnProps extends ColorSquareProps {
    label: string
    clickHandler: () => void
}

const DoughnutLabel = ({ label, color, size, clickHandler }: OwnProps) => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    const { env } = useAppSelector((state) => state.global)

    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="center"
            style={{ cursor: env.type !== EnvType.FOOD_SERVICE_CROSS && 'pointer' }}
            onClick={clickHandler}
        >
            <ColorSquare color={color} size={size} />
            <SecondaryText color={BLACK} ml="1vw">
                {label}
            </SecondaryText>
        </Grid>
    )
}

export default DoughnutLabel
