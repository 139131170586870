import React from 'react'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DARK_BLUE, MEDIUM_GREY, DARK_GREY } from '../../ui/Colors'
import { PrimaryText } from '../../ui/Text'
import { DialogFooter } from './BasicFooterDialog'

interface OwnProps {
    isOpen: boolean
    setOpen: any
    title: string
    content: string
    rightButtonText: string
    leftButtonText: string
    rightButtonFunc: () => void
    leftButtonFunc: () => void
    video: string
}

const Title = ({ title, xl }: { title: string; xl: boolean }) => {
    return (
        <DialogTitle
            sx={{
                fontWeight: 'bold',
                padding: 0,
                paddingLeft: '1em',
                textAlign: 'left',
                width: 'auto',
                color: DARK_BLUE,
                fontSize: { xs: '16px', md: xl ? '2.2vmin' : '1.6vmin' },
            }}
        >
            {title}
        </DialogTitle>
    )
}

export const DialogHeader = ({
    title,
    setOpen,
    img,
    xl = false,
}: {
    title: string
    setOpen: (boolean) => void
    img?: string
    xl?: boolean
}) => {
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{
                borderBottom: `solid ${MEDIUM_GREY} 1px`,
                padding: '1vh 1vw 1vh 1vw',
            }}
        >
            <Grid container alignItems="center">
                {img && (
                    <img
                        src={img}
                        style={{ width: '3.5vw', paddingRight: '0.5vw' }}
                        alt="Logo du distributeur"
                    />
                )}
                <Title title={title} xl={xl} />
            </Grid>
            <CloseIcon
                onClick={(e) => {
                    e.stopPropagation()
                    setOpen(false)
                }}
                sx={{
                    color: MEDIUM_GREY,
                    cursor: 'pointer',
                    mr: '1vw',
                    width: '2.5vw',
                    height: '2.5vh',
                }}
            />
        </Grid>
    )
}

const BasicDialog = ({
    isOpen,
    setOpen,
    title,
    content,
    rightButtonText,
    leftButtonText,
    rightButtonFunc,
    leftButtonFunc,
    video,
}: OwnProps) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            onClose={() => setOpen(false)}
            open={isOpen}
            PaperProps={{
                sx: { borderRadius: '1em', width: '780px', padding: '1em' },
            }}
        >
            <DialogHeader title={title} setOpen={setOpen} />
            <DialogContent sx={{ borderBottom: `solid ${MEDIUM_GREY} 1px` }}>
                <Grid
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <PrimaryText align="left" marginBottom="2vh">
                        {content}
                    </PrimaryText>
                    <Grid item flex={1} container justifyContent="center">
                        <video autoPlay controls width="420px" height="315px">
                            <source src={video} type="video/mp4" />
                        </video>
                    </Grid>
                    <a
                        rel="noopener noreferrer"
                        target={'_blank'}
                        style={{ color: DARK_GREY }}
                        href="https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed"
                    >
                        Besoin d'aide ?
                    </a>
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={leftButtonFunc}
                leftButtonText={leftButtonText}
                rightButtonFunc={rightButtonFunc}
                rightButtonText={rightButtonText}
            />
        </Dialog>
    )
}

export default BasicDialog
