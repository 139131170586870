export const monthsAsObject = {
    '01': 'Janvier',
    '02': 'Février',
    '03': 'Mars',
    '04': 'Avril',
    '05': 'Mai',
    '06': 'Juin',
    '07': 'Juillet',
    '08': 'Août',
    '09': 'Septembre',
    '10': 'Octobre',
    '11': 'Novembre',
    '12': 'Décembre',
}

export const monthAsArray = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
]

export const shortMonths = {
    '01': 'Janv',
    '02': 'Févr',
    '03': 'Mars',
    '04': 'Avr',
    '05': 'Mai',
    '06': 'Juin',
    '07': 'Juill',
    '08': 'Août',
    '09': 'Sept',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Déc',
}

const vowels = ['A', 'O']

const getStarter = (letter: string) => {
    if (vowels.includes(letter)) {
        return "D'"
    }

    return 'De '
}

export const getPlaceholderDates = (startDate: Date, endDate: Date) => {
    if (startDate.getTime() === endDate.getTime()) {
        const year = endDate.getFullYear()
        const month = endDate.getMonth()
        const period = monthAsArray[month] + ' ' + year

        return period
    } else {
        const startDateMonth = startDate.getMonth()
        const startDateYear = startDate.getFullYear()
        const endDateMonth = endDate.getMonth()
        const endDateYear = endDate.getFullYear()

        const period = `${getStarter(monthAsArray[startDateMonth][0])}${
            monthAsArray[startDateMonth]
        } ${startDateYear} à ${monthAsArray[endDateMonth]} ${endDateYear}`

        return period
    }
}

export const getDefaultPeriod = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = monthsAsObject[currentDate.getMonth() + 1]

    return `De Janvier ${currentYear} à  ${currentMonth}  ${currentYear}`
}
