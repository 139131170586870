import React from 'react'
import PublicPage from './PublicPage'
import Illustration from '../../../assets/404.svg'
import { PrimaryButton } from '../../ui/Button'
import { PrimaryText, SecondaryText, Title } from '../../ui/Text'
import { Box, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const LeftSide = () => {
    return (
        <Box>
            <PrimaryText textAlign="left" mb={8}>
                Des tableaux de bord concrets et dynamiques pour piloter et
                valoriser vos données
            </PrimaryText>
            <img
                src={Illustration}
                alt="Illustration"
                style={{ width: '30vw', height: '50vh' }}
            />
        </Box>
    )
}

export default function PageNotFound() {
    const alignement = 'left'
    const navigate = useNavigate()

    return (
        <PublicPage staticComponent={<LeftSide />}>
            <Grid container direction="column">
                <Grid item mb={8} textAlign={alignement}>
                    <Grid item>
                        <SecondaryText>Erreur 404</SecondaryText>
                    </Grid>
                    <Grid item>
                        <Title>Il semblerait que vous soyez perdu</Title>
                    </Grid>
                    <Grid item sx={{ marginTop: '1em' }}>
                        <PrimaryButton
                            onClick={() => {
                                navigate('/')
                            }}
                        >
                            Accueil
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </PublicPage>
    )
}
