import React from 'react'
import PrivatePage from '../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../components/features/filters/Filters'
import { useAppSelector } from '../../../redux/hooks'
import { listOfRetailDistributors } from './types'
import CarrefourProductsPage from './Carrefour/productsPage/CarrefourProductsPage'
import AuchanProductsPage from './Auchan/productsPage/AuchanProductsPage'

export const ProductsPage = () => {
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)

    const getRetailProductsPageContent = (envName: string) => {
        switch (envName) {
            case listOfRetailDistributors.CARREFOUR:
                return <CarrefourProductsPage />
            case listOfRetailDistributors.AUCHAN:
                return <CarrefourProductsPage />
            default:
                return <CarrefourProductsPage />
        }
    }

    return (
        <PrivatePage title="Références" attribute={0} period>
            <Filters
                filtersType={FiltersType.RETAIL}
                filters={activeRetailFilters}
            />
            {getRetailProductsPageContent(env.name)}
        </PrivatePage>
    )
}
