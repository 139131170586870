import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import DashboardMetro from '../../../../../../pages/private/Foodservice/Metro/DashboardMetro'
import { CustomersPageMetro } from '../../../../../../pages/private/Foodservice/Metro/CustomersPage'
import { WarehousesPageMetro } from '../../../../../../pages/private/Foodservice/Metro/WarehousesPage'
import { ProductsPageMetro } from '../../../../../../pages/private/Foodservice/Metro/ProductsPage'
import { ViewPageMetro } from '../../../../../../pages/private/Foodservice/Metro/ViewPage'

export const MetroRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardMetro />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageMetro />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'depots/',
        element: <WarehousesPageMetro />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'produits/',
        element: <ProductsPageMetro />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPageMetro />,
        title: PagesName.VIEWS,
    },
]
