import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from '@mui/material'
import { type FormikBag, type FormikProps, withFormik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import Activated from '../../../../../../assets/inactivated_carrefour.svg'
import { PrimaryText } from '../../../../../../components/ui/Text'
import { DARK_GREY } from '../../../../../../components/ui/Colors'
import {
    EnhancedField,
    TextInput,
} from '../../../../../../components/ui/Inputs'
import { Inputs } from '../../../../../public/authentication/signUp/steps/SignUpAccount'
import { DialogFooter } from '../../../../../../components/features/Dialog/BasicFooterDialog'
import { ConfigProps } from '../AutoInactivated'
import { CircularProgressWithLogoWithoutText } from '../../../../../../components/features/screens/LoadingScreen'

interface FormValues {
    startDate: string
    endDate: string
    brands: string[]
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    isSubmit: boolean
    config: ConfigProps
}

const inputs: Inputs[] = [
    { type: 'text', name: 'startDate', label: 'Date de début' },
    { type: 'text', name: 'endDate', label: 'Date de fin' },
]

const names = ['Chocodélice', 'Atelier choco', 'Padma Chocolat']

const MultipleSelect = () => {
    const [personName, setPersonName] = React.useState<string[]>([])

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: { value },
        } = event
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="multiple-checkbox-label">Marques</InputLabel>
                <Select
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Marques" />}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

const PureCarrefourForm = (props: FormikProps<FormValues> & FormProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [brand, setBrand] = useState('')

    const handleChange = (event: SelectChangeEvent) => {
        setBrand(event.target.value as string)
    }

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
        }
    }, [isLoading])

    return (
        <Form style={{ width: '70vw', height: '100%' }}>
            <Grid container wrap="nowrap" alignItems="center">
                <Grid textAlign="center" padding={5} flex={1}>
                    <Grid>
                        <img
                            alt="Besoin d'aide"
                            src={Activated}
                            style={{ width: '18em', height: '15em' }}
                        />
                    </Grid>
                    <PrimaryText>
                        <a
                            rel="noopener noreferrer"
                            target={'_blank'}
                            style={{ color: DARK_GREY, fontSize: '16px' }}
                            href="https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed"
                        >
                            Besoin d'aide ?
                        </a>
                    </PrimaryText>
                </Grid>
                {isLoading ? (
                    <Grid flex={0.5}>
                        <CircularProgressWithLogoWithoutText />
                    </Grid>
                ) : (
                    <Grid flex={3}>
                        <Grid justifyContent="center" mt="5vh" flex={3}>
                            <PrimaryText>
                                Précisez le périmètre de vos données
                            </PrimaryText>
                            <Grid>
                                <Grid display="flex" gap="2vh">
                                    {inputs.map((input, index) => (
                                        <EnhancedField
                                            type={input.type}
                                            name={input.name}
                                            label={input.label}
                                            autoFocus={index === 0}
                                            margin="normal"
                                            component={TextInput}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                            <MultipleSelect />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <DialogFooter
                rightButtonFunc={(e) => {
                    e.stopPropagation()
                    props.config.handleNext()
                }}
                rightButtonText="Connecter"
                leftButtonFunc={(e) => {
                    e.stopPropagation()
                    props.config.handlePrevious()
                }}
                leftButtonText="Retour"
            />
        </Form>
    )
}

const ControlledCarrefourForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        startDate: '',
        endDate: '',
        brands: [''],
    }),
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureCarrefourForm)

const CarrefourDataFiltersStep = ({ config }: { config: ConfigProps }) => {
    const [isSubmit, setIsSubmit] = useState<boolean>(false)

    const handleSubmit = async (values: FormValues) => {
        setIsSubmit(true)
    }

    return (
        <ControlledCarrefourForm
            config={config}
            onSubmit={handleSubmit}
            isSubmit={isSubmit}
        />
    )
}

export default CarrefourDataFiltersStep
