import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import client from '../../api/config'
import { ProductTour, ProductTourState } from './productsTourType'

const initialState: ProductTourState = {
    productTour: null,
}

const productsTourSlice = createSlice({
    name: 'product-tour',
    initialState,
    reducers: {
        setProductTour: (state, action: PayloadAction<ProductTour>) => {
            state.productTour = action.payload
        },
    },
})

export const { setProductTour } = productsTourSlice.actions
export default productsTourSlice.reducer

export const fetchProductsTour =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            await client
                .get('/product-tour')
                .then((response) => {
                    dispatch(setProductTour(response.data))
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                })
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const createProductsTourLog =
    (message: string, productTourId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            await client
                .post('/activities/product-tour', {
                    message: message,
                    productTourId: productTourId,
                })
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                })
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }
