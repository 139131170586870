import React from 'react'
import Filters, {
    FiltersType,
} from '../../../components/features/filters/Filters'
import { useAppSelector } from '../../../redux/hooks'
import Stores from './Auchan/storesPage/AuchanStoresPage'
import PrivatePage from '../../../components/features/GlobalPages/Private/PrivatePage'
import { listOfRetailDistributors } from './types'
import CarrefourStoresPage from './Carrefour/storesPage/Stores'
import AuchanStoresPage from './Auchan/storesPage/AuchanStoresPage'

export const StoresPage = () => {
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)

    const getRetailStoresPageContent = (envName: string) => {
        switch (envName) {
            case listOfRetailDistributors.CARREFOUR:
                return <CarrefourStoresPage />
            case listOfRetailDistributors.AUCHAN:
                return <CarrefourStoresPage />
            default:
                return <CarrefourStoresPage />
        }
    }

    return (
        <PrivatePage title="Points de vente" attribute={0} period>
            <Filters
                filtersType={FiltersType.RETAIL}
                filters={activeRetailFilters}
            />
            {getRetailStoresPageContent(env.name)}
        </PrivatePage>
    )
}
