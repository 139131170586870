import React, { useEffect, useCallback, useState } from 'react'
import { Grid } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { ActiveFsCrossFilters } from '../../../../../redux/filters/filtersTypes'
import { NoDataScreen } from '../../../../../components/features/screens/NoDataScreen'
import { EnvStatus, UsersStatus } from '../../../../../redux/user/userTypes'
import { fetchFilteredDashboard } from '../../../../../redux/global/globalSlice'
import { StandaloneScreen } from '../../../../../components/features/screens/StandAloneScreen'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import WaitingScreen from '../../../../../components/features/screens/WaitingScreen'

const FsCrossDashboardLayout = ({ children }: { children: JSX.Element }) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dispatch = useAppDispatch()
    const dashboard = useAppSelector(
        (state) => state.global.fsCrossDashboardData
    )
    const { activeFsCrossFilters } = useAppSelector((state) => state.filters)
    const { isLoading, unit, env } = useAppSelector((state) => state.global)
    const { status, brand } = useAppSelector((state) => state.user.user)

    const _fetchFsDashboard = useCallback(
        async (filters: ActiveFsCrossFilters) => {
            await dispatch(fetchFilteredDashboard(filters, false))
        },
        [dispatch]
    )

    if (status === UsersStatus.STANDALONE) {
        return <StandaloneScreen />
    }

    useEffect(() => {
        if (brand && activeFsCrossFilters.startDate) {
            _fetchFsDashboard(activeFsCrossFilters)
            setIsFirstLoad(false)
        } else if (activeFsCrossFilters.startDate === '') {
            setIsFirstLoad(false)
        }
    }, [brand, activeFsCrossFilters, _fetchFsDashboard, unit])

    if (
        isLoading ||
        isFirstLoad ||
        (!dashboard && env.status !== EnvStatus.ON_LOADING_INIT)
    ) {
        return <Loading />
    }

    if (
        !dashboard ||
        !activeFsCrossFilters.startDate ||
        env.status === EnvStatus.ON_LOADING_INIT
    ) {
        return <WaitingScreen />
    }

    if (dashboard.productsTopFlop.length === 0) {
        return <NoDataScreen />
    }
    return <Grid>{children}</Grid>
}
export default FsCrossDashboardLayout
