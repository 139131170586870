import React from 'react'
import { Routes } from 'react-router-dom'
import SignIn from '../../../../pages/public/authentication/SignIn'
import SignUp from '../../../../pages/public/authentication/signUp/SignUpFlow'
import PasswordRecovery from '../../../../pages/public/authentication/PasswordRecovery'
import PageNotFound from '../../GlobalPages/PageNotFound'
import createRoute from './createRoute'

export interface routerType {
    title: string
    path: string
    element: JSX.Element
}

const PublicNavigation = () => {
    return <Routes>{createRoute(publicpages)}</Routes>
}

export default PublicNavigation

const publicpages: routerType[] = [
    {
        path: '/',
        element: <SignIn />,
        title: 'signin',
    },
    {
        path: 'signup',
        element: <SignUp />,
        title: 'signup',
    },
    {
        path: 'recovery',
        element: <PasswordRecovery />,
        title: 'recovery',
    },
    {
        path: '*',
        element: <PageNotFound />,
        title: '*',
    },
]
