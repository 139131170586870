import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchProductsTour } from '../../../../redux/product-tour/productsTourSlice'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import {
    EnvName,
    FsMetroKPIs,
    UsersStatus,
} from '../../../../redux/user/userTypes'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { DashboardLayout } from '../layout/DashboardLayout'
import { DashboardFSPage } from '../Pages/DashboardFSPage'
import { FFRColumnsDashboard } from '../utils'
import { ProductTourDialog } from '../../../../components/features/ProductTourDialog'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import { ConfigKPIs } from '../Pages/Dashboard/FsKpis'
import { Units } from '../../../../redux/global/globalTypes'

export const KPIsMetroConfig = ({
    KPIs,
    envName,
    unit
}: {
    KPIs: FsMetroKPIs
    envName: EnvName
    unit: Units
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: unit === Units.uvc ? Units.uvc : Units.kg,
            description: `Volume total livré sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
        },
        {
            id: 2,
            name: 'Dépôts',
            units: Units.warehouses,
            description: `Nombre total de dépôts ayant distribué au moins une de vos références. et variation par rapport à l'année précédente.`,
            number: KPIs.warehouses,
            difference: KPIs.warehouses - KPIs.warehousesLastYear,
            route: `/food-service/${envName}/depots`,
        },
        {
            id: 3,
            name: 'VMM par dépôt',
            units: unit === Units.uvc ? Units.uvcPerWarehouse : Units.kgPerWarehouse,
            description: `Ventes moyennes mensuelles : indication en volume de la rotation de vos produits et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.vmm),
            difference: KPIs.vmm - KPIs.vmmLastYear,
            route: `/food-service/${envName}/depots`,
        },
        {
            id: 4,
            name: 'Références distribuées',
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
        },
    ]

const DashboardMetro = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { productTour } = useAppSelector((state) => state.productsTour)
    const { status } = useAppSelector((state) => state.user.user)
    const { unit } = useAppSelector((state) => state.global)
    const dispatch = useAppDispatch()
    const _fetchProductsTour = useCallback(async () => {
        await dispatch(fetchProductsTour())
    }, [dispatch])

    useEffect(() => {
        _fetchProductsTour()
    }, [])

    return (
        <PrivatePage
            title="Tableau de bord"
            period={status != UsersStatus.STANDALONE}
        >
            {status != UsersStatus.STANDALONE && (
                <Filters
                    filtersType={FiltersType.FS}
                    filters={activeFsFilters}
                />
            )}
            <DashboardLayout>
                <DashboardFSPage
                    KPIsConfig={KPIsMetroConfig}
                    warehousesConfig={FFRColumnsDashboard(unit)}
                    drilldownTypeClientSegmentation={DrillDownType.CLIENTSMETRO}
                    drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSESMETRO}
                    dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
                    segmentationChartTitle={`Segmentation utilisateurs finaux en part de CA (${Units.euro})`}
                />
            </DashboardLayout>
            {productTour && (
                <ProductTourDialog
                    title={productTour.title}
                    productTourId={productTour._id}
                />
            )}
        </PrivatePage>
    )
}

export default DashboardMetro
