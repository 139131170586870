import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import DashboardFFR from '../../../../../../pages/private/Foodservice/FFR/DashboardFFR'
import { CustomersPageFFR } from '../../../../../../pages/private/Foodservice/FFR/CustomersPage'
import { WarehousesPageFFR } from '../../../../../../pages/private/Foodservice/FFR/WarehousesPage'
import { ProductsPageFFR } from '../../../../../../pages/private/Foodservice/FFR/ProductsPage'
import { ViewPageFFR } from '../../../../../../pages/private/Foodservice/FFR/ViewPage'
import { PromoPageFFR } from '../../../../../../pages/private/Foodservice/FFR/PromoPage'

export const FranceFraisRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardFFR />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageFFR />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'depots/',
        element: <WarehousesPageFFR />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'produits/',
        element: <ProductsPageFFR />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPageFFR />,
        title: PagesName.VIEWS,
    },
    ...(modules.includes(KaryonModules.PROMO)
        ? [
              {
                  path: 'promo/',
                  element: <PromoPageFFR />,
                  title: PagesName.PROMO,
              },
          ]
        : []),
]
