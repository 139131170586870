import React, { useCallback, useState } from 'react'
import {
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Popover,
    Tooltip,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { BLACK, DARK_BLUE, LIGHT_BLUE } from '../../ui/Colors'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { deleteView, setupViewsUpdate } from '../../../redux/views/viewsSlice'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import YesNoDialog from '../Dialog/YesNoDialog'
import { PrimaryText } from '../../ui/Text'
import { type ViewConfig, ViewsSteps } from '../../../redux/views/viewsTypes'
import ViewsUpdateDialog from '../views/ViewsUpdateDialog'
import { EnvType } from '../../../redux/user/userTypes'
import SendIcon from '@mui/icons-material/Send';
import ShareDialog from '../views/ShareDialog'
import Avatar from '../Avatar'

interface PopoverProps {
    anchor: Element
    handleClose: (e: React.MouseEvent) => void
    viewId: string
    env: EnvType
    distributor: string,
}

export interface ViewNavigationData {
    id: string
    label: string
    link: string
    icon: JSX.Element
    env: EnvType
    distributor: string
    shared?: boolean
    usersToShare?: string[]
    creator: {
        firstname: string
        lastname: string
        email: string
    }
}

interface OwnProps {
    item: ViewNavigationData
    isActive: boolean
}

const ItemActive = {
    background: LIGHT_BLUE,
    '#textItemsViewMenu': { paddingRight: 0 },
    borderRadius: '5px',
    '& span': {
        color: DARK_BLUE,
        fontWeight: 'bold',
    },
    '& svg': {
        color: DARK_BLUE,
    },
}

const ItemInactive = {
    background: 'transparent',
    '#textItemsViewMenu': { paddingRight: 0 },
    borderRadius: '5px',
    '& svg': {
        color: BLACK,
    },
}

const hasAction = {
    paddingRight: '48px !important',
}

const DeleteAction = ({
    id,
    env,
    distributor,
}: {
    id: string
    env: EnvType
    distributor: string
}) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const _deleteView = useCallback(
        async (viewId: string) => await dispatch(deleteView(viewId)),
        [dispatch]
    )

    const handleDeletion = () => {
        _deleteView(id).then(() => {
            navigate(
                env === EnvType.FOOD_SERVICE_CROSS ? '/food-service/cross/dashboard' : `/${env}/${distributor}/dashboard`
            )
        })
    }

    return (
        <ListItemButton
            onClick={(e) => {
                e.stopPropagation()
                setOpen(true)
            }}
            sx={{ p: 0, pt: '1vh', pb: '1vh' }}
        >
            <ListItemIcon
                sx={{
                    minWidth: '1vw',
                    '& svg': {
                        width: { xs: '15px', md: '2.5vw' },
                        height: { xs: '22px', md: '2.5vh' },
                    },
                }}
            >
                <DeleteForeverIcon sx={{ color: BLACK }} />
            </ListItemIcon>
            <PrimaryText color={BLACK}>Supprimer</PrimaryText>
            <YesNoDialog
                handleClose={(e) => {
                    setOpen(false)
                }}
                isOpen={open}
                yes={() => {
                    handleDeletion()
                    setOpen(false)
                }}
                no={() => {
                    setOpen(false)
                }}
            />
        </ListItemButton>
    )
}

const UpdateAction = ({ id }: { id: string }) => {
    const dispatch = useAppDispatch()
    const { openUpdate } = useAppSelector((state) => state.views)
    const { env } = useAppSelector((state) => state.global)
    const _setupViewsUpdate = useCallback(
        (viewConfig: ViewConfig) =>
            dispatch(setupViewsUpdate({ config: viewConfig })),
        [dispatch]
    )

    const handleClick = () => {
        if (!openUpdate) {
            if (env.type === EnvType.FOOD_SERVICE_CROSS) {
                _setupViewsUpdate({
                    steps: [ViewsSteps.FsCrossSettings, ViewsSteps.FsCrossFilters],
                })
            }
            else if (env.type === EnvType.FOOD_SERVICE) {
                _setupViewsUpdate({
                    steps: [ViewsSteps.FsSettings, ViewsSteps.FsFilters],
                })
            } else {
                _setupViewsUpdate({
                    steps: [
                        ViewsSteps.RetailSettings,
                        ViewsSteps.RetailFilters,
                    ],
                })
            }
        }
    }

    return (
        <ListItemButton
            onClick={(e) => {
                e.stopPropagation()
                handleClick()
            }}
            sx={{ p: 0, pt: '1vh', pb: '1vh' }}
        >
            <ListItemIcon
                sx={{
                    minWidth: '1vw',
                    '& svg': {
                        width: { xs: '22px', md: '2.5vw' },
                        height: { xs: '22px', md: '2.5vh' },
                    },
                }}
            >
                <EditIcon sx={{ color: BLACK }} />
            </ListItemIcon>
            <PrimaryText color={BLACK}> Éditer </PrimaryText>
            <ViewsUpdateDialog id={id} />
        </ListItemButton>
    )
}


const ShareAction = ({
    id,
}: {
    id: string
}) => {
    const [open, setOpen] = useState<boolean>(false)

    return (
        <ListItemButton
            onClick={(e) => {
                e.stopPropagation()
                setOpen(true)
            }}
            sx={{ p: 0, pt: '1vh', pb: '1vh' }}
        >
            <ListItemIcon
                sx={{
                    minWidth: '1vw',
                    '& svg': {
                        width: { xs: '22px', md: '2.5vw' },
                        height: { xs: '22px', md: '2.5vh' },
                    },
                }}
            >
                <SendIcon sx={{ color: BLACK }} />
            </ListItemIcon>
            <PrimaryText color={BLACK}> Partager </PrimaryText>
            <ShareDialog
                viewId={id}
                handleClose={(e) => {
                    setOpen(false)
                }}
                isOpen={open}
            />
        </ListItemButton>
    )
}


const ActionsPopover = ({
    anchor,
    handleClose,
    viewId,
    env,
    distributor,
}: PopoverProps) => {
    const open = Boolean(anchor)

    return (
        <Popover
            anchorEl={anchor}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={{ ml: '1vw', mt: '-1.5vh' }}
        >
            <List dense sx={{ padding: '1vh', mr: '0.6vw' }}>
                <ListItem disablePadding>
                    <UpdateAction id={viewId} />
                </ListItem>
                <ListItem disablePadding>
                    <ShareAction id={viewId} />
                </ListItem>
                <ListItem disablePadding>
                    <DeleteAction
                        id={viewId}
                        env={env}
                        distributor={distributor}
                    />
                </ListItem>
            </List>
        </Popover>
    )
}

const ViewsNavigationItemLabel = ({ view, isShared }: { view: ViewNavigationData, isShared: boolean }) => {
    return (
        <Grid container wrap='nowrap' alignItems="center" justifyContent="space-between" position="relative">
            <PrimaryText color={BLACK} noWrap >
                {view.label}
            </PrimaryText>
            {isShared &&
                <Grid container width="auto">
                    <Avatar xs name={view.creator.firstname} />
                </Grid>
            }
        </Grid>
    )
}

const ViewsNavigationItem = ({ item, isActive }: OwnProps) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [isHover, setHover] = useState(false)
    const { email } = useAppSelector((state) => state.user.user)

    const isShared = item.shared && item.creator.email != email
    const tooltipTitle = isShared ? `Vue partagée par ${item.creator.firstname} ${item.creator.lastname}` : ""

    const visibility = isHover && !isShared || Boolean(anchorEl) && !isShared

    return (
        <Tooltip title={tooltipTitle} placement='right'>
            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                onMouseOver={() => {
                    setHover(true)
                }}
                onMouseLeave={() => {
                    setHover(false)
                }}
                sx={{ postion: 'relative' }}
            >
                <Grid width="100%">
                    <Link
                        to={item.link}
                        key={item.id}
                        style={{ textDecoration: 'none', color: BLACK }}
                    >
                        <ListItem
                            sx={isActive ? ItemActive : ItemInactive}
                            disablePadding
                            onClick={(e) => {
                                e.stopPropagation
                            }}
                        >
                            <ListItemButton
                                id="textItemsViewMenu"
                                sx={visibility ? hasAction : { paddingRight: "16px !important" }}
                            >
                                <ViewsNavigationItemLabel view={item} isShared={isShared} />
                            </ListItemButton>
                        </ListItem>
                        {!isShared && <ActionsPopover
                            anchor={anchorEl}
                            handleClose={(e) => {
                                e.stopPropagation()
                                setHover(false)
                                setAnchorEl(null)
                            }}
                            viewId={item.id}
                            env={item.env}
                            distributor={item.distributor}
                        />}
                    </Link>
                </Grid>

                <MoreVertIcon
                    id="viewListItem"
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                    }}
                    visibility={visibility ? 'visibility' : 'hidden'}
                    sx={{
                        right: 0,
                        width: '2.2vw',
                        height: '2.2vh',
                        position: 'absolute',
                        cursor: 'pointer',
                    }}
                />
            </Grid>
        </Tooltip>
    )
}

export default ViewsNavigationItem
