import React from 'react'
import LabelCustomer from './LabelCustomer'
import { labels } from './LabelsCustomer'
import { Grid } from '@mui/material'
import { SquareColor } from '../../../../../components/ui/ColorSquare'
import { SegmentationType } from '../../../../../redux/fsProducts/fsProductsTypes'

export interface List {
    data: number[]
    labels: string[]
    percentage: number[]
}
export interface ListLabelsCustomerProps {
    list: List
    toggleData: (a: number, b: string, c: string) => void
    hideItem: (a: string, b: SegmentationType, c: number) => boolean
    toggleNumber: number
    type: SegmentationType
    index?: number
}

export const sum = (accumulator: number, curr: number) => accumulator + curr

const round = (value, step) => {
    step || (step = 1.0)
    const inv = 1.0 / step
    const res = Math.round(value * inv) / inv
    return res
}

const formatItemText = (
    label: string,
    list: List,
    labelIndex: number
): string => {
    const labelToDisplay = label.toUpperCase()
    const percentageToDisplay =
        list.percentage[labelIndex] < 0.01
            ? list.percentage[labelIndex] < 0.001
                ? round(list.percentage[labelIndex], 0.0001)
                : round(list.percentage[labelIndex], 0.001)
            : list.percentage[labelIndex].toFixed(2)
    return `${labelToDisplay} : ${percentageToDisplay} %`
}

export const ListLabelsCustomer = ({
    list,
    toggleData,
    hideItem,
    toggleNumber,
    type,
    index,
}: ListLabelsCustomerProps) => {
    return (
        <Grid container ml={3} flexDirection="column" flex={1}>
            {list.labels.map((label, labelIndex) => {
                if (!label) {
                    label = 'Autres'
                }

                return (
                    <LabelCustomer
                        key={label}
                        clickHandler={() => {
                            toggleData(toggleNumber, label, type)
                        }}
                        label={formatItemText(label, list, labelIndex)}
                        color={
                            labels[toggleNumber].backgroundColor[
                                (labelIndex + index * 10) % 22
                            ]
                        }
                        size={SquareColor.SM}
                        hide={hideItem(label, type, toggleNumber)}
                    />
                )
            })}
        </Grid>
    )
}
