import { Box, Fade, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import React, { useState } from 'react'
import { array, object, string } from 'yup'
import { AltButton, PrimaryButton } from '../../../../../components/ui/Button'
import { BLACK, PRIMARY, WHITE } from '../../../../../components/ui/Colors'
import ErrorMessage from '../../../../../components/ui/ErrorMessage'
import { EnhancedField, TextInput } from '../../../../../components/ui/Inputs'
import { type StepperData } from '../../../../../redux/stepper/stepperTypes'
import { PrimaryText } from '../../../../../components/ui/Text'
import {
    retailDistributor,
    MINIMUM_CHARACTER_FOR_OTHERS,
} from '../../../../../helpers/distributorsList'
import DistributerSelector from '../../../../../components/features/DistributerSelector'
import { StepContentComponent } from '../stepper/Stepper'

interface FormValues {
    yesnoRetail: string
    distributerRetail: string[]
    otherRetail: string
    dataRetail: string[]
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    initialValues: FormValues
}

export const hasOthers = (
    distributer: string[],
    distributorsList: string[]
) => {
    const others = distributer.filter(
        (distributor) => !distributorsList.includes(distributor)
    )

    return others.length > 0
}

const PureSignUpRetailForm = (
    props: FormikProps<FormValues> & FormProps
): JSX.Element => {
    const isYes = props.values.yesnoRetail === 'Yes'
    const isNo = props.values.yesnoRetail === 'No'

    // Two next lines allows us to display the distributor list with the others the user just added
    const otherDistributors = props.values.distributerRetail.filter(
        (distributor) => !retailDistributor.includes(distributor)
    )
    const distributorListToDisplay = [
        ...retailDistributor,
        ...otherDistributors,
    ]

    const [listDistributorsSelected, setListDistributorsSelected] = useState([])

    const toggleDistributer = (distributer: string) => {
        const isInclude = props.values.distributerRetail.includes(distributer)
        if (isInclude) {
            props.setFieldValue(
                'distributerRetail',
                props.values.distributerRetail.filter(
                    (el) => el !== distributer
                )
            )
        } else {
            props.setFieldValue('distributerRetail', [
                ...props.values.distributerRetail,
                distributer,
            ])
        }
    }

    const handleAddButton = ({ otherRetail }: { otherRetail: string }) => {
        if (!props.errors.otherRetail) {
            props.setFieldValue('otherRetail', props.initialValues.otherRetail)
            toggleDistributer(otherRetail)
            toggleData(otherRetail)
        }
    }
    const toggleData = (distributer: string) => {
        const isInclude =
            props.values.dataRetail.includes(distributer) ||
            props.values.otherRetail.includes(distributer)
        if (isInclude) {
            props.setFieldValue(
                'dataRetail',
                props.values.dataRetail.filter((el) => el !== distributer)
            )
        } else {
            setListDistributorsSelected([
                ...listDistributorsSelected,
                distributer,
            ])

            props.setFieldValue('dataRetail', [
                ...props.values.dataRetail,
                distributer,
            ])
        }
    }

    return (
        <Form>
            <Grid container direction="column">
                <Grid container alignItems="center">
                    <PrimaryText color={BLACK}>
                        Êtes-vous distribués en grande distribution ? (GMS, GSS,
                        indépendants...)
                    </PrimaryText>
                    <Grid container mt="2vh">
                        <Grid mr="2vw">
                            <AltButton
                                style={{
                                    backgroundColor: isYes ? PRIMARY : WHITE,
                                    color: isYes ? WHITE : PRIMARY,
                                }}
                                onClick={() => {
                                    props.setFieldValue('yesnoRetail', 'Yes')
                                }}
                            >
                                Oui
                            </AltButton>
                        </Grid>
                        <Grid>
                            <AltButton
                                style={{
                                    backgroundColor: isNo ? PRIMARY : WHITE,
                                    color: isNo ? WHITE : PRIMARY,
                                }}
                                onClick={() => {
                                    props.setFieldValue('yesnoRetail', 'No')
                                }}
                            >
                                Non
                            </AltButton>
                        </Grid>
                    </Grid>
                </Grid>
                {isYes && (
                    <Fade in={isYes}>
                        <Grid container alignItems="center">
                            <PrimaryText color={BLACK} bold mt={5}>
                                Sélectionnez vos distributeurs Retail
                            </PrimaryText>
                            <DistributerSelector
                                list={distributorListToDisplay}
                                values={props.values.distributerRetail}
                                selectorHandler={toggleDistributer}
                            />
                            <Grid
                                container
                                alignItems="center"
                                marginTop={3}
                                flexWrap="nowrap"
                            >
                                <Grid item>
                                    <EnhancedField
                                        label="Autre(s)"
                                        type="text"
                                        name="otherRetail"
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item ml={3}>
                                    <PrimaryButton
                                        disabled={
                                            props.values.otherRetail.length <
                                            MINIMUM_CHARACTER_FOR_OTHERS
                                        }
                                        type="button"
                                        onClick={() => {
                                            handleAddButton({
                                                otherRetail:
                                                    props.values.otherRetail,
                                            })
                                        }}
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fade>
                )}
                {props.values.distributerRetail.length >= 1 && (
                    <Grid container alignItems="center" mt={5}>
                        <Grid>
                            <PrimaryText color={BLACK} flex={2} mb={2}>
                                Lesquels vous partagent vos données ?
                            </PrimaryText>
                            <DistributerSelector
                                list={props.values.distributerRetail}
                                values={props.values.dataRetail}
                                selectorHandler={toggleData}
                            />
                        </Grid>
                    </Grid>
                )}
                {props.errors.yesnoRetail && (
                    <ErrorMessage msg={props.errors.yesnoRetail} />
                )}
                <Box mt={5} width="100%">
                    <PrimaryButton fullWidth type="submit">
                        Suivant
                    </PrimaryButton>
                </Box>
            </Grid>
        </Form>
    )
}

const ControlledSignUpRetailForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props: FormProps) => ({
        yesnoRetail: props.initialValues.yesnoRetail,
        distributerRetail: props.initialValues.distributerRetail,
        otherRetail: props.initialValues.otherRetail,
        dataRetail: props.initialValues.dataRetail,
    }),
    validationSchema: () => {
        return object().shape({
            yesnoRetail: string().required('Le champ est obligatoire'),
            distributerRetail: array().of(string()),
            otherRetail: string()
                .min(
                    MINIMUM_CHARACTER_FOR_OTHERS,
                    `Le nom du distributeur doit contenir au moins ${MINIMUM_CHARACTER_FOR_OTHERS} caractère`
                )
                .optional(),
            dataRetail: array().of(string()).optional(),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureSignUpRetailForm)

const SignUpRetail = ({
    getData,
    updateData,
    handleNext,
}: StepContentComponent) => {
    const initialValues = getData() as unknown as FormValues

    const handleSubmit = (values: FormValues) => {
        updateData({
            ...values,
            hasOthersRetail: hasOthers(
                values.distributerRetail,
                retailDistributor
            ),
        } as unknown as StepperData)
        handleNext()
    }

    return (
        <Grid container direction="column">
            <ControlledSignUpRetailForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        </Grid>
    )
}

export default SignUpRetail
