import React from 'react'
import StandAloneIllustration from '../../../assets/standalone_illustration.svg'
import { Grid, Paper } from '@mui/material'
import { BLACK, PRIMARY } from '../../ui/Colors'
import { PrimaryText, Title } from '../../ui/Text'

export const StandaloneScreen = () => {
    return (
        <Paper
            sx={{
                width: 'calc(100% - 48px)',
                height: '92vh',
                margin: 'auto',
                marginTop: '1vw',
            }}
        >
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                height={'100%'}
                wrap="nowrap"
            >
                <img
                    alt="Chargement"
                    src={StandAloneIllustration}
                    style={{ width: '35vw' }}
                />
                <Title>Données en cours d’intégration</Title>
                <Grid
                    container
                    alignItems="center"
                    direction="column"
                    justifyContent="space-between"
                    height="15vh"
                >
                    <PrimaryText mt={3} color={BLACK}>
                        Vous serez notifié.e par email dès qu’elles sont
                        disponibles dans votre espace KaryonFood !
                    </PrimaryText>
                    <PrimaryText color={BLACK}>
                        Besoin d'aide ? Accédez à la{' '}
                        <a
                            href="https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed"
                            style={{ color: PRIMARY, fontWeight: 'bold' }}
                            target={'_blank'}
                            rel="noreferrer noopener"
                        >
                            Boîte à outils
                        </a>
                    </PrimaryText>
                </Grid>
            </Grid>
        </Paper>
    )
}
