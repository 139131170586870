import { Grid } from '@mui/material'
import React from 'react'
import Inactivated from '../../../../../assets/inactivated.svg'
import { PrimaryText } from '../../../../../components/ui/Text'

const LoadInit = () => {
    return (
        <Grid container height="100%" alignItems="center" mt="4vh" mb="4vh">
            <Grid item flex={1} textAlign="center">
                <img width="60%" src={Inactivated} />
            </Grid>
            <Grid item flex={1}>
                <PrimaryText>
                    La création de votre environnement est en cours !
                </PrimaryText>
                <PrimaryText>
                    Vous serez notifié(e) par mail dès qu'il sera accessible.
                </PrimaryText>
            </Grid>
        </Grid>
    )
}

export default LoadInit
