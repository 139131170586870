import { Grid } from '@mui/material'
import React from 'react'
import { Title } from '../ui/Text'

export enum TextAlignement {
    LEFT = 'left',
    RIGHT = 'right',
}

interface OwnProps {
    title: string
    subtitle: string | JSX.Element
    align?: TextAlignement
    children: JSX.Element
}

const PublicForm = ({ title, subtitle, align, children }: OwnProps) => {
    const alignement = align || 'center'
    return (
        <Grid container direction="column">
            <Grid item mb={8} textAlign={alignement}>
                <Grid item>
                    <Title>{title}</Title>
                </Grid>
                <Grid item>{subtitle}</Grid>
            </Grid>
            <Grid item>{children}</Grid>
        </Grid>
    )
}

export default PublicForm
