import React from 'react'
import { useAppSelector } from '../../../../redux/hooks'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { ProductsLayout } from '../layout/ProductsLayout'
import FsProductsPage from '../Pages/FsProductsPage'
import { CercleVertColumnsProductsPage } from '../utils'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'

export const ProductsPageCercleVert = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage title="Références" attribute={0} period>
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} />
            <ProductsLayout>
                <FsProductsPage
                    columns={CercleVertColumnsProductsPage}
                    displayToggle={true}
                    lineChartTitle={`Volume livré par référence (kg)`}
                    drillDownType={DrillDownType.PRODUCTSCERCLEVERT}
                />
            </ProductsLayout>
        </PrivatePage>
    )
}
