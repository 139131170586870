import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useCallback, useState } from 'react'
import { ClientItem } from '../../../../redux/fsProducts/fsProductsTypes'
import {
    setDrillDownProducts,
    setDrillDownWarehouses,
} from '../../../../redux/fsProducts/fsProductsSlice'
import DrillDown from '../../../../components/features/drillDowns/DrillDown'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import React from 'react'
import { Grid } from '@mui/material'
import ChartBlock from '../../../../components/features/ChartBlock'
import DoughnutChartCustomer from './CustomerPage/DoughnutChartCustomer'
import { DataTableComponent } from '../../DataTableComponent'
import { EnvName } from '../../../../redux/user/userTypes'
import { Units } from '../../../../redux/global/globalTypes'

const Customers = ({
    envName,
    columns,
    titles,
    drillDownType,
}: {
    envName: string
    columns: GridColDef[]
    titles: { doughnutTitle: string; dataGridTitle: string }
    drillDownType: DrillDownType
}) => {
    const [isOpen, setOpen] = useState(false)
    const [fieldSelected, setFieldSelected] =
        useState<GridCellParams<ClientItem>>()
    const [value, setValue] = useState(0)

    const { clients } = useAppSelector((state) => state.fsProducts)
    const { unit } = useAppSelector((state) => state.global)
    const { env } = useAppSelector((state) => state.global)


    const dispatch = useAppDispatch()

    const _setDrillDownWarehouses = useCallback(() => {
        dispatch(setDrillDownWarehouses([]))
    }, [dispatch])

    const _setDrillDownProducts = useCallback(() => {
        dispatch(setDrillDownProducts([]))
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        _setDrillDownProducts()
        _setDrillDownWarehouses()
    }

    return (
        <Grid
            container
            direction="column"
            sx={{
                '& .MuiDataGrid-row:hover #tendency': {
                    visibility: 'visible',
                },
            }}
            p="1vw"
            flexWrap="nowrap"
        >
            <DrillDown
                type={drillDownType}
                open={isOpen}
                title={fieldSelected && `${fieldSelected.row.id}`}
                value={value}
                setValue={setValue}
                row={fieldSelected}
                handleClose={handleClose}
            />
            <Grid item>
                {clients && (
                    <ChartBlock title={`${titles.doughnutTitle} (${env.name === EnvName.METRO ? Units.euro : unit})`}>
                        <DoughnutChartCustomer envName={envName} />
                    </ChartBlock>
                )}
            </Grid>
            <Grid item mt="1vw" height="47vh">
                {clients && (
                    <ChartBlock
                        title={`${titles.dataGridTitle} (${clients.list.length})`}
                    >
                        <DataTableComponent
                            setOpen={setOpen}
                            setFieldSelected={setFieldSelected}
                            setValue={setValue}
                            type={drillDownType}
                            columns={columns}
                            rows={clients.list}
                            getRowId={(row) => (row.type ? row.id : 'Autres')}
                        />
                    </ChartBlock>
                )}
            </Grid>
        </Grid >
    )
}
export default Customers
