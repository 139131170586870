import React from 'react'
import { useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import { FsPromoPage } from '../Pages/PromotionPage/page/FsPromoPage'
import { KPIsTGMConfig } from './DashboardTGM'
import { PromoLayout } from '../layout/PromoLayout'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'

export const PromoPageTGM = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage period title="Promotions">
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} />
            <PromoLayout>
                <FsPromoPage
                    promoGraphTitle={
                        'Corrélation promo et vol moyen par transaction'
                    }
                    sellInGraphTitle={
                        'Corrélation prix net et vol moyen par transaction'
                    }
                    KPIsConfig={KPIsTGMConfig}
                    promoGraphCurveDataName={
                        'Volume sell-out par transaction (kg)'
                    }
                    sellInGraphCurveDataName={
                        'Volume sell-out par transaction (kg)'
                    }
                    drillDownType={DrillDownType.PROMOTGM}
                />
            </PromoLayout>
        </PrivatePage>
    )
}
