export enum listOfFSDistributors {
    FRANCE_FRAIS = 'France Frais',
    TRANSGOURMET = 'Transgourmet',
    TRANSGOURMETPLUS = 'Transgourmet plus',
    METRO = 'Metro',
    EPISAVEURS = 'EpiSaveurs',
    SODEXO = 'Sodexo',
    PASSIONFROID = 'PassionFroid',
    CERCLEVERT = 'Cercle vert'
}
