import React from 'react'
import NoResult from '../../../assets/no-result.svg'
import { Grid, Paper } from '@mui/material'
import { BLACK, PRIMARY } from '../../ui/Colors'
import { PrimaryText, Title } from '../../ui/Text'

export const NoDataScreen = () => {
    return (
        <Paper
            sx={{
                width: 'calc(100% - 48px)',
                height: '86vh',
                margin: 'auto',
                marginTop: '1vw',
            }}
        >
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                height={'100%'}
                wrap="nowrap"
            >
                <img
                    alt="Chargement"
                    src={NoResult}
                    style={{ width: '35vw' }}
                />
                <Title>Aucun résultat</Title>
                <Grid
                    container
                    alignItems="center"
                    direction="column"
                    justifyContent="space-between"
                    height="15vh"
                >
                    <PrimaryText mt={3} color={BLACK}>
                        La combinaison des filtres sélectionnée ne permet pas
                        d'afficher de résultat,{' '}
                        <strong>modifiez vos filtres.</strong>
                    </PrimaryText>

                    <PrimaryText color={BLACK}>
                        Besoin d'aide ? Accédez à la{' '}
                        <a
                            href="https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed"
                            style={{ color: PRIMARY, fontWeight: 'bold' }}
                            target={'_blank'}
                            rel="noreferrer noopener"
                        >
                            Boîte à outils
                        </a>
                    </PrimaryText>
                </Grid>
            </Grid>
        </Paper>
    )
}
