import { Grid } from '@mui/material'
import { type FormikBag, type FormikProps, withFormik } from 'formik'
import React, { useState } from 'react'
import { object, string } from 'yup'
import Activated from '../../../../../../assets/activated.svg'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { PrimaryText, SubTitle } from '../../../../../../components/ui/Text'
import {
    EnhancedField,
    TextInput,
} from '../../../../../../components/ui/Inputs'
import { DARK_GREY, WHITE } from '../../../../../../components/ui/Colors'

interface FormValues {
    code: string
}

interface FFRFormProps {
    onSubmit: (values: FormValues) => void
    isSubmit: boolean
}

const PureFranceFraisForm = (props: FormikProps<FormValues> & FFRFormProps) => {
    return (
        <Grid container justifyContent="center" width="40vw" mt="5vh">
            <Grid container wrap="nowrap" alignItems="center" mb="5vh">
                <Grid item flex={1} textAlign="center">
                    <img
                        alt="une dame près d'un arbre"
                        src={Activated}
                        style={{ width: '60%' }}
                    />
                </Grid>

                <Grid flex={1}>
                    {!props.isSubmit ? (
                        <Grid container width="20vw">
                            <PrimaryText>
                                Saisissez votre code fournisseur France Frais :
                            </PrimaryText>
                            <Grid>
                                <EnhancedField
                                    type="text"
                                    name="code"
                                    label="Code"
                                    autoFocus={true}
                                    margin="normal"
                                    component={TextInput}
                                />
                            </Grid>
                            <PrimaryText>
                                Ce connecteur autorise KaryonFood à recevoir vos
                                données.
                            </PrimaryText>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid>
                                <SubTitle>
                                    Votre requête de connexion a bien été
                                    envoyée ! Vous serez notifié(e) dès que le
                                    flux de données sera établi.
                                </SubTitle>
                            </Grid>
                            <Grid
                                container
                                sx={{
                                    background: DARK_GREY,
                                    marginTop: '20px',
                                    width: '12vw',
                                    padding: '1vh',
                                    borderRadius: '8px',
                                    color: WHITE,
                                }}
                            >
                                <AccessTimeIcon />
                                <PrimaryText ml="5px" color={WHITE}>
                                    Connexion en cours
                                </PrimaryText>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <PrimaryText>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ color: DARK_GREY, fontSize: '20px' }}
                    href="https://karyon.notion.site/Bo-te-outils-00b5bdf8fe194eedaca2c798c4e791ed"
                >
                    Besoin d'aide ?
                </a>
            </PrimaryText>
        </Grid>
    )
}

const ControlledFranceFraisForm = withFormik<FFRFormProps, FormValues>({
    mapPropsToValues: () => ({
        code: '',
    }),
    validationSchema: () => {
        return object().shape({
            code: string().required('Le champ Code est obligatoire'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FFRFormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(PureFranceFraisForm)

const FranceFraisInactivated = () => {
    const [isSubmit, setIsSubmit] = useState<boolean>(false)

    const handleSubmit = async (values: FormValues) => {
        console.log('Handle FFR connexion : ', values)
        setIsSubmit(true)
    }

    return (
        <ControlledFranceFraisForm
            onSubmit={handleSubmit}
            isSubmit={isSubmit}
        />
    )
}

export default FranceFraisInactivated
