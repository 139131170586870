import { combineReducers } from '@reduxjs/toolkit'
import filtersSlice from './filters/filtersSlice'
import fsProductsSlice from './fsProducts/fsProductsSlice'
import globalSlice from './global/globalSlice'
import importsSlice from './imports/importsSlice'
import mapSlice from './map/mapSlice'
import retailProductsSlice from './retailProducts/retailProductsSlice'
import stepperSlice from './stepper/stepperSlice'
import userSlice from './user/userSlice'
import viewsSlice from './views/viewsSlice'
import contactSlice from './contact/contactSlice'
import exportsSlice from './export/exportsSlice'
import companiesSlice from './companies/companiesSlice'
import notificationsSlice from './notifications/notificationsSlice'
import productsTourSlice from './product-tour/productsTourSlice'
import invitationsSlice from './invitations/invitationsSlice'

const rootReducer = combineReducers({
    global: globalSlice,
    user: userSlice,
    imports: importsSlice,
    map: mapSlice,
    views: viewsSlice,
    filters: filtersSlice,
    stepper: stepperSlice,
    fsProducts: fsProductsSlice,
    retailProducts: retailProductsSlice,
    contacts: contactSlice,
    exports: exportsSlice,
    notifications: notificationsSlice,
    companies: companiesSlice,
    productsTour: productsTourSlice,
    invitations: invitationsSlice
})

export default rootReducer
