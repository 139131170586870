import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import { useAppSelector } from '../../../../redux/hooks'
import { listOfFSDistributors } from '../types'
import { MetroColumnsCustomersPage } from '../utils'
import Customers from '../Pages/FsCustomersPage'
import { CustomersLayout } from '../layout/CustomersLayout'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'

export const CustomersPageMetro = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    const filters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        min: activeFsFilters.min,
        max: activeFsFilters.max,
        products: [],
        clients: [],
        regions: [],
        categories: [],
    }

    return (
        <PrivatePage title="Utilisateurs finaux" period>
            <Filters
                filtersType={FiltersType.FS}
                filters={filters}
                page={PagesName.CLIENTS}
            />
            <CustomersLayout>
                <Customers
                    envName={listOfFSDistributors.METRO}
                    columns={MetroColumnsCustomersPage()}
                    titles={{
                        doughnutTitle: `Segmentation d'utilisateurs finaux en part de CA`,
                        dataGridTitle: `Liste des types d'utilisateurs finaux`,
                    }}
                    drillDownType={DrillDownType.CLIENTSMETRO}
                />
            </CustomersLayout>
        </PrivatePage>
    )
}
