import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import DashboardSodexo from '../../../../../../pages/private/Foodservice/Sodexo/DashboardSodexo'
import { CustomersPageSodexo } from '../../../../../../pages/private/Foodservice/Sodexo/CustomersPage'
import { ProductsPageSodexo } from '../../../../../../pages/private/Foodservice/Sodexo/ProductsPage'
import { ViewPageSodexo } from '../../../../../../pages/private/Foodservice/Sodexo/ViewPage'

export const SodexoRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardSodexo />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageSodexo />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'produits/',
        element: <ProductsPageSodexo />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPageSodexo />,
        title: PagesName.VIEWS,
    },
]
