import React, { useCallback, useEffect, useState } from 'react'
import { Dialog } from '@mui/material'
import { DialogHeader } from '../Dialog/BasicDialog'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { fetchCompany } from '../../../redux/companies/companiesSlice'
import UsersToShareStep from './stepShareView/UsersToShareStep'
import AddMessageStep from './stepShareView/AddMessageStep'
import ValidationStep from './stepShareView/ValidationStep'

export interface ConfigProps {
    handleNext: () => void
    handlePrevious: () => void
    handleClose: (e: any) => void
    handleReset: () => void,
    updateData: (payload: ShareViewData) => void,
    getData: () => ShareViewData,
    viewId: string
}

export interface ShareViewData {
    usersToShare: string[],
    description: string
}

const initialData: ShareViewData = {
    usersToShare: [],
    description: ""
}

const stepsSync: Record<
    string,
    (config: ConfigProps) => JSX.Element
> = {
    [0]: (config: ConfigProps) => (
        <UsersToShareStep {...config} />
    ),
    [1]: (config: ConfigProps) => (
        <AddMessageStep {...config} />
    ),
    [2]: (config: ConfigProps) => (
        <ValidationStep {...config} />
    ),
}


interface OwnProps {
    handleClose: (e) => void
    isOpen: boolean
    viewId: string
}

const ShareDialog = ({ handleClose, isOpen, viewId }: OwnProps) => {
    const dispatch = useAppDispatch()
    const { views } = useAppSelector((state) => state.views)
    const [stepData, setStepData] = useState<ShareViewData>(initialData)
    const [activeStep, setActiveStep] = useState<number>(0)

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }
    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
        setStepData(initialData)
    }

    const updateData = (payload: ShareViewData) => {
        setStepData((prevState: ShareViewData) => ({ ...prevState, ...payload }))
    }

    const getData = () => {
        return stepData
    }

    const _fetchCompany = useCallback(
        async () => await dispatch(fetchCompany()),
        [dispatch]
    )

    useEffect(() => {
        _fetchCompany()
    }, [])

    const StepComponent = stepsSync[activeStep]

    const view = views.filter((el) => el._id === viewId)[0]

    return (
        <Dialog
            maxWidth="lg"
            onClose={(e: any) => {
                e.stopPropagation()
                handleClose(e)
            }}
            open={isOpen}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    width: '300vw !important',
                    padding: '1em',
                    height: '50vh',
                },
            }}
        >
            <DialogHeader
                title={`Partager la vue "${view.name}" ?`}
                setOpen={(e) => {
                    handleClose(e)
                }}
            />
            {StepComponent({
                handleNext,
                handlePrevious,
                handleClose,
                handleReset,
                updateData,
                getData,
                viewId
            })}
        </Dialog>
    )
}

export default ShareDialog
