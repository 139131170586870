import { ExportData } from '../../../redux/export/exportsTypes'
import { StepperData } from '../../../redux/stepper/stepperTypes'
import { Env } from '../../../redux/user/userTypes'
import FsExportRawSelectStep from './FsExportRawSelectStep'
import MultiExportDistributorsStep from './MultiExportDistributorsStep'
import MultiExportEnvChoiceStep from './MultiExportEnvChoiceStep'
import MultiExportFiltersStep from './MultiExportFiltersStep'
import ReasonOfExportStep from './ReasonOfExportStep'
import RetailExportFiltersStep from './RetailExportFiltersStep'
import TypeOfExportStep from './TypeOfExportStep'

interface ExportStepConfig {
    component: (props: ExportComponentProps) => JSX.Element
}

export interface StepExportConfig {
    handleNext: () => void
    handlePrevious: () => void
    handleSubmit: (values: ExportData) => void
    updateData: (payload: StepperData) => void
    handleClose:
        | ((e?: MouseEvent) => void)
        | ((e?: React.MouseEvent<Element, MouseEvent>) => void)

    getData: () => StepperData
}

export interface ExportComponentProps {
    config: StepExportConfig
    step: number
    env?: Env
}

export const retailEnvStepConfig: ExportStepConfig[] = [
    {
        component: TypeOfExportStep,
    },
    {
        component: RetailExportFiltersStep,
    },
    {
        component: ReasonOfExportStep,
    },
]

export const fsEnvStepConfig: ExportStepConfig[] = [
    {
        component: TypeOfExportStep,
    },
    {
        component: FsExportRawSelectStep,
    },
    {
        component: ReasonOfExportStep,
    },
]

export const multiEnvStepConfig: ExportStepConfig[] = [
    {
        component: MultiExportEnvChoiceStep,
    },
    {
        component: TypeOfExportStep,
    },
    {
        component: MultiExportDistributorsStep,
    },
    {
        component: MultiExportFiltersStep,
    },
    {
        component: ReasonOfExportStep,
    },
]
