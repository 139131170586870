import { DialogContent, Grid } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import React, { useState, useEffect, useCallback } from 'react'
import { array, object, string } from 'yup'
import { useAppDispatch } from '../../../redux/hooks'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import { ExportComponentProps, StepExportConfig } from './ExportSetup'
import Loading from '../screens/LoadingScreen'
import { fetchListExport } from '../../../redux/export/exportsSlice'
import { useAppSelector } from '../../../redux/hooks'
import { ListExport } from '../../../redux/export/exportsTypes'
import {
    DataGrid,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
} from '@mui/x-data-grid'
import { PrimaryText, SecondaryText } from '../../ui/Text'
import { BLACK } from '../../ui/Colors'
import ErrorMessage from '../../ui/ErrorMessage'

interface FormValues {
    filename: string[]
}

interface test {
    id: string
    filename: string
    period: string
}

interface FormProps {
    config: StepExportConfig
    step: number
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
    isLoading: boolean
    list: ListExport
}

export const periodsList = {
    q1: 'Janvier à mars',
    q2: 'Avril à juin',
    q3: 'Juillet à septembre',
    q4: 'Octobre à décembre',
    '01': 'Janvier',
    '02': 'Février',
    '03': 'Mars',
    '04': 'Avril',
    '05': 'Mai',
    '06': 'Juin',
    '07': 'Juillet',
    '08': 'Août',
    '09': 'Septembre',
    '10': 'Octobre',
    '11': 'Novembre',
    '12': 'Décembre',
}

const columns = (): GridColDef[] => {
    return [
        {
            field: 'filename',
            headerName: 'Nom',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'period',
            headerName: 'Période',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                const months = params.value.slice(0, 2)
                const year = params.value.slice(2, 6)

                return (
                    <SecondaryText color={BLACK}>
                        {`${periodsList[months]} ${year}`}
                    </SecondaryText>
                )
            },
            sortable: false,
        },
    ]
}

const PureFSExportFilterStepForm = ({
    setFieldValue,
    submitForm,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    const [modified, setModified] = useState(false)

    const handleSelection = (ids) => {
        const selectedRowsData = ids.map((id) => {
            const selectedlist = props.list.find((row) => row.id === id)
            return selectedlist.filename
        })

        setFieldValue('filename', selectedRowsData)
    }

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '32vh' }}>
                {props.submitCount > 0 && props.errors.filename && (
                    <Grid item mb="1vh" display="flex" justifyContent="center">
                        <ErrorMessage msg={props.errors.filename as string} />
                    </Grid>
                )}
                {props.isLoading ? (
                    <Grid container alignItems="center" height="100%">
                        <Loading text={false} height="100%" />
                    </Grid>
                ) : (
                    <DataGrid
                        rows={props.list}
                        columns={columns()}
                        experimentalFeatures={{ newEditingApi: false }}
                        hideFooter={true}
                        disableColumnMenu={true}
                        getRowId={(row) => row.filename}
                        checkboxSelection
                        onSelectionModelChange={(ids) => handleSelection(ids)}
                    />
                )}
            </DialogContent>
            <DialogFooter
                leftButtonFunc={(e) => {
                    props.config.handlePrevious()
                }}
                leftButtonText={'PRECEDENT'}
                rightButtonFunc={(e) => {
                    e.stopPropagation()

                    submitForm()
                }}
                rightButtonText={'SUIVANT'}
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        filename: [],
        period: '',
    }),
    validationSchema: () => {
        return object().shape({
            filename: array()
                .of(string().required())
                .required()
                .min(1, 'Sélectionnez au moins un fichier à télécharger'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureFSExportFilterStepForm)

const FsExportRawSelectStep = ({ step, config }: ExportComponentProps) => {
    const [isLoading, setLoading] = useState<boolean>(true)
    const dispatch = useAppDispatch()
    const { listExport } = useAppSelector((state) => state.exports)
    const distributor = useAppSelector((state) => state.global.env.name)

    const _fetchListExport = useCallback(async () => {
        await dispatch(fetchListExport(distributor))
    }, [dispatch])

    useEffect(() => {
        _fetchListExport().then(() => setLoading(false))
    }, [])

    const handleSubmit = (values: FormValues) => {
        config.updateData({
            filename: values.filename,
        })
        config.handleNext()
    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            list={listExport}
        />
    )
}

export default FsExportRawSelectStep
