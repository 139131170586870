import React from 'react'
import { Grid } from '@mui/material'
import ColorSquare, {
    ColorSquareProps,
} from '../../../../../components/ui/ColorSquare'
import { PrimaryText } from '../../../../../components/ui/Text'
import { BLACK } from '../../../../../components/ui/Colors'

export interface OwnProps extends ColorSquareProps {
    label: string
}

const LineChartLabel = ({ label, color, size }: OwnProps) => {
    return (
        <Grid
            container
            alignItems="center"
            style={{ cursor: 'default' }}
            wrap="nowrap"
        >
            <ColorSquare color={color} size={size} />
            <PrimaryText color={BLACK} ml="0.5vw">
                {label}
            </PrimaryText>
        </Grid>
    )
}

export default LineChartLabel
