import { Dialog } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
    closeViewCreator,
    handleViewCreation,
} from '../../../redux/views/viewsSlice'
import {
    type StepConfig,
    ViewsSteps,
    ResponseViewsCreation,
} from '../../../redux/views/viewsTypes'
import { DialogHeader } from '../Dialog/BasicDialog'
import FiltersStep from './steps/FiltersStep'
import FsSettingsStep from './steps/FsSettingsStep'
import RetailFiltersStep from './steps/RetailFiltersStep'
import RetailSettingsStep from './steps/RetailSettingStep'
import FsCrossViewsFiltersForm from './steps/FsCrossFiltersStep'
import FsCrossSettingsStep from './steps/FsCrossSettingsStep'
import { EnvType } from '../../../redux/user/userTypes'

const stepsSync: Record<
    string,
    (step: number, config: StepConfig) => JSX.Element
> = {
    [ViewsSteps.FsFilters]: (step: number, config: StepConfig) => (
        <FiltersStep step={step} config={config} />
    ),
    [ViewsSteps.FsSettings]: (step: number, config: StepConfig) => (
        <FsSettingsStep step={step} config={config} />
    ),
    [ViewsSteps.RetailFilters]: (step: number, config: StepConfig) => (
        <RetailFiltersStep step={step} config={config} />
    ),
    [ViewsSteps.FsCrossFilters]: (step: number, config: StepConfig) => (
        <FsCrossViewsFiltersForm step={step} config={config} />
    ),
    [ViewsSteps.FsCrossSettings]: (step: number, config: StepConfig) => (
        <FsCrossSettingsStep step={step} config={config} />
    ),
    [ViewsSteps.RetailSettings]: (step: number, config: StepConfig) => (
        <RetailSettingsStep step={step} config={config} />
    ),
}

const ViewsDialog = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { openAdd, config } = useAppSelector((state) => state.views)
    const { env } = useAppSelector((state) => state.global)
    const _closeViewCreator = useCallback(
        () => dispatch(closeViewCreator()),
        [dispatch]
    )
    const _handleCreation = useCallback(
        async () => await dispatch(handleViewCreation()),
        [dispatch]
    )
    const [activeStep, setActiveStep] = useState<number>(0)

    const handleClose = () => {
        _closeViewCreator()
    }

    const handleReset = () => {
        _closeViewCreator()
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = () => {
        if (activeStep === config.steps.length - 1) {
            handleSubmit()
        } else {
            setActiveStep(activeStep + 1)
        }
    }

    const handleSubmit = () => {
        _handleCreation().then((view: ResponseViewsCreation) => {
            navigate(
                env.type === EnvType.FOOD_SERVICE_CROSS ? `/food-service/cross/vues/${view.insertedId}` : `/${view.environment}/${view.distributor}/vues/${view.insertedId}`,
            )
        })
        setActiveStep(0)
    }

    const StepComponent = stepsSync[config.steps[activeStep]]

    return (
        <Dialog
            open={openAdd}
            fullWidth={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader
                title="Que souhaitez-vous observer ?"
                setOpen={handleClose}
            />
            {StepComponent(activeStep, {
                handleNext,
                handlePrevious,
                handleReset,
                handleSubmit,
                stepsNumber: config.steps.length,
            })}
        </Dialog>
    )
}

export default ViewsDialog
