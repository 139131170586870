import React from 'react'
import { FieldAttributes } from 'formik'
import { BLACK, GREY, LIGHT_BLUE, LIGHT_GREY, PRIMARY } from '../../ui/Colors'
import { Grid, SxProps, Tooltip } from '@mui/material'
import { ViewTypes } from '../../../redux/views/viewsTypes'
import { OptionsChoicesItem } from './OptionsChoicesItem'
import { EnvType } from '../../../redux/user/userTypes'
import { ExportTypes } from '../../../redux/export/exportsTypes'

export interface TypeOfItem {
    id: number
    label: string
    icon: JSX.Element
    type: ViewTypes | ExportTypes | EnvType
    locked: boolean
    tooltip?: string
}

export interface TypeItemProps {
    item: TypeOfItem
    length: number
}

export interface ItemStyle extends React.CSSProperties {
    background: string | number
    border: string
    height: string
    borderRadius: string
    textAlign: any
    padding: string
    '& p'?: { color: string }
}

const selected = {
    background: LIGHT_BLUE,
    border: `3px solid ${PRIMARY}`,
    minHeight: '15vh',
    minWidth: '10vw',
    borderRadius: '1em',
    textAlign: 'center',
    padding: '0.5vh 0.5vw 0.5vh 0.5vw ',
    fontSize: '1.2vmin',
    '& p': { color: BLACK },
}

const itemAvailable = {
    background: LIGHT_BLUE,
    border: `3px solid ${GREY}`,
    minHeight: '15vh',
    minWidth: '10vw',
    borderRadius: '1em',
    textAlign: 'center',
    padding: '0.5vh 0.5vw 0.5vh 0.5vw ',
    fontSize: '1.2vmin',
    '& p': { color: BLACK },
}

const locked: React.CSSProperties = {
    background: LIGHT_GREY,
    border: `1px solid ${GREY}`,
    minHeight: '15vh',
    minWidth: '10vw',
    borderRadius: '1em',
    textAlign: 'center',
    padding: '0.5vh 0.5vw 0.5vh 0.5vw ',
    fontSize: '1.2vmin',
}

const getItemStyle = (item: TypeOfItem, value: String): SxProps => {
    const style = item.locked
        ? locked
        : value === item.type
        ? selected
        : itemAvailable
    return style
}

export const OptionsChoicesBox = ({
    item,
    value,
    length,
    setValue,
    ...props
}: FieldAttributes<any> & TypeItemProps) => {
    const handleSelection = () => {
        if (props.form.values[`${props.field.name}`] === '') {
            setValue(`${props.field.name}`, value)
        } else if (props.form.values[`${props.field.name}`] === value) {
            setValue(`${props.field.name}`, '')
        } else if (
            props.form.values[`${props.field.name}`] !== props.field.value
        ) {
            setValue(`${props.field.name}`, value)
        }
    }

    return item.tooltip ? (
        <Tooltip title={item.tooltip}>
            <Grid item flex={1} mr={item.id !== length ? '2vw' : '0vw'}>
                <OptionsChoicesItem
                    item={item}
                    style={getItemStyle(
                        item,
                        props.form.values[`${props.field.name}`]
                    )}
                />
            </Grid>
        </Tooltip>
    ) : (
        <Grid item flex={1} mr={item.id !== length ? '2vw' : '0vw'}>
            <OptionsChoicesItem
                handleSelection={handleSelection}
                item={item}
                style={getItemStyle(
                    item,
                    props.form.values[`${props.field.name}`]
                )}
            />
        </Grid>
    )
}
