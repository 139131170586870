import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useCallback, useState } from 'react'
import { WarehouseItem } from '../../../../redux/fsProducts/fsProductsTypes'
import {
    setDrillDownClients,
    setDrillDownProducts,
} from '../../../../redux/fsProducts/fsProductsSlice'
import React from 'react'
import { Grid } from '@mui/material'
import DrillDown from '../../../../components/features/drillDowns/DrillDown'
import ChartBlock from '../../../../components/features/ChartBlock'
import { DataTableComponent } from '../../DataTableComponent'

const FsWarehousesPage = ({
    columns,
    drillDownType,
}: {
    columns: GridColDef[]
    drillDownType: DrillDownType
}) => {
    const dispatch = useAppDispatch()

    const { warehouses } = useAppSelector((state) => state.fsProducts)
    const [open, setOpen] = useState(false)
    const [fieldSelected, setFieldSelected] =
        useState<GridCellParams<WarehouseItem>>()
    const [value, setValue] = useState(0)

    const _setDrillDownProducts = useCallback(() => {
        dispatch(setDrillDownProducts([]))
    }, [dispatch])

    const _setDrillDownClients = useCallback(() => {
        dispatch(setDrillDownClients([]))
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        _setDrillDownProducts()
        _setDrillDownClients()
    }

    return (
        <Grid>
            <DrillDown
                type={drillDownType}
                open={open}
                title={fieldSelected && `${fieldSelected.row.warehouse}`}
                value={value}
                setValue={setValue}
                row={fieldSelected}
                handleClose={handleClose}
            />

            <Grid
                container
                direction="column"
                sx={{
                    height: '90vh',
                    '& .MuiDataGrid-row:hover #tendency': {
                        visibility: 'visible',
                    },
                }}
                p="1vw"
                flexWrap="wrap"
            >
                <Grid flex={2}>
                    {warehouses && (
                        <ChartBlock
                            title={`Liste des dépôts (${warehouses.list.length})`}
                        >
                            <DataTableComponent
                                type={drillDownType}
                                setOpen={setOpen}
                                setFieldSelected={setFieldSelected}
                                setValue={setValue}
                                columns={columns}
                                rows={warehouses.list}
                                getRowId={(row) => row.id}
                            />
                        </ChartBlock>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FsWarehousesPage
