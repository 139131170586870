import { Inputs } from '../../../public/authentication/signUp/steps/SignUpAccount'

interface EnhancedInput extends Inputs {
    id: number
    list?: string | string[]
    disabled?: boolean
}

interface InputsSection {
    id?: number
    title: string
    inputs: EnhancedInput[]
}

export const inputsProfil: InputsSection = {
    title: 'Informations personnelles',
    inputs: [
        {
            id: 0,
            type: 'email',
            name: 'email',
            label: 'Adresse email',
            disabled: true,
        },
        {
            id: 2,
            type: 'text',
            name: 'firstname',
            label: 'Prénom',
        },
        {
            id: 3,
            type: 'text',
            name: 'lastname',
            label: 'Nom',
        },
        { id: 4, type: 'tel', name: 'phoneNumber', label: 'Téléphone' },
        { id: 5, type: 'text', name: 'job', label: 'Poste' },
    ],
}

export const inputsMissions: InputsSection = {
    title: 'Mes missions',
    inputs: [
    ],
}

export const menuForUser = [
    'Informations personnelles',
    'Mes missions',
]
