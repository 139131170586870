import React from 'react'
import { Grid } from '@mui/material'
import { Field } from 'formik'
import { OptionsChoicesBox, TypeOfItem } from './OptionsChoicesBox'

export const OptionsChoiceComponent = ({
    listOfChoice,
    setValue,
    name,
}: {
    listOfChoice: TypeOfItem[]
    setValue: (field: string, value: any, shouldValidate?: boolean) => void
    name: string
}) => {
    return (
        <Grid container justifyContent="space-between" wrap="nowrap">
            {listOfChoice.map((type, index) => (
                <Field
                    key={`${type}${index}`}
                    type="radio"
                    name={name}
                    item={type}
                    value={type.type}
                    length={listOfChoice.length}
                    component={OptionsChoicesBox}
                    setValue={setValue}
                />
            ))}
        </Grid>
    )
}
