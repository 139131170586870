import React, { useState } from 'react'
import { useAppSelector } from '../../../../../../redux/hooks'
import { Grid } from '@mui/material'
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import Kpis from '../../../../../../components/features/Kpis'
import { Units } from '../../../../../../redux/global/globalTypes'
import { BasicCard } from '../../../../../../components/features/BasicCard';
import { EvolSellOutNumber, IncrementalNumber, KpiIcon, NumberCard } from '../../../../../../components/features/NumberCard';
import { TertiaryButton } from '../../../../../../components/ui/Button';
import ContactDialog from '../../../../Global/Import/dialog/contact/ContactDialog';
import { DialogData } from '../../../../Global/Import/Import';
import { EnvName, EnvStatus } from '../../../../../../redux/user/userTypes';
import { StockageComponent } from './StockageComponent';

interface FSPromoKPIs {
    volumeSellOut: number
    differenceSellOut: number
    evolutionSellOut: number

    volumeSellIn: number
    differenceSellIn: number
    evolutionSellIn: number

    volumePromo: number
    differencePromo: number
    evolutionPromo: number

    budgetTotal?: number
    budgetInvest: number
    percentageBudget: number
}

export enum KpiType {
    NUMBER = "number",
    GAUGE = "gauge"
}

export interface StockageChart {
    sellOut: number,
    sellInGlobal: number,
    sellInPromo: number
}
interface ImpactData {
    promoWeight: number,
    differencePromoWeight: number,
    stockagePercentage: number,
    globalTendency: number,
    sellOutVolume: number,
    sellInGlobal: number,
    sellInPromo: number,
    incremental: number,
    differenceSellOut: number,
    evolSellOut: number,
    chart: StockageChart
}


interface PerformanceData {
    UF: number,
    differenceUF: number,
    evolUF: number,
    basket: number,
    differenceBasket: number,
    evolBasket: number,
    chart: {
        labels: string[],
        data: number[],
        volume: number[],
        evolVolume: number[],
        clients: number[],
        evolClients: number[],
    }
}

interface CostData {
    CACUF: number,
    differenceCACUF: number,
    evolCACUF: number,
    investPerKg: number,
    differenceInvestPerKg: number,
    evolInvestPerKg: number
}

const dataTest = {
    volumeSellOut: 530009,
    differenceSellOut: 4754,
    evolutionSellOut: 0.8,

    volumeSellIn: 537535,
    differenceSellIn: 24189,
    evolutionSellIn: 1.8,

    volumePromo: 142961,
    differencePromo: -6429,
    evolutionPromo: -3.4,

    budgetTotal: 154975,
    budgetInvest: 107521,
    percentageBudget: 70
}

const impact = {
    promoWeight: 26.6,
    differencePromoWeight: 3,
    stockagePercentage: 19,
    globalTendency: 17,
    sellOutVolume: 50,
    sellInGlobal: 110,
    sellInPromo: 80,
    incremental: 3000,
    differenceSellOut: 4754,
    evolSellOut: 0.8,
    chart: {
        sellOut: 3000,
        sellInGlobal: 2000,
        sellInPromo: 4500
    }
}

const performance = {
    UF: 19796,
    differenceUF: 429,
    evolUF: 5,
    basket: 6.70,
    differenceBasket: -0.08,
    evolBasket: -0.02,
    chart: {
        labels: ["RS", "RC"],
        data: [70, 30],
        volume: [50000, 4333],
        evolVolume: [12.8, 25],
        clients: [200, 10],
        evolClients: [25.4, 24]
    }
}

const cost = {
    CACUF: 250.6,
    differenceCACUF: 5.58,
    evolCACUF: 2.2,
    investPerKg: 35.8,
    differenceInvestPerKg: -0.7,
    evolInvestPerKg: -2
}

export const KPIsConfig = (
    KPIs: FSPromoKPIs
) => [
        {
            id: 1,
            type: KpiType.NUMBER,
            name: 'Volume sell-in promo',
            icon: <DiscountOutlinedIcon color='primary' />,
            units: Units.kg,
            description: `Volume sell-in promotionné livré au distributeur et son évolution par rapport à A-1.`,
            number: KPIs.volumePromo,
            difference: KPIs.differencePromo,
            evolution: KPIs.evolutionPromo
        },
        {
            id: 2,
            type: KpiType.NUMBER,
            name: 'Volume sell-in global',
            icon: <LoginOutlinedIcon color='primary' />,
            units: Units.kg,
            description: `Volume sell-in total livré au distributeur et son évolution par rapport à A-1.`,
            number: KPIs.volumeSellIn,
            difference: KPIs.differenceSellIn,
            evolution: KPIs.evolutionSellIn
        },
        {
            id: 3,
            type: KpiType.NUMBER,
            name: 'Volume sell-out',
            icon: <LogoutOutlinedIcon color='primary' />,
            units: Units.kg,
            description: `Volume sell-out total distribué par le distributeur (sortie de dépôt) et son évolution par rapport à A-1.`,
            number: KPIs.volumeSellOut,
            difference: KPIs.differenceSellOut,
            evolution: KPIs.evolutionSellOut
        },
        {
            id: 4,
            type: KpiType.GAUGE,
            name: 'Budget',
            units: Units.euro,
            description: `Budget total déclaré et budget investi dans de la promotion sur la période A.`,
            number: KPIs.budgetTotal,
            difference: KPIs.budgetInvest,
            evolution: KPIs.percentageBudget
        },
    ]

export const impactCardConfig = (impact: ImpactData) => [
    {
        id: 1,
        title: "Poids promo",
        subtitle: "Sell-in en promo sur sell-in global",
        component: <NumberCard number={impact.promoWeight} difference={impact.differencePromoWeight} unit={Units.percent} icon={KpiIcon.UF} />,
        flex: "0 0 20%",
    },
    {
        id: 2,
        title: "Stockage",
        subtitle: "Suivi de l'effet d'aubaine générant des stocks en dépôt.",
        component: <StockageComponent chart={impact.chart} labelGlobal="Sell-in global (kg)" labelPart={"Sell-in promo (kg)"} labelSecondBard={"Sell-out (kg)"} stockagePercentage={impact.stockagePercentage} globalTendency={impact.globalTendency} ></StockageComponent>,
        flex: "1 1",
    },
    {
        id: 3,
        title: "Contribution à la croissance",
        subtitle: "Impact sur les volumes en sell-out.",
        component: <Grid container direction="row" width="100%" wrap="nowrap"><IncrementalNumber number={impact.incremental} unit={Units.kg} text='Incrémental sell-out' /><EvolSellOutNumber number={impact.differenceSellOut} difference={impact.evolSellOut} unit={Units.kg} text='Evo sell-out A-1' /></ Grid>,
        flex: "1 1 100%",
    },

]

export const performanceCardConfig = (performance: PerformanceData) => [
    {
        id: 1,
        title: "Répartition d'utilisateurs finaux (UF)",
        subtitle: "Répartition des sell-out par typologie d’utilisateur final.",
        component: <></>,
        flex: "1 1 100%",
    },
    {
        id: 2,
        title: "Evolution nombre d’utilisateurs final (UF)",
        subtitle: "Evolution de ma distribution numérique (DN).",
        component: <NumberCard number={performance.UF} difference={performance.differenceUF} evol={performance.evolUF} unit={Units.UF} icon={KpiIcon.UF} />,
        flex: "1 0",
    },
    {
        id: 3,
        title: "Evolution du panier moyen mensuel",
        subtitle: "Poids du panier moyen mensuel en sortie entrepôt.",
        component: <NumberCard number={performance.basket} difference={performance.differenceBasket} evol={performance.evolBasket} unit={Units.kgPerUFPerMonth} icon={KpiIcon.VOLUME} />,
        flex: "1 0",
    },

]

export const costCardConfig = (cost: CostData) => [
    {
        id: 1,
        title: "Coût d'acquisition d'un utilisateur final (UF)",
        subtitle: "Investissement € par nouvel utilisateur final acheteur.",
        component: <NumberCard number={cost.CACUF} difference={cost.differenceCACUF} evol={cost.evolCACUF} unit={Units.euroPerUF} icon={KpiIcon.UF} />,
        flex: "1 1 ",
    },
    {
        id: 2,
        title: "Coût d’acquisition de volume d’affaires",
        subtitle: "Investissement € par kilo vendu sell-out.",
        component: <NumberCard number={cost.investPerKg} difference={cost.differenceInvestPerKg} evol={cost.evolInvestPerKg} unit={Units['€/kg']} icon={KpiIcon.VOLUME} />,
        flex: "1 0",
    },]

const kpis = KPIsConfig(dataTest)


const resetState = {
    isOpen: false,
    envName: EnvName.MYDISTRIB,
    status: EnvStatus.INACTIVATED,
}

export const PromoPageV2 = () => {
    const [isLoadingChart, setLoadingChart] = useState(true)
    const { isLoading } = useAppSelector((state) => state.global)
    //For ContactDialog
    const [dialogContactData, setDialogContactData] =
        useState<DialogData>(resetState)
    const openModaleContact = () => {
        setDialogContactData({ // TODO : Valider l'intérêt de reset l'env
            isOpen: true,
            envName: EnvName.MYDISTRIB,
            status: EnvStatus.INACTIVATED,
        })
    }

    const impactBloc = impactCardConfig(impact)
    const perfBloc = performanceCardConfig(performance)
    const costBloc = costCardConfig(cost)
    return (
        <Grid
            container
            direction="column"
            p="1vw"
        >
            <Grid container mb="3vh">
                <Kpis kpis={kpis} />
            </Grid>
            <Grid container flexWrap="nowrap" >
                <Grid direction="column" flex="60%">
                    <BasicCard title={'Impact'} subtitle="Quel est l'impact de la promo sur les ventes globales ?" flex={"0 1 50%"} >
                        <Grid container flexWrap="wrap">
                            {impactBloc.map((el) =>
                                <BasicCard title={el.title} subtitle={el.subtitle} flex={el.flex} >
                                    {el.component}
                                </BasicCard>

                            )}
                        </Grid>
                    </BasicCard>
                    <BasicCard title={'Coûts'} subtitle="Est-ce que mes investissements contribuent à ma croissance financière ?" flex={"0 1 50%"} >
                        <Grid container flexWrap="wrap">
                            {costBloc.map((el) =>
                                <BasicCard title={el.title} subtitle={el.subtitle} flex={el.flex}>
                                    {el.component}
                                </BasicCard>
                            )}
                        </Grid>
                    </BasicCard>
                    <TertiaryButton onClick={(e) => {
                        openModaleContact()
                    }} >
                        Demander un audit promo personnalisé
                    </TertiaryButton>
                    <ContactDialog
                        isOpen={dialogContactData.isOpen}
                        handleClose={() => {
                            setDialogContactData(resetState)
                        }}
                    />
                </Grid>
                <Grid direction="column">
                    <BasicCard title={'Performance'} subtitle="Comment ma promo contribue à ma croissance et quels sont mes leviers ?" flex={"1 1 "} >
                        <Grid container flexWrap="wrap" >
                            {perfBloc.map((el) =>
                                <BasicCard title={el.title} subtitle={el.subtitle} flex={el.flex}>
                                    {el.component}
                                </BasicCard>
                            )}
                        </Grid>
                    </BasicCard>


                    <BasicCard title={'Campagnes'} subtitle="Quelles sont les campagnes les plus et les moins performantes ?" flex={"1 1"} >
                        <Grid container flexWrap="wrap">

                        </Grid>
                    </BasicCard>
                </Grid>
            </Grid >
        </Grid >
    )
}
