import React from 'react'
import { DialogHeader } from '../Dialog/BasicDialog'
import { Dialog, DialogContent as DialogContentWrapper } from '@mui/material'
import { EnvType } from '../../../redux/user/userTypes'
import { FsDialogContent } from './FsDialogContent'
import { RetailDialogContent } from './RetailDialogContent'

interface OwnProps {
    handleClose: (e) => void
    isOpen: boolean
    type: EnvType
}

const AddDistributorDialog = ({ handleClose, isOpen, type }: OwnProps) => {
    return (
        <Dialog
            maxWidth={false}
            onClose={(e: any) => {
                e.stopPropagation()
                handleClose(e)
            }}
            open={isOpen}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    width: '70vw',
                    padding: '1em',
                },
            }}
        >
            <DialogHeader
                title="Ajouter des distributeurs"
                setOpen={(e) => {
                    handleClose(e)
                }}
            />
            <DialogContentWrapper>
                {type === EnvType.FOOD_SERVICE ? (
                    <FsDialogContent setOpen={handleClose} />
                ) : (
                    <RetailDialogContent setOpen={handleClose} />
                )}
            </DialogContentWrapper>
        </Dialog>
    )
}

export default AddDistributorDialog
