import {
    Env,
    FsCrossDashboard,
    FsDashboard,
    FsFFRKPIs,
    FsTGMKPIs,
    RetailDashboard,
} from '../user/userTypes'

export type KPIsTypes = FsFFRKPIs | FsTGMKPIs

export enum Units {
    kg = 'kg',
    uvc = 'UVC',
    uvcPerStorePerMonth = 'UVC / PDV / mois',
    none = '',
    references = 'Références',
    stores = 'Points de vente',
    percent = '%',
    '€/kg' = '€/kg',
    warehouses = 'Dépôts',
    clients = 'clients',
    kgPerWarehouse = 'kg/dépôt',
    uvcPerWarehouse = 'UVC/dépôt',
    uvcPerStore = "UVC/PDV",
    pdv = 'PDV',
    euro = '€',
    kgPerUFPerMonth = "kg / UF / Mois",
    UF = "UF",
    euroPerUF = "€ / UF",

}

export interface GlobalState {
    KPIs: KPIsTypes
    env: Env
    period: PeriodGap
    fsCrossDashboardData: FsCrossDashboard
    fsDashboardData: FsDashboard | null
    retailDashboardData: RetailDashboard | null
    isLoading: boolean
    error: null | string
    unit: Units
    isFiltered: boolean
}

export interface PeriodGap {
    min: string
    max: string
}
