interface WindowSize {
  width: number;
  height: number;
}

interface ExternalStore {
  size: WindowSize;
}

const store: ExternalStore = {
  size: {
    width: undefined,
    height: undefined,
  },
};

export const getResizeSnapshot = () => {
  if (
    store.size.height !== window.innerHeight ||
    store.size.width !== window.innerWidth
  ) {
    store.size = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }

  return store.size;
};

export const subscribeToResize = (callback) => {
  window.addEventListener("resize", callback);
  return () => window.removeEventListener("resize", callback);
};
