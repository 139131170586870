import { Grid } from '@mui/material'
import React from 'react'
import NoResult from '../../../../assets/no-result.svg'
import { PrimaryText } from '../../../ui/Text'

const NoResultsFilter = () => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            p="2vh 0"
        >
            <PrimaryText mb="2vh" bold>
                Pas de résultats
            </PrimaryText>
            <img
                style={{ width: '10vw' }}
                src={NoResult}
                alt="Pas de résultats"
            />
            <PrimaryText mt="2vh">Renouvelez votre recherche</PrimaryText>
        </Grid>
    )
}

export default NoResultsFilter
