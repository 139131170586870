import { Dialog } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DialogHeader } from '../Dialog/BasicDialog'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
    LogExportRawActivity,
    handleExportCreation,
} from '../../../redux/export/exportsSlice'
import { StepperData } from '../../../redux/stepper/stepperTypes'
import { Env, EnvType, UsersRole } from '../../../redux/user/userTypes'
import {
    fsEnvStepConfig,
    multiEnvStepConfig,
    retailEnvStepConfig,
} from './ExportSetup'
import { ExportData, MultiOrMono } from '../../../redux/export/exportsTypes'
import { exportToXlsx } from './exportXlsx'
import { ResponseExportRaw, getExportRawSupabase } from './getExportRawSupabase'

export const ExportDialog = ({
    isOpen,
    setOpen,
    env,
}: {
    isOpen: boolean
    setOpen: any
    env: Env
}) => {
    const [activeStep, setActiveStep] = useState<number>(0)

    const { config } = useAppSelector((state) => state.exports)
    const { role } = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _createExport = useCallback(
        (data: ExportData) => dispatch(handleExportCreation(data)),
        [dispatch]
    )
    const _LogExportRawActivity = useCallback(
        (data: ResponseExportRaw[], reason: string) =>
            dispatch(LogExportRawActivity(data, reason)),
        [dispatch]
    )
    const startStepData =
        env.type === EnvType.SETTING
            ? { multiOrMono: MultiOrMono.MULTI }
            : {
                  environment: `${env.type}`,
                  distributors: [`${env.name}`],
                  multiOrMono: MultiOrMono.MONO,
              }

    const [stepData, setStepData] = useState<StepperData>(startStepData)

    useEffect(() => {
        if (isOpen) {
            setActiveStep(0)
        }
    }, [isOpen])

    const getData = () => {
        return stepData
    }

    const updateData = (payload: StepperData) => {
        setStepData((prevState: StepperData) => ({ ...prevState, ...payload }))
    }

    const handleClose = () => {
        setOpen(false)

        setStepData(startStepData)
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const handleSubmit = async (values: ExportData) => {
        if (values.filename) {
            // Handle raw export
            const getFileFromSupabase = await getExportRawSupabase(
                values.filename
            )
            const logActivity = _LogExportRawActivity(
                getFileFromSupabase,
                values.reason
            )

            // Handle export Karyon
            // _createExport(values).then((response) => {
            //     exportToXlsx(
            //         response.exportedData.list,
            //         `Export data ${response.brand} ${stepData.distributors}`
            //     )
            // })
        }
        setActiveStep(0)
        handleClose()
    }

    const StepComponentData =
        env.type === 'setting'
            ? multiEnvStepConfig[activeStep]
            : env.type === 'retail'
            ? retailEnvStepConfig[activeStep]
            : fsEnvStepConfig[activeStep]

    const StepComponent = StepComponentData?.component

    const dialogTitle =
        env.type === 'setting'
            ? 'Exporter vos données'
            : `Exporter vos données ${env.name}`

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader title={dialogTitle} setOpen={handleClose} />
            {StepComponent && (
                <StepComponent
                    config={{
                        handleNext,
                        handlePrevious,
                        handleSubmit,
                        handleClose,
                        updateData,
                        getData,
                    }}
                    step={config.steps.length}
                    env={env}
                />
            )}
        </Dialog>
    )
}
