import { Grid } from '@mui/material'
import React, { useCallback } from 'react'
import ImportHistoric from '../ImportHistoric'
import {
    ConnectorType,
    Env,
    EnvName,
    EnvStatus,
    EnvType,
} from '../../../../../redux/user/userTypes'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { UpdateUserEnv } from '../../../../../redux/user/userSlice'
import { PrimaryText, SubTitle } from '../../../../../components/ui/Text'
import ImportUpload from '../ImportUpload'
import { EnvStatusChip } from '../../../../../components/features/import/EnvStatusChip'
import { BLACK } from '../../../../../components/ui/Colors'
import { DialogFooterOneAction } from '../../../../../components/features/Dialog/BasicFooterDialog'
import { useNavigate } from 'react-router-dom'

interface OwnProps {
    distributor: string
    type: EnvType
    status: EnvStatus
    lastImportDate: string
    connectorType: ConnectorType
}

const ManualActivated = ({
    distributor,
    type,
    status,
    lastImportDate,
    connectorType,
}: OwnProps) => {
    const updateDate = new Date(lastImportDate).toLocaleDateString('fr-FR')

    const targeted =
        type === EnvType.FOOD_SERVICE ? 'FsHistoric' : 'RetailHistoric'
    const historic = useAppSelector((state) => state.imports[targeted])
    const filteredHistoric = historic.list.filter(
        (el) => el.distributorName === distributor
    )
    const navigate = useNavigate()

    const user = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _updateUserEnv = useCallback(
        async (userId: string, env: Env) =>
            await dispatch(UpdateUserEnv(userId, env)),
        [dispatch]
    )

    //TO DO : make last date dynamic

    return (
        <Grid>
            <Grid container>
                <Grid
                    container
                    direction="column"
                    flexWrap="nowrap"
                    height="100%"
                >
                    <Grid container mb="4vh">
                        <Grid flex={3}>
                            <ImportUpload
                                actualDistrib={distributor}
                                actualEnv={type}
                                onUpload={async () =>
                                    await _updateUserEnv(user._id, {
                                        name: distributor as EnvName,
                                        type: type,
                                        status:
                                            // TODO : it's just for the demo
                                            distributor === EnvName.AUCHAN
                                                ? EnvStatus.ACTIVATED
                                                : EnvStatus.ON_LOADING,
                                        connectorType: connectorType,
                                    })
                                }
                            />
                        </Grid>
                        <Grid flex={2} height="100%" p={3}>
                            <PrimaryText>
                                Votre environnement {distributor} est actif.
                            </PrimaryText>
                            <Grid container alignItems="center" mt="2vh">
                                <Grid
                                    container
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <SubTitle bold>
                                        Statut des données :
                                    </SubTitle>
                                    <Grid marginLeft="1vw">
                                        <EnvStatusChip
                                            pointer={false}
                                            status={status}
                                            name={distributor}
                                            connectorType={connectorType}
                                            last={[]}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container mt="2vh">
                                    <SubTitle bold>
                                        Dernière mise à jour :
                                    </SubTitle>
                                    <PrimaryText color={BLACK} ml="1vw">
                                        22/05/2024
                                    </PrimaryText>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item height="100%">
                        <ImportHistoric list={filteredHistoric} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DialogFooterOneAction
                    text="Tableau de bord"
                    buttonFunc={() => {
                        navigate(`/${type}/${distributor}/dashboard`)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default ManualActivated
