import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { ActiveFsFilters } from '../../../../redux/filters/filtersTypes'
import {
    fetchFsPromo,
    fetchKPIs,
} from '../../../../redux/fsProducts/fsProductsSlice'
import NoSellInScreen from '../../../../components/features/screens/NoSellInScreen'
import { EnvStatus } from '../../../../redux/user/userTypes'
import Loading from '../../../../components/features/screens/LoadingScreen'

export const PromoLayout = ({ children }: { children: JSX.Element }) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const { isLoading, env } = useAppSelector((state) => state.global)

    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const dispatch = useAppDispatch()
    const _fetchPromoChart = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchFsPromo(filters))
        },
        [dispatch]
    )
    const promotions = useAppSelector((state) => state.fsProducts.promotions)

    const _fetchKPIs = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchKPIs(filters))
        },
        [dispatch]
    )

    useEffect(() => {
        if (activeFsFilters.startDate) {
            _fetchPromoChart(activeFsFilters)
            _fetchKPIs(activeFsFilters)
            setIsFirstLoad(false)
        }
    }, [activeFsFilters])

    if (
        isLoading ||
        isFirstLoad ||
        (!promotions && env.status !== EnvStatus.ON_LOADING_INIT)
    ) {
        return <Loading />
    }
    if (promotions && promotions.evolution[1] === null) {
        return <NoSellInScreen />
    }
    return <Grid>{children}</Grid>
}
