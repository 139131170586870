import React, { useEffect, useState } from 'react'
import {
    Box,
    Step,
    type StepIconProps,
    StepLabel,
    Stepper,
} from '@mui/material'
import { Check } from '@mui/icons-material'
import { DARK_BLUE, PRIMARY, WHITE } from '../../../../../components/ui/Colors'
import { PrimaryText } from '../../../../../components/ui/Text'

interface OwnProps {
    activeStep: number
    steps: string[]
    isValid?: boolean
    triggerDisabled?: number
    setActiveStep?: (step: number) => void
    save: Set<number>
}

const StepIcon = ({ active, completed }: StepIconProps) => {
    return (
        <Box
            sx={{
                ...{
                    background: 'transparent',
                    color: '#fff',
                    width: '1.5vw',
                    height: '1.5vw',
                    display: 'flex',
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                ...(active || completed
                    ? {
                          backgroundColor: DARK_BLUE,
                          color: WHITE,
                      }
                    : {}),
            }}
        >
            {active || completed ? (
                <Check sx={{ width: '1vw' }} />
            ) : (
                <Box
                    sx={{
                        width: '1.5vw',
                        height: '1.5vw',
                        borderRadius: '50%',
                        border: `1px solid ${PRIMARY}`,
                    }}
                />
            )}
        </Box>
    )
}

const StepperLayout = ({
    activeStep,
    steps = [],
    setActiveStep,
    isValid,
    triggerDisabled,
    save,
}: OwnProps) => {
    const isClickable = !!setActiveStep
    const [disabled, setDisabled] = useState<Set<number>>(new Set<number>())

    useEffect(() => {
        if (activeStep === triggerDisabled) {
            const newDisabled = new Set(save.values())
            setDisabled(newDisabled)
        }
    }, [activeStep, triggerDisabled, save])

    const handleGotoStep = (step: number) => {
        if (isClickable && (save.has(step) || isValid) && !disabled.has(step)) {
            setActiveStep(step)
        }
    }

    return (
        <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={(theme) => ({
                backgroundColor: 'transparent',
                '& .MuiStepLabel-root': {
                    padding: '1vh 0',
                },
                '& .MuiStepConnector-root': {
                    padding: 0,
                    marginLeft: '0.7vw',
                },
                '& .MuiStepConnector-line': {
                    borderColor: PRIMARY,
                    [theme.breakpoints.up('md')]: {
                        minHeight: '5vh',
                    },
                },
                '& .activeStep span': {
                    fontWeight: 'bold',
                },
                '& .disabledStep, .disabledStep span, .disabledStep p': {
                    cursor: 'unset !important',
                    color: 'red',
                    textDecoration: 'none !important',
                },
                '& .MuiStepLabel-iconContainer': {
                    paddingRight: '1vw',
                },
                '& .disabledStep .MuiStepLabel-iconContainer': {
                    opacity: '0.6',
                },
                '& .clickableLabel': {
                    cursor: 'pointer',
                },
                '& .timelineLabel': {
                    margin: 0,
                },
                '& .clickableLabel:hover .timelineLabel': {
                    textDecoration: 'underline',
                },
            })}
        >
            {steps.map((label, index) => (
                <Step key={label} completed={save.has(index)}>
                    <StepLabel
                        className={`${
                            isClickable && save.has(index)
                                ? 'clickableLabel'
                                : ''
                        } ${activeStep === index ? 'activeStep' : ''} ${
                            disabled.has(index) ? 'disabledStep' : ''
                        }`}
                        onClick={() => {
                            handleGotoStep(index)
                        }}
                        StepIconComponent={StepIcon}
                    >
                        <PrimaryText color={PRIMARY} className="timelineLabel">
                            {label}
                        </PrimaryText>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default StepperLayout
