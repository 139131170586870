import React, { useState } from 'react'
import FranceFraisInactivated from './autoInactivated/FranceFraisInactivated'
import { EnvName } from '../../../../../redux/user/userTypes'
import CarrefourInactivatedAuthentification from './autoInactivated/CarrefourInactivatedAuthentificiation'
import CarrefourInactivatedStatus from './autoInactivated/CarrefourInactivatedStatus'
import { Grid } from '@mui/material'
import CarrefourDataFiltersStep from './autoInactivated/CarrefourDataFiltersStep'

export interface ConfigProps {
    handleNext: () => void
    handlePrevious: () => void
    handleClose: (e: any) => void
    goToDashboard: () => void
}

const stepsSync: Record<string, (config: ConfigProps) => JSX.Element> = {
    [0]: (config: ConfigProps) => (
        <CarrefourInactivatedAuthentification config={config} />
    ),
    [1]: (config: ConfigProps) => <CarrefourDataFiltersStep config={config} />,
    [2]: (config: ConfigProps) => (
        <CarrefourInactivatedStatus config={config} />
    ),
}
const AutoInactivated = ({
    distrib,
    goToDashboard,
    handleClose,
}: {
    distrib: string
    goToDashboard: () => void

    handleClose: (e) => void
}) => {
    const [activeStep, setActiveStep] = useState<number>(0)

    const StepComponent = stepsSync[activeStep]

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }
    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    switch (distrib) {
        case EnvName.FRANCE_FRAIS:
            return <FranceFraisInactivated />
        case EnvName.CARREFOUR:
            return (
                <Grid>
                    {StepComponent({
                        handleNext,
                        handlePrevious,
                        handleClose,
                        goToDashboard,
                    })}
                </Grid>
            )
        default:
            return <FranceFraisInactivated />
    }
}

export default AutoInactivated
