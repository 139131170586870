import * as React from 'react'
import Box from '@mui/material/Box'
import { GridCellParams, type GridColDef } from '@mui/x-data-grid'
import { useAppDispatch } from '../../../../redux/hooks'
import { DataTableComponent } from '../../../../pages/private/DataTableComponent'
import { useCallback, useState } from 'react'
import {
    setDrillDownClients,
    setDrillDownProducts,
} from '../../../../redux/fsProducts/fsProductsSlice'
import { DrillDownType } from '../../drillDowns/ProductsDrillDownContent'
import DrillDown from '../../drillDowns/DrillDown'
import {
    WarehouseItem,
    Warehouses,
} from '../../../../redux/fsProducts/fsProductsTypes'
import { Categories } from '../../../../redux/user/userTypes'

export default function DataGridDashboard({
    columns,
    drilldown = true,
    data,
    drilldownType
}: {
    columns: GridColDef[]
    drilldown?: boolean
    data: Categories[] | Warehouses[]
    drilldownType: DrillDownType
}) {
    const [fieldSelected, setFieldSelected] =
        useState<GridCellParams<WarehouseItem>>()
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(0)
    const dispatch = useAppDispatch()

    const _setDrillDownProducts = useCallback(() => {
        dispatch(setDrillDownProducts([]))
    }, [dispatch])

    const _setDrillDownClients = useCallback(() => {
        dispatch(setDrillDownClients([]))
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        _setDrillDownProducts()
        _setDrillDownClients()
    }

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                '& .MuiDataGrid-virtualScroller': {
                    marginTop: '4vh !important',
                    height: 'calc(100% - 4vh) !important',
                },
                '& .MuiDataGrid-virtualScrollerRenderZone': {
                    height: '100%',
                },
                '& .MuiDataGrid-row': {
                    height: '33% !important',
                    maxHeight: '33% !important',
                    minHeight: 'unset !important',
                },
                '& .MuiDataGrid-cell--withRenderer': {
                    height: '100%',
                    maxHeight: 'unset !important',
                    minHeight: 'unset !important',
                    width: 200,
                },
                '& .MuiDataGrid-row:last-child .MuiDataGrid-cell--withRenderer':
                {
                    borderBottom: 'none',
                },
                '& .MuiDataGrid-cell': {
                    height: 'auto !important',
                    width: '20vw !important',
                },
                '& .MuiGrid-container': {
                    justifyContent: 'center',
                },
            }}
        >
            {drilldown && (
                <DrillDown
                    type={drilldownType}
                    open={open}
                    title={fieldSelected && `${fieldSelected.row.warehouse}`}
                    value={value}
                    setValue={setValue}
                    row={fieldSelected}
                    handleClose={handleClose}
                />
            )}
            <DataTableComponent
                type={drilldownType}
                setOpen={setOpen}
                setFieldSelected={setFieldSelected}
                setValue={setValue}
                columns={columns}
                rows={data}
                getRowId={(row) => row.id}
                hideFooter
            />
        </Box>
    )
}
