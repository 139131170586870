import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { AccordionCustomerLegend } from './AccordionCustomerLegend'
import {
    ClientSegmentationFFR,
    ClientSegmentationTGM,
    SegmentationType,
} from '../../../../../redux/fsProducts/fsProductsTypes'
import { listOfFSDistributors } from '../../types'

type LegendState = Array<{ type: number; label: number }>

export const labels = [
    {
        backgroundColor: [
            '#FAE5FF',
            '#F7EAA6',
            '#D4F7A6',
            '#FFE495',
            '#73FF44',
            '#D4FFEE',
            '#FFE5E5',
            '#E3EEFF',
            '#CDAB00',
            '#EF9BFD',
            '#F7B0A6',
            '#FACE5D',
            '#D5BA68',
            '#CDAB00',
            '#F7EAA6',
            '#D4F7A6',
            '#FFE495',
            '#D4FFEE',
            '#FAE5FF',
            '#E3EEFF',
            '#CDAB00',
            '#EF9BFD',
            '#FFE5E5',
            '#F7B0A6',
            '#FACE5D',
            '#D5BA68',
            '#73FF44',
            '#CDAB00',
        ],
    },
    {
        backgroundColor: [
            '#B9C7F9',
            '#32A9FF',
            '#9EF2ED',
            '#0F52FF',
            '#007F4E',
            '#226D26',
            '#4FADFF',
            '#307FC4',
            '#476A87',
            '#049E63',
            '#3CDFFF',
            '#83B6C1',
            '#248B87',
            '#3A248B',
            '#584C82',
            '#32198E',
            '#9BA1D2',
            '#0C1BA8',
            '#151B52',
            '#151B52',
            '#96A7A6',
            '#3BA87E',
        ],
    },
    {
        backgroundColor: [
            '#C990A4',
            '#EF1515',
            '#84ACCE',
            '#827191',
            '#632C93',
            '#D73B5B',
            '#7D1D3F',
            '#3B5368',
            '#DFB9FF',
            '#EC427E',
            '#8B0031',
            '#532B39',
            '#7D0707',
            '#843437',
            '#841F1F',
            '#2C608E',
            '#D78686',
            '#B11C1C',
            '#FFB2B2',
            '#C077FF',
            '#8800FF',
            '#EF6115',
            '#FFA270',
        ],
    },
]

const CustomerLabels = ({
    chart,
    clients,
    setLabelsHidden,
    envName,
}: {
    setLabelsHidden: (N: number[]) => void
    chart: any
    clients: ClientSegmentationFFR | ClientSegmentationTGM
    envName: string
}) => {
    const [legend, setLegend] = useState<LegendState>([])
    const [countTypeInLegend, setCountTypeInLegend] = useState(0)
    const [countSubTypeInLegend, setCountSubTypeInLegend] = useState(0)
    const [countSubSubTypeInLegend, setCountSubSubTypeInLegend] = useState(0)
    const [dataSetsToHide, setDataSetsToHide] = useState<number[]>([1, 2])
    const [expanded, setExpanded] = React.useState<string | false>('panel1')

    const showData = (dataset: number, label: number) => {
        chart.show(dataset, label)
        setLegend(
            legend.filter((el) => {
                if (el.type === dataset) {
                    return el.label !== label
                }
                return true
            })
        )
        if (dataset === 0) {
            setCountTypeInLegend((compt) => compt - 1)
        } else if (dataset === 1) {
            setCountSubTypeInLegend((compt) => compt - 1)
        } else if (dataset === 2) {
            setCountSubSubTypeInLegend((compt) => compt - 1)
        }
    }

    const hideData = (dataset: number, label: number) => {
        chart.hide(dataset, label)
        setLegend((current) => [...current, { type: dataset, label }])
        if (dataset === 0) {
            setCountTypeInLegend((compt) => compt + 1)
        } else if (dataset === 1) {
            setCountSubTypeInLegend((compt) => compt + 1)
        } else if (dataset === 2) {
            setCountSubSubTypeInLegend((compt) => compt + 1)
        }
    }

    useEffect(() => {
        if (
            clients[SegmentationType.TYPE].labels.length - countTypeInLegend ===
            0
        ) {
            chart.hide(0)
            setDataSetsToHide((current) => [...current, 0])
        } else if (
            clients[SegmentationType.TYPE].labels.length - countTypeInLegend ===
            1
        ) {
            chart.show(0)
            setDataSetsToHide(() =>
                dataSetsToHide.filter((el) => {
                    if (el === 0) {
                        return false
                    }
                    return true
                })
            )
        }
    }, [countTypeInLegend])

    const toggleData = (
        dataset: number,
        label: string,
        type: SegmentationType
    ) => {
        const findLabelIndex = clients[type].labels.findIndex(
            (element) => element === label
        )

        const el = legend.find(
            (pair) => pair.type === dataset && pair.label === findLabelIndex
        )

        if (el) {
            showData(dataset, findLabelIndex)
        } else {
            hideData(dataset, findLabelIndex)
        }
    }

    const handleChange =
        (panel: string) =>
            (event: React.SyntheticEvent, isExpanded: boolean) => {
                if (
                    envName === listOfFSDistributors.TRANSGOURMET ||
                    envName === listOfFSDistributors.SODEXO
                )
                    return null

                const menus = panel.split('')

                const menu = parseInt(menus[menus.length - 1]) - 1

                toggleDataSets(menu)

                setExpanded(isExpanded ? panel : false)
            }

    const hideItem = (
        label: string,
        type: SegmentationType,
        toggleNumber: number
    ) => {
        const findLabelIndex = clients[type].labels.findIndex(
            (element) => element === label
        )

        return (
            legend.findIndex(
                (pair) =>
                    pair.type === toggleNumber && pair.label === findLabelIndex
            ) >= 0
        )
    }

    const allMenus = [0, 1, 2]

    const toggleDataSets = (menu: number, e?: React.MouseEvent) => {
        const menusToHide = allMenus.filter((el) => el != menu)

        setDataSetsToHide(menusToHide)

        chart.show(menu)
        setExpanded(`panel${menu + 1}`)

        setLabelsHidden(menusToHide)

        chart.hide(menusToHide[0])
        chart.hide(menusToHide[1])
    }

    const isOneLevelOnly = Object.keys(clients).length === 1

    switch (Object.keys(clients).length) {
        case 1:
            return (
                <Grid p="1vw" width="100%">
                    <AccordionCustomerLegend
                        checked={!dataSetsToHide.includes(0)}
                        toggleDataSets={toggleDataSets}
                        handleChange={handleChange}
                        list={clients[SegmentationType.TYPE]}
                        hideItem={hideItem}
                        toggleData={toggleData}
                        countInLegend={countTypeInLegend}
                        expanded={expanded}
                        panel="panel1"
                        toggleNumber={0}
                        type={SegmentationType.TYPE}
                        isOneLevelOnly={isOneLevelOnly}
                    />
                </Grid>
            )
        case 3:
            return (
                <Grid p="1vw" width="100%">
                    <AccordionCustomerLegend
                        checked={!dataSetsToHide.includes(0)}
                        toggleDataSets={toggleDataSets}
                        handleChange={handleChange}
                        list={clients[SegmentationType.TYPE]}
                        hideItem={hideItem}
                        toggleData={toggleData}
                        countInLegend={countTypeInLegend}
                        expanded={expanded}
                        panel="panel1"
                        toggleNumber={0}
                        type={SegmentationType.TYPE}
                        isOneLevelOnly={isOneLevelOnly}
                    />
                    <AccordionCustomerLegend
                        checked={!dataSetsToHide.includes(1)}
                        toggleDataSets={toggleDataSets}
                        handleChange={handleChange}
                        list={clients[SegmentationType.SUB_TYPE]}
                        hideItem={hideItem}
                        toggleData={toggleData}
                        countInLegend={countSubTypeInLegend}
                        expanded={expanded}
                        panel="panel2"
                        toggleNumber={1}
                        type={SegmentationType.SUB_TYPE}
                        isOneLevelOnly={isOneLevelOnly}
                    />
                    <AccordionCustomerLegend
                        checked={!dataSetsToHide.includes(2)}
                        toggleDataSets={toggleDataSets}
                        handleChange={handleChange}
                        list={clients[SegmentationType.SUB_SUB_TYPE]}
                        hideItem={hideItem}
                        toggleData={toggleData}
                        countInLegend={countSubSubTypeInLegend}
                        expanded={expanded}
                        panel="panel3"
                        toggleNumber={2}
                        type={SegmentationType.SUB_SUB_TYPE}
                        isOneLevelOnly={isOneLevelOnly}
                    />
                </Grid>
            )
        default:
            return (
                <Grid p="1vw" width="100%">
                    <AccordionCustomerLegend
                        checked={!dataSetsToHide.includes(0)}
                        toggleDataSets={toggleDataSets}
                        handleChange={handleChange}
                        list={clients[SegmentationType.TYPE]}
                        hideItem={hideItem}
                        toggleData={toggleData}
                        countInLegend={countTypeInLegend}
                        expanded={expanded}
                        panel="panel1"
                        toggleNumber={0}
                        type={SegmentationType.TYPE}
                        isOneLevelOnly={isOneLevelOnly}
                    />
                    <AccordionCustomerLegend
                        checked={!dataSetsToHide.includes(1)}
                        toggleDataSets={toggleDataSets}
                        handleChange={handleChange}
                        list={clients[SegmentationType.SUB_TYPE]}
                        hideItem={hideItem}
                        toggleData={toggleData}
                        countInLegend={countSubTypeInLegend}
                        expanded={expanded}
                        panel="panel2"
                        toggleNumber={1}
                        type={SegmentationType.SUB_TYPE}
                        isOneLevelOnly={isOneLevelOnly}
                    />
                    <AccordionCustomerLegend
                        checked={!dataSetsToHide.includes(2)}
                        toggleDataSets={toggleDataSets}
                        handleChange={handleChange}
                        list={clients[SegmentationType.SUB_SUB_TYPE]}
                        hideItem={hideItem}
                        toggleData={toggleData}
                        countInLegend={countSubSubTypeInLegend}
                        expanded={expanded}
                        panel="panel3"
                        toggleNumber={2}
                        type={SegmentationType.SUB_SUB_TYPE}
                        isOneLevelOnly={isOneLevelOnly}
                    />
                </Grid>
            )
    }
}

export default CustomerLabels
