import React from 'react'
import { Grid } from '@mui/material'
import {
    setActiveRetailViewFilters,
    setRetailViewFilters,
} from '../../../redux/filters/filtersSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { EnvType } from '../../../redux/user/userTypes'
import { View, ViewTypes } from '../../../redux/views/viewsTypes'
import Filters, {
    FiltersType,
} from '../../../components/features/filters/Filters'
import { NoDataViewScreen } from '../../../components/features/screens/NoDataViewScreen'
import { Navigate, useParams } from 'react-router-dom'
import { RetailViewFilters } from '../../../redux/filters/filtersTypes'
import PrivatePage from '../../../components/features/GlobalPages/Private/PrivatePage'
import RetailDashboardView from '../Global/views/types/RetailDashboardView'

const getViewComponent = (type: ViewTypes, view: View, env: EnvType) => {
    const dispatch = useAppDispatch()

    switch (type) {
        case ViewTypes.Dashboard:
            if (env === EnvType.FOOD_SERVICE) {
            } else {
                const filters = view.filters as RetailViewFilters
                dispatch(
                    setRetailViewFilters({
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        products: filters.products,
                        stores: filters.stores,
                        distributionChannels: filters.distributionChannels,
                        min: filters.min,
                        max: filters.max
                    })
                )
                dispatch(
                    setActiveRetailViewFilters({
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        products: filters.products,
                        stores: filters.stores,
                        distributionChannels: filters.distributionChannels,
                        min: filters.min,
                        max: filters.max
                    })
                )
                return (
                    <Grid>
                        <Filters
                            filtersType={FiltersType.VIEWSRETAIL}
                            filters={filters}
                        />
                        <RetailDashboardView view={view} />
                    </Grid>
                )
            }
        default:
            return <NoDataViewScreen />
    }
}

const ViewsPage = () => {
    const { viewId } = useParams()
    const views = useAppSelector((state) => state.views.views)
    const view = views.find((view) => view._id === viewId)
    const envType = useAppSelector((state) => state.global.env.type)

    if (!view) {
        return <Navigate to="/" />
    } else {
        const component = getViewComponent(view.type, view, envType)

        return (
            <PrivatePage title={view.name} period onViewPage>
                {component}
            </PrivatePage>
        )
    }
}

export default ViewsPage
