import axios from 'axios'

export interface Config {
    appUrl: string
    apiUrl: string
    environment: ENVIRONMENTS
}

export enum ENVIRONMENTS {
    PRODUCTION = 'prod',
    ALPHA = 'alpha',
    DEVELOPMENT = 'development',
}

export const getConfig = (): Config => {
    switch (process.env.REACT_APP_ENV) {
        case ENVIRONMENTS.PRODUCTION:
            return {
                appUrl: 'https://dashboard.karyonfood.com/',
                apiUrl: 'https://api-dashboard.karyonfood.com/',
                environment: ENVIRONMENTS.PRODUCTION,
            }
        case ENVIRONMENTS.DEVELOPMENT:
            return {
                appUrl: 'https://dev-dashboard.karyonfood.com/',
                apiUrl: 'https://dev.api-dashboard.karyonfood.com/',
                environment: ENVIRONMENTS.PRODUCTION,
            }
        case ENVIRONMENTS.ALPHA:
            return {
                appUrl: 'https://alpha-dashboard.karyonfood.com/',
                apiUrl: 'https://alpha.api-dashboard.karyonfood.com/',
                environment: ENVIRONMENTS.ALPHA,
            }
        default:
            return {
                appUrl: 'http://localhost:3000/',
                apiUrl: 'http://localhost:3001/',
                environment: ENVIRONMENTS.DEVELOPMENT,
            }
    }
}

const client = axios.create({
    baseURL: getConfig().apiUrl,
})

let interceptors = null
let currentToken = null;

export const setHeaders = (token?: string, companyId?: string) => {

    if (token) {
        currentToken = token;
    }

    if (interceptors >= 0) {
        client.interceptors.request.eject(interceptors)
    }

    interceptors = client.interceptors.request.use(
        (config) => {
            if (currentToken) {
                config.headers['Authorization'] = `Bearer ${currentToken}`;
            }
            config.headers["companyid"] = companyId

            return config
        },
        (error) => {
            console.log('ERROR : ', error)
            return error
        }
    )
}

export default client
