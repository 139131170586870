import React from 'react'
import { DialogContent, Grid, TextField } from '@mui/material'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import { object, string } from 'yup'
import { ExportData } from '../../../redux/export/exportsTypes'
import { DialogFooter } from '../Dialog/BasicFooterDialog'
import { PrimaryText } from '../../ui/Text'
import { useAppSelector } from '../../../redux/hooks'
import { BLACK } from '../../ui/Colors'
import { ExportComponentProps, StepExportConfig } from './ExportSetup'
import ErrorMessage from '../../ui/ErrorMessage'

interface FormValues {
    reason: string
}

interface FormProps {
    initialValues: { reason: string }
    config: StepExportConfig
    step: number
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

const PureReasonForm = ({
    config,
    step,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    return (
        <Form>
            <DialogContent sx={{ height: '32vh' }}>
                <Grid container direction="column">
                    <PrimaryText
                        bold
                        color={BLACK}
                        style={{ marginBottom: '3vh', marginTop: '1vh' }}
                    >
                        Finalisez votre export :
                    </PrimaryText>
                    <PrimaryText color={BLACK} style={{ marginBottom: '3vh' }}>
                        En quelques mots, pourquoi souhaitez-vous exporter ces
                        données ?
                    </PrimaryText>
                    <Grid
                        container
                        justifyContent="space-between"
                        style={{ marginBottom: '3vh' }}
                    >
                        <TextField
                            fullWidth
                            id="fullWidth"
                            multiline
                            required
                            defaultValue=""
                            onChange={(e) =>
                                props.setFieldValue('reason', e.target.value)
                            }
                            rows={4}
                            sx={{
                                height: '10vh',
                                '& div': {
                                    height: '100%',
                                    fontSize: '1.6vmin',
                                },
                            }}
                        />
                    </Grid>
                    {props.submitCount > 0 && props.errors.reason && (
                        <Grid
                            item
                            mt="1vh"
                            display="flex"
                            justifyContent="center"
                        >
                            <ErrorMessage msg={props.errors.reason as string} />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={(e) => {
                    config.handlePrevious()
                }}
                leftButtonText={'PRECEDENT'}
                rightButtonFunc={(e) => {
                    e.stopPropagation()

                    props.submitForm()
                }}
                rightButtonText={'EXPORTER'}
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        reason: props.initialValues.reason,
    }),
    validationSchema: () => {
        return object().shape({
            reason: string().required(
                'Archivage, retraitement sur Excel, création de graphes pour vos présentations … Partagez-nous votre besoin.'
            ),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureReasonForm)

const ReasonOfExportStep = ({ step, config }: ExportComponentProps) => {
    const { data } = useAppSelector((state) => state.exports)

    const handleSubmit = (values: FormValues) => {
        const data = config.getData()
        const finalValues = { ...data, ...values } as ExportData
        config.handleSubmit(finalValues)
    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            initialValues={{
                reason: data.reason,
            }}
        />
    )
}

export default ReasonOfExportStep
