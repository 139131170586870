export enum ContactSteps {
    Default = 'default',
    MobileContact = 'fmobile-contact',
    Appointment = 'appointment',
    EmailContact = 'remail-contact',
    MobileValidation = 'mobile-validation',
}

export interface ContactState {
    open: boolean
    config: ContactConfig
}

export interface ContactConfig {
    steps: ContactSteps[]
}
