import React from 'react'
import { type StepperDataConfig } from '../../../../redux/stepper/stepperTypes'
import SignUpAccount from './steps/SignUpAccount'
import SignUpResume from './steps/SignUpResume'
import SignUpUser from './steps/SignUpUser'
import SignUpJob from './steps/SignUpJob'
import Stepper, {
    BackType,
    StepContentConfig,
    StepTimelineConfig,
} from './stepper/Stepper'

const stepsLabels: StepTimelineConfig = {
    labels: [
        'Compte',
        'Informations personnelles',
        'Informations professionnelles',
        'Résumé',
    ],
}

const stepsContent: StepContentConfig[] = [
    {
        component: SignUpAccount,
        backType: BackType.HISTORY,
        backLocalisation: '/',
    },
    {
        component: SignUpUser,
    },
    {
        component: SignUpJob,
    },
    {
        component: SignUpResume,
    },
]

const initialData: StepperDataConfig = {
    key: 'signup',
    initialData: {
        password: '',
        firstname: '',
        lastname: '',
        phoneNumber: '',
        job: '',
        useCases: [],
    },
}

const SignUpFlow = () => {
    return (
        <Stepper
            stepsContent={stepsContent}
            stepTimeline={stepsLabels}
            stepDataConfig={initialData}
        />
    )
}

export default SignUpFlow
