import { UsersRole } from "../../../../redux/user/userTypes"

export const handleScroll = (userRole: UsersRole) => {
    const topMenu = document.getElementById('tabs')
    if (!topMenu) return {}

    // All list items
    const menuItems = topMenu.getElementsByTagName('a')
    // Anchors corresponding to menu items
    const scrollItems = []
    for (let i = 0; i < menuItems.length; i++) {
        const id = menuItems[i].getAttribute('href').slice(1)
        const node = document.getElementById(id)

        if (node) {
            scrollItems.push(node)
        }
    }

    // Get container scroll position
    const position = userRole === UsersRole.ADMIN ? 0.2 : 0.65
    var fromTop = window.scrollY + window.innerHeight * position

    // Get id of current scroll item
    var cur = scrollItems
        .map((node) => {
            if (node.offsetTop < fromTop) return node
        })
        .filter((node) => node !== undefined)
    // Get the id of the current element
    const el = cur[cur.length - 1] as unknown as HTMLElement

    var id = el ? el.id : ''
    // Set/remove active class
    for (let i = 0; i < menuItems.length; i++) {
        if (menuItems[i].getAttribute('href') === `#${id}`) {
            menuItems[i].classList.add('active')
        } else {
            menuItems[i].classList.remove('active')
        }
    }
}
