import React, { useCallback, useEffect, useRef, useState } from 'react'
import Loading from '../../../../components/features/screens/LoadingScreen'
import {
    ActiveFsFilters,
    FsViewFilters,
} from '../../../../redux/filters/filtersTypes'
import { fetchFilteredDashboard } from '../../../../redux/global/globalSlice'
import {
    setActiveFsViewFilters,
    setFsViewFilters,
} from '../../../../redux/filters/filtersSlice'
import { NoDataScreen } from '../../../../components/features/screens/NoDataScreen'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { View } from '../../../../redux/views/viewsTypes'
import { Grid } from '@mui/material'

export const ViewsLayout = ({
    children,
    view,
}: {
    children: JSX.Element
    view: View
}) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dispatch = useAppDispatch()
    const { unit, isLoading } = useAppSelector((state) => state.global)
    const dashboard = useAppSelector((state) => state.global.fsDashboardData)
    const { min, max } = useAppSelector((state) => state.filters.activeFsFilters)
    const { activeFsViewFilters } = useAppSelector((state) => state.filters)
    const isFirstUpdate = useRef(true)
    const filters = view.filters as FsViewFilters

    const _fetchFsDashboard = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchFilteredDashboard(filters, true))
        },
        [dispatch]
    )

    useEffect(() => {
        dispatch(
            setFsViewFilters({
                startDate: filters.startDate,
                endDate: filters.endDate,
                products: filters.products,
                clients: filters.clients,
                regions: filters.regions,
                categories: filters.categories,
                min: min,
                max: max
            })
        )
        dispatch(
            setActiveFsViewFilters({
                startDate: filters.startDate,
                endDate: filters.endDate,
                products: filters.products,
                clients: filters.clients,
                regions: filters.regions,
                categories: filters.categories,
                min: min,
                max: max
            })
        )
    }, [])

    useEffect(() => {
        if (!isFirstUpdate.current) {

            _fetchFsDashboard(view.filters as ActiveFsFilters)
            setActiveFsViewFilters(activeFsViewFilters)
            setIsFirstLoad(false)
            isFirstUpdate.current = false
        }
    }, [view.filters])

    useEffect(() => {
        if (activeFsViewFilters.startDate && !isFirstUpdate.current) {

            _fetchFsDashboard(activeFsViewFilters)
            setIsFirstLoad(false)
        }
        isFirstUpdate.current = false
    }, [activeFsViewFilters, _fetchFsDashboard, unit])

    if (isFirstLoad || isLoading) return <Loading />

    if (dashboard === null || dashboard.products.length === 0)
        return <NoDataScreen />

    return <Grid>{children}</Grid>
}
